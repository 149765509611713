import { Button, Card, ThemeProps, Input, Text, BoxReset } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { useReceiveAttribution } from "./useReceiveAttribution";
import { AttributionResults } from "../AttributionResults";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const ReceiveAttribution = () => {
  const { eventId, onSubmitPreview, previewResults, resetAll, persistAttribution, isSubmitting } =
    useReceiveAttribution();

  return (
    <Container>
      <Card>
        <StyledForm onSubmit={onSubmitPreview}>
          <Text size="bodyLarge" bold>
            Receive Attribution
          </Text>
          <Text size="body">
            This tool is used to preview the results of a receiving event and optionally approve the results
          </Text>

          <Input
            {...eventId.field}
            helpText="The id of the event that you'd like to preview/approve/reassign"
            placeholder="002a36a8-be27-584a-a834-3e7d3a7123e9"
            label="Event Id"
            required
            hasError={!!eventId.fieldState.error}
            errorMessage={eventId.fieldState.error?.message}
          />
          {!previewResults ? (
            <Button type="submit" loading={isSubmitting}>
              Preview
            </Button>
          ) : null}
        </StyledForm>
      </Card>
      {previewResults ? (
        <BoxReset marginTop="S4">
          <AttributionResults
            attributionResults={previewResults}
            onCancel={resetAll}
            onApprove={persistAttribution}
            isSubmitting={isSubmitting}
          />
        </BoxReset>
      ) : null}
    </Container>
  );
};
