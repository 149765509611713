import { useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "react-use";

const sortCodePattern = /^(?<airportCode>[A-Z]{3})-(?<carrierMapping>[A-Z]+)(?<sortLane>[0-9]+)$/;

const isValidSortCode = (sortCode: string): boolean => {
  return sortCodePattern.test(sortCode);
};

export const useCreateSortCode = (currentSortsCodes: string[]) => {
  const [sortCodeInput, setSortCodeInput] = useState<string>("");
  const [validSortCodeInput, setValidSortCodeInput] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { sortationCenterId, shippingMethod } = useParams<{ sortationCenterId: string; shippingMethod: string }>();
  const navigate = useNavigate();

  // Form value debounce for validation
  useDebounce(
    () => {
      if (currentSortsCodes.includes(sortCodeInput)) {
        setErrorMessage(`Sort code "${sortCodeInput}" already exists`);
      } else if (sortCodeInput.length > 0 && !isValidSortCode(sortCodeInput)) {
        setErrorMessage("Invalid sort code");
      } else if (isValidSortCode(sortCodeInput)) {
        setErrorMessage("");
        setValidSortCodeInput(true);
      }
    },
    300,
    [sortCodeInput]
  );

  const handleSortCodeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSortCodeInput(e.target.value.toUpperCase());
  };

  const handleContinue = () => {
    navigate(`/sortation/sort-code-manager/${sortationCenterId}/${shippingMethod}/${sortCodeInput}`);
  };

  return {
    sortCodeInput,
    hasError: errorMessage.length > 0,
    errorMessage,
    validSortCodeInput,
    handleSortCodeInputChange,
    handleContinue,
  };
};
