import styled from "@emotion/styled";
import { HeaderGroup, flexRender } from "@tanstack/react-table";
import { CreditBlockItem } from "../CreditBlockItem";
import { ThemeProps } from "@deliverr/ui";

const StyledHeaderRow = styled.tr<ThemeProps>(
  ({ theme }) => `
  border-bottom: 1px solid ${theme.colors.NEUTRAL[80]};
`
);

const StyledHeaderCell = styled.th<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S4} ${theme.spacing.S5};
  text-align: start;
  border-bottom: 1px solid ${theme.colors.NEUTRAL[80]};
`
);

interface CreditBlockTableHeaderProps {
  headerGroups: HeaderGroup<CreditBlockItem>[];
}

export const CreditBlockTableHeader: React.FC<CreditBlockTableHeaderProps> = ({ headerGroups }) => {
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <StyledHeaderRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <StyledHeaderCell key={header.id}>
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            </StyledHeaderCell>
          ))}
        </StyledHeaderRow>
      ))}
    </thead>
  );
};
