import { toast } from "@deliverr/ui";
import { IssueGetResponse, IssueStatus, NonComplianceClient } from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { ActionsProps } from "./components/Actions/Actions";
import { handleNonComplianceError } from "utils/handleNonComplianceError";

export async function getUnknownBarcodeDetails({
  issue,
  client,
  setIsLoading,
  refreshIssue,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
  setIsLoading: (isLoading: boolean) => void;
  refreshIssue: () => void;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
  actionProps: Pick<ActionsProps, "onFailedToIdentifyProduct" | "identifyProductWithoutBarcode"> | null;
}> {
  const unknownBarcodeIssue = await client.getIssueUnknownBarcodeById({ issueId: issue.id });

  const issueDetails = {
    issue: unknownBarcodeIssue.issue,
    images: unknownBarcodeIssue.images,
    issueEntities: unknownBarcodeIssue.issueEntities,
    zendeskTickets: unknownBarcodeIssue.zendeskTickets,
    fields: [
      { type: FIELD_TYPE.UNIT_DISPOSITION, value: unknownBarcodeIssue.unitDisposition },
      { type: FIELD_TYPE.BARCODE, value: unknownBarcodeIssue.barcodeScanned },
      { type: FIELD_TYPE.DSKU, value: unknownBarcodeIssue.dskuIdentified },
    ],
    reimbursementData: null,
    disputeData: null,
  };

  const identifyProduct = async (params: { dsku: string }) => {
    setIsLoading(true);
    try {
      await client.completeProductIdentificationForIssueUnknownBarcode({
        issueId: issue.id,
        dsku: params.dsku,
      });
      await refreshIssue();
      setIsLoading(false);
      toast.success("Issue product identification success", { position: "top-right" });
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "getUnknownBarcodeDetails.identifyProduct" }, error as Error);
    }
  };

  const failedToIdentify = async () => {
    setIsLoading(true);
    try {
      await client.failedToIdentifyProductForUnknownBarcode({
        issueId: issue.id,
      });
      await refreshIssue();
      setIsLoading(false);
      toast.success("Success: Marked issue as failed to identify product", { position: "top-right" });
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "getUnknownBarcodeDetails.failedToIdentify" }, error as Error);
    }
  };

  const showIdentificationOption = (
    [IssueStatus.AWAITING_SUPPORT_IDENTIFICATION, IssueStatus.AWAITING_MERCHANT_FEEDBACK] as IssueStatus[]
  ).includes(issue.status);

  return {
    issueDetails,
    actionProps: {
      onFailedToIdentifyProduct: showIdentificationOption ? failedToIdentify : null,
      identifyProductWithoutBarcode: showIdentificationOption ? identifyProduct : null,
    },
  };
}
