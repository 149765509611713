import { Button, Input, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useCreateSortCode } from "./useCreateSortCode";

const CreateSortCodeContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  margin: ${theme.spacing.S4} 0;
`
);

const InputContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S4};
`
);

interface CreateSortCodeProps {
  currentSortCodes: string[];
}

export const CreateSortCode = ({ currentSortCodes }: CreateSortCodeProps) => {
  const { sortCodeInput, hasError, errorMessage, validSortCodeInput, handleSortCodeInputChange, handleContinue } =
    useCreateSortCode(currentSortCodes);

  return (
    <CreateSortCodeContainer>
      {/* Container is required for margin when there's an error message */}
      <InputContainer>
        <Input
          placeholder="ABC-D1"
          value={sortCodeInput}
          hasError={hasError}
          errorMessage={errorMessage}
          onChange={handleSortCodeInputChange}
          label="Enter a new sort code"
        />
      </InputContainer>
      <Button disabled={!validSortCodeInput} onClick={handleContinue}>
        Create new sort code
      </Button>
    </CreateSortCodeContainer>
  );
};
