import { defaultTheme } from "@deliverr/ui";
import createCache from "@emotion/cache";
import { ThemeProvider } from "emotion-theming";
import { CacheProvider } from "@emotion/core";
import * as React from "react";

const emotionInstance = createCache({
  key: "support",
  container: document.getElementsByTagName("body")[0],
});

export const EmotionCache: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <CacheProvider value={emotionInstance}>
    <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
  </CacheProvider>
);
