import { labelaryClient } from "base/clients";

export const downloadPdf = async (sortationCenterId: string, zplData: string) => {
  // Step 1: Convert ZPL to PDF using Labelary API
  const labelData = await labelaryClient.convertZPLToPDF(zplData);

  // Step 2: Create a Blob from the PDF data
  const blob = new Blob([labelData], { type: "application/pdf" });

  // Step 3: Create URL for the Blob
  const url = URL.createObjectURL(blob);

  // Step 4: Create link element
  const link = document.createElement("a");
  link.href = url;
  link.download = `${sortationCenterId}_dock_door_labels.pdf`; // Set the desired filename

  // Step 5: Programmatically trigger download
  document.body.appendChild(link); // Append the link to the DOM
  link.click();

  // Clean up: Remove the link and revoke the URL
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
