import styled from "@emotion/styled";
import { RowModel, flexRender } from "@tanstack/react-table";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { CreditBlockItem } from "../CreditBlockItem";
import { ThemeProps } from "@deliverr/ui";

const StyledBodyRow = styled.tr<ThemeProps>(
  ({ theme }) => `
  border-bottom: 1px solid ${theme.colors.NEUTRAL[80]};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
`
);

const StyledBodyCell = styled.td<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S5};
  border-bottom: 1px solid ${theme.colors.NEUTRAL[80]};
`
);

interface CreditBlockTableBodyProps {
  rowModel: RowModel<CreditBlockItem>;
}

export const CreditBlockTableBody: React.FC<CreditBlockTableBodyProps> = ({ rowModel }) => {
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (sellerId: string) => {
      navigate(`/finance/credit-blocks/${sellerId}`);
    },
    [navigate]
  );

  return (
    <tbody>
      {rowModel.rows.map((row) => (
        <StyledBodyRow key={row.id} onClick={() => handleRowClick(row.id)}>
          {row.getVisibleCells().map((cell) => (
            <StyledBodyCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</StyledBodyCell>
          ))}
        </StyledBodyRow>
      ))}
    </tbody>
  );
};
