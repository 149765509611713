import { useForm, useController, SubmitHandler } from "react-hook-form";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { toast } from "@deliverr/ui";
import { logError } from "utils/logger";
import { useState } from "react";
import { ViewFacilityZonesFormValues } from "../FacilityZonesFormAndTable/FacilityZonesFormAndTable";
import { ZoneManagementInputRules } from "../InputPatterns";

export type ZoneData = { zoneId: number; zoneName: string }[];

export const useViewFacilityZones = () => {
  const { inboundClient } = useClientsWithAuth();
  const { control, handleSubmit, reset } = useForm<ViewFacilityZonesFormValues>({});
  const [facilityZonesData, setFacilityZonesData] = useState<ZoneData>([]);
  const [currentFacilityId, setCurrentFacilityId] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { facilityIdInput } = ZoneManagementInputRules;

  const facilityIdController = useController({
    name: "facilityId",
    control,
    rules: {
      required: true,
      pattern: { value: facilityIdInput.pattern, message: facilityIdInput.message },
    },
    defaultValue: "",
  });

  const viewZones: SubmitHandler<ViewFacilityZonesFormValues> = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await inboundClient.listFacilityZones(data.facilityId);
      setIsSubmitting(false);
      if (response.error) {
        reset();
        if (response.error.message) {
          toast.error(response.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }
      const zoneData =
        response.data?.facilityZones.map((zone) => {
          return { zoneId: zone.id, zoneName: zone.zoneName };
        }) ?? [];
      if (zoneData.length === 0) {
        toast.info(`No active zones found for facility ${data.facilityId}`, { position: "top-right" });
      }
      setFacilityZonesData(zoneData);
      setCurrentFacilityId(data.facilityId);
      reset();
    } catch (error) {
      setIsSubmitting(false);
      reset();
      logError({ fn: "useViewFacilityZones.viewZones" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  const removeZone = async (zoneId: number, zoneName: string) => {
    toast.dismiss();
    setIsSubmitting(true);
    try {
      const response = await inboundClient.deactivateFacilityZones([zoneId]);
      setIsSubmitting(false);
      if (response.error) {
        if (response.error.message) {
          toast.error(response.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }
      toast.success(`Zone ${zoneName} removed`, { position: "top-right" });

      //requery the facility zones to update the table
      viewZones({ facilityId: currentFacilityId });
    } catch (error) {
      setIsSubmitting(false);
      logError({ fn: "useViewFacilityZones.removeZone" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  return {
    facilityIdController,
    currentFacilityId,
    facilityZonesData,
    isSubmitting,
    onSubmit: handleSubmit(viewZones),
    removeZone,
  };
};
