import { Card, Select, Stack } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { LoadingSpinner } from "components/LoadingSpinner";
import { CreditBlockTable } from "./CreditBlockTable/CreditBlockTable";
import { useCreditBlock } from "./useCreditBlock";
import { SearchBar } from "./SearchBar";
import { isEmpty } from "lodash";
import EmptyMessage from "./CreditBlockTable/EmptyMessage";

const StyledContainer = styled(Container)`
  margin-bottom: 30px;
`;
const StyledCard = styled(Card)`
  padding: 0;
`;
const StyledSelectWrapper = styled.div`
  min-width: 160px;

  & > label {
    min-width: 100%;
  }
`;

export const CreditBlock = () => {
  const {
    statusFilterOptions,
    handleStatusFilter,
    statusFilterValue,
    onPageChange,
    currentPage,
    totalHits,
    sort,
    currentPageItems,
    searchTerm,
    handleSort,
    handleSearch,
    loading,
  } = useCreditBlock();
  return (
    <StyledContainer fullWidth={true}>
      <Stack direction="HORIZONTAL">
        <SearchBar currentSearchTerm={searchTerm} handleSearch={handleSearch} />
        <StyledSelectWrapper>
          <Select
            flexWidth
            isSearchable={false}
            onChange={handleStatusFilter}
            options={statusFilterOptions}
            value={statusFilterValue}
          />
        </StyledSelectWrapper>
      </Stack>

      <StyledCard>
        <LoadingSpinner loading={loading} text="Loading Credit Blocks Data">
          {!isEmpty(currentPageItems) ? (
            <CreditBlockTable
              onPageChange={onPageChange}
              currentPage={currentPage}
              totalHits={totalHits}
              sort={sort}
              handleSort={handleSort}
              currentPageItems={currentPageItems}
            />
          ) : (
            <EmptyMessage />
          )}
        </LoadingSpinner>
      </StyledCard>
    </StyledContainer>
  );
};
