import { SearchOptions } from "@deliverr/logistics-search-client";
import { OpenSearchService } from "base/OpenSearch/OpenSearchService";
import { SearchRequest } from "base/OpenSearch/SearchService";

export class CreditBlockSearchService extends OpenSearchService {
  protected buildSearchOptions(request: SearchRequest): SearchOptions {
    const searchOptions = super.buildSearchOptions(request);
    const shouldQueries: any[] = [];

    if (request.searchTerm) {
      shouldQueries.push({
        query_string: {
          query: `sellerId: *${request.searchTerm}*`,
          analyze_wildcard: true,
        },
      });
    }

    const existingBoolQuery = searchOptions.query?.bool || {};

    return {
      ...searchOptions,
      query: {
        bool: {
          ...existingBoolQuery,
          should: shouldQueries,
        },
      },
      hydrate: true,
    };
  }
}
