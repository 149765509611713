import React from "react";
import { Button, Card, Divider, SwitchMenu, Text, ThemeProps } from "@deliverr/ui";
import { Container } from "components/Container";
import { useSortCodeSelector } from "./useSortCodeSelector";
import styled from "@emotion/styled";
import { LoadingSpinner } from "components/LoadingSpinner";
import { SORT_CODE_SELECTOR_VIEWS, useSortCodeSelectorViews } from "./useSortCodeSelectorViews";
import { SelectSortCode } from "./SelectSortCode";
import { CreateSortCode } from "./CreateSortCode";

const CardTitleContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
  `
);

const TextGroup = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.S2};
  margin-bottom: ${theme.spacing.S2};
`
);

const ButtonGroup = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.S4};
  margin-top: ${theme.spacing.S4};
`
);

const StyledHr = styled(Divider)<ThemeProps>(
  ({ theme }) => `
  margin-bottom: ${theme.spacing.S4};
`
);

export const SortCodeSelector: React.FC = () => {
  const { sortCodeSelectorView, updateLocationView } = useSortCodeSelectorViews();
  const { state, sortationCenterId, shippingMethod, sortCodes, handleCancel } = useSortCodeSelector();

  const componentToRender = () => {
    switch (sortCodeSelectorView) {
      case SORT_CODE_SELECTOR_VIEWS.SELECT_SORT_CODE:
        return (
          <SelectSortCode sortCodes={sortCodes} sortationCenterId={sortationCenterId} shippingMethod={shippingMethod} />
        );
      case SORT_CODE_SELECTOR_VIEWS.CREATE_NEW_SORT_CODE:
        return <CreateSortCode currentSortCodes={sortCodes} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Card>
        <LoadingSpinner loading={state.loading}>
          <CardTitleContainer>
            <Text size="bodyLarge" bold>
              Select or Create Sort Code
            </Text>
            <Text size="body">Please select or create a sort code to continue.</Text>
          </CardTitleContainer>
          <TextGroup>
            <Text appearance="INFO">Sortation Center</Text>
            <Text>{sortationCenterId}</Text>
          </TextGroup>
          <TextGroup>
            <Text appearance="INFO">Shipping Method</Text>
            <Text>{shippingMethod}</Text>
          </TextGroup>
          {/* <StyledHr /> */}
          <SwitchMenu
            activeMenuItem={sortCodeSelectorView}
            handleOnClick={updateLocationView}
            menuItems={[
              {
                key: SORT_CODE_SELECTOR_VIEWS.SELECT_SORT_CODE,
                label: SORT_CODE_SELECTOR_VIEWS.SELECT_SORT_CODE,
              },
              {
                key: SORT_CODE_SELECTOR_VIEWS.CREATE_NEW_SORT_CODE,
                label: SORT_CODE_SELECTOR_VIEWS.CREATE_NEW_SORT_CODE,
              },
            ]}
          />
          {componentToRender()}
          <StyledHr />
          <ButtonGroup>
            <Button secondary onClick={handleCancel}>
              Back
            </Button>
          </ButtonGroup>
        </LoadingSpinner>
      </Card>
    </Container>
  );
};
