import { Pagination, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useCreditBlockTable } from "./useCreditBlockTable";
import { CreditBlockTableHeader } from "./CreditBlockTableHeader";
import { CreditBlockTableBody } from "./CreditBlockTableBody";
import { FC } from "react";
import { CreditBlockItem, SortFields } from "components/finance/CreditBlockList/CreditBlockItem";
import { ITEMS_PER_PAGE } from "components/finance/constants";

const StyledPagination = styled.div<ThemeProps>`
  ${({ theme }) => `
  padding: ${theme.spacing.S3} 45rem  ${theme.spacing.S3} ${theme.spacing.S3}
  `}
`;

const StyledTable = styled.table<ThemeProps>`
  ${({ theme }) => `
    width: 100%;
    font-size: ${theme.font.size.F2};
    color: ${theme.colors.NEUTRAL[500]};
  `}
`;

interface CreditBlockTableProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  totalHits: number;
  currentPageItems: CreditBlockItem[];
  sort: SortFields;
  handleSort: () => void;
}

export const CreditBlockTable: FC<CreditBlockTableProps> = ({
  onPageChange,
  currentPage,
  totalHits,
  currentPageItems,
  sort,
  handleSort,
}) => {
  const { table } = useCreditBlockTable(currentPageItems, sort, handleSort);

  return (
    <div>
      <StyledTable>
        <CreditBlockTableHeader headerGroups={table.getHeaderGroups()} />
        <CreditBlockTableBody rowModel={table.getRowModel()} />
      </StyledTable>
      <StyledPagination>
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          itemsPerPage={ITEMS_PER_PAGE}
          totalItemsCount={totalHits}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          entity="items"
          initialPage={currentPage}
        />
      </StyledPagination>
    </div>
  );
};
