import { useForm, useController, SubmitHandler } from "react-hook-form";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { toast } from "@deliverr/ui";
import { logError } from "utils/logger";
import { useState } from "react";
import { ViewFacilityZonesFormValues } from "../FacilityZonesFormAndTable/FacilityZonesFormAndTable";
import { ZoneManagementInputRules } from "../InputPatterns";
import * as _ from "lodash";

export type ZoneData = { zoneId: number; zoneName: string }[];

export const useAddMerchantZoneAssignments = () => {
  const { inboundClient } = useClientsWithAuth();
  const facilityIdForm = useForm<ViewFacilityZonesFormValues>({});
  const merchantForm = useForm<{ merchants: string }>({});
  const [facilityZonesData, setFacilityZonesData] = useState<ZoneData>([]);
  const [currentFacilityId, setCurrentFacilityId] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAssigningMerchantToZone, setIsAssigningMerchantToZone] = useState(false);
  const [currentZoneId, setCurrentZoneId] = useState<number | null>(null);
  const [currentZoneName, setCurrentZoneName] = useState<string>("");

  const { facilityIdInput, merchantIdsInput } = ZoneManagementInputRules;

  const facilityIdController = useController({
    name: "facilityId",
    control: facilityIdForm.control,
    rules: {
      required: true,
      pattern: { value: facilityIdInput.pattern, message: facilityIdInput.message },
    },
    defaultValue: "",
  });

  const merchantsController = useController({
    name: "merchants",
    control: merchantForm.control,
    rules: {
      required: true,
      pattern: {
        value: merchantIdsInput.pattern,
        message: merchantIdsInput.message,
      },
    },
    defaultValue: "",
  });

  const viewZones: SubmitHandler<ViewFacilityZonesFormValues> = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await inboundClient.listFacilityZones(data.facilityId);
      setIsSubmitting(false);
      if (response.error) {
        facilityIdForm.reset();
        if (response.error.message) {
          toast.error(response.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }
      const zoneData =
        response.data?.facilityZones.map((zone) => {
          return { zoneId: zone.id, zoneName: zone.zoneName };
        }) ?? [];
      if (zoneData.length === 0) {
        toast.info(`No active zones found for facility ${data.facilityId}`, { position: "top-right" });
      }
      setFacilityZonesData(zoneData);
      setCurrentFacilityId(data.facilityId);
      facilityIdForm.reset();
    } catch (error) {
      setIsSubmitting(false);
      facilityIdForm.reset();
      logError({ fn: "useAddMerchantZoneAssignments.viewZones" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  const assignMerchantsToZone: SubmitHandler<{ merchants: string }> = async (data) => {
    const { merchants } = data;
    setIsSubmitting(true);
    try {
      const merchantIds = _.uniq(_.compact(merchants.split(",")));
      const response = await inboundClient.configureZoneAssignments(merchantIds, currentZoneId!);
      setIsSubmitting(false);
      if (response.error) {
        merchantForm.reset();
        if (response.error.message) {
          toast.error(response.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }
      toast.success(`Merchants ${merchantIds.join(", ")} assigned to zone ${currentZoneName}`, {
        position: "top-right",
      });
      merchantForm.reset();
    } catch (error) {
      setIsSubmitting(false);
      merchantForm.reset();
      logError({ fn: "useAddMerchantZoneAssignments.assignMerchantsToZone" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  const setFacilityZoneAndSwitchToAddSellerView = async (zoneId: number, zoneName: string) => {
    setIsAssigningMerchantToZone(true);
    setCurrentZoneId(zoneId);
    setCurrentZoneName(zoneName);
  };

  const onCancel = () => {
    setCurrentZoneId(null);
    setCurrentZoneName("");
    setIsAssigningMerchantToZone(false);
    merchantForm.reset();
  };

  return {
    facilityIdController,
    merchantsController,
    currentFacilityId,
    currentZoneName,
    facilityZonesData,
    isSubmitting,
    isAssigningMerchantToZone,
    onFacilityIdSubmit: facilityIdForm.handleSubmit(viewZones),
    onMerchantSubmit: merchantForm.handleSubmit(assignMerchantsToZone),
    onCancel,
    setFacilityZoneAndSwitchToAddSellerView,
  };
};
