import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC, ReactElement } from "react";
import { useMatch } from "react-router-dom";
import { SidebarMode, useSidebarContext } from "./SidebarContext";
import { SidebarPageItemProps } from "./SidebarPageItem";
import { sidebarTheme } from "./SidebarTheme";

type Placement = "first" | "between" | "last";
export interface SidebarItemGroupProps {
  path: string;
  children: ReactElement<SidebarPageItemProps> | Array<ReactElement<SidebarPageItemProps> | null>;
  header: ReactElement;
  placement: Placement;
}

const StyledGroup = styled.div<{
  mode: SidebarMode;
  active: boolean;
  placement: Placement;
}>(
  ({ mode, active, placement }) => `
    padding: 0;
    margin: ${sidebarTheme.item.margin[mode]};

    ${
      !active
        ? `
          margin-bottom: 0;
        `
        : ""
    }

    > li {
      &, &:first-of-type, &:last-of-type {
        margin: ${sidebarTheme.item.margin[mode]};
        ${!active && placement === "between" ? `margin-bottom: 0` : ""}
      }
    }
  `
);

const StyledGroupContent = styled.div<{ active: boolean; childCount: number; mode: SidebarMode }, DefaultTheme>(
  ({ active, mode, childCount, theme }) => {
    const itemMargin = sidebarTheme.item.margin[mode].split(" ")[0];
    const totalHeight = `${sidebarTheme.item.height[mode]} * ${childCount} + ${itemMargin} * (${childCount} - 1)`;

    // The margin-left is calculated as the padding of the icon minus half the icon
    // width. This way, the left border appears right in between the icons.
    return `
      height: ${active ? `calc(${totalHeight})` : "0px"};
      overflow: hidden;
      border-left: 1px solid rgb(255 255 255 / 20%);
      margin-left: calc(2px + ${theme.spacing.S2} + (${sidebarTheme.item.iconSize} - 1px) / 2);
      padding-left: ${theme.spacing.S3};
      transition: height ${sidebarTheme.transitionDuration};
      
      li {
        margin: ${sidebarTheme.item.margin[mode]};
      }

      li:first-of-type {
        margin-top: 0;
      }

      li:last-of-type {
        margin-bottom: 0;
      }
  `;
  }
);

export const SidebarItemGroup: FC<SidebarItemGroupProps> = ({ header, children, placement, path }) => {
  const { mode } = useSidebarContext();
  const match = useMatch({ path, end: true });
  const isActive = match != null;

  const headerItemCount = (header.props as SidebarPageItemProps).itemCount;
  const getHeader = (groupOpen: boolean) =>
    React.cloneElement(header, {
      active: false,
      itemCount: groupOpen ? null : headerItemCount,
    });

  const childCount = React.Children.toArray(children).filter((child) => child !== null).length;

  return (
    <StyledGroup mode={mode} active={isActive} placement={placement}>
      {getHeader(isActive)}
      <StyledGroupContent mode={mode} childCount={childCount} active={isActive}>
        {children}
      </StyledGroupContent>
    </StyledGroup>
  );
};
