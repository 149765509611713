import { Button } from "@deliverr/ui";
import styled from "@emotion/styled";

export const NetworkButtonGroup = styled.div`
  display: flex;
  button {
    margin-right: 1rem;
  }
`;

export const NetworkButton = styled(Button)`
  width: fit-content;
`;
