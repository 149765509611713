import styled from "@emotion/styled";
import { ThemeProps, Input, Button, Box, Table, Text } from "@deliverr/ui";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import { UseControllerReturn } from "react-hook-form";
import { StyledForm } from "components/inbounds/AsnAssignmentTool";

export const StyledTable = styled(Table)<ThemeProps>(
  ({ theme }) => `
    table {    
      width: 100%;
    }

    th {
      background-color: ${theme.colors.BLUE["300"]};
      color: ${theme.colors.NEUTRAL["00"]};
      font-size: ${theme.font.size.F3};
      padding: ${theme.spacing.S1};
      text-align: center;  
      position: sticky;
      top: 0px;
      z-index: 100;
    }

    td {
      text-align:center;
      font-size: ${theme.font.size.F2};
      padding-left: 0px;
      padding-right: 0px;
      word-break: break-all;

    }
  `
);

export const StyledTableContainer = styled.div`
  overflow: auto;
  max-height: 50vh;
`;

export type ViewFacilityZonesFormValues = {
  facilityId: string;
};

export const FacilityZonesFormAndTable = ({
  onSubmit,
  facilityIdInputController,
  isSubmitting,
  rowValues,
  currentFacilityId,
}: {
  onSubmit: () => Promise<void>;
  facilityIdInputController: UseControllerReturn<ViewFacilityZonesFormValues, "facilityId">;
  isSubmitting: boolean;
  rowValues: [string, JSX.Element][];
  currentFacilityId: string;
}) => {
  const facilityZonesTableHeadings = ["Zone Name", "Action"];

  return (
    <>
      <StyledForm onSubmit={onSubmit}>
        <Input
          {...facilityIdInputController.field}
          label="Facility ID"
          placeholder="TST_LOG"
          required
          hasError={!!facilityIdInputController.fieldState.error}
          errorMessage={facilityIdInputController.fieldState.error?.message}
        />
        <Button type="submit" loading={isSubmitting}>
          View Zones
        </Button>
      </StyledForm>
      {rowValues.length > 0 && (
        <>
          <Text size="bodyLarge" bold textAlign={TextAlign.center} as="header">{`Facility: ${currentFacilityId}`}</Text>
          <Box marginTop="S4">
            <StyledTableContainer>
              <StyledTable
                headings={facilityZonesTableHeadings}
                rows={rowValues}
                caption={"Facility Zones"}
              ></StyledTable>
            </StyledTableContainer>
          </Box>
        </>
      )}
    </>
  );
};
