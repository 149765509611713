import { Text } from "@deliverr/ui";

export const Field = ({ label, value }: { label: string; value: any }) => {
  return (
    <>
      <Text as="span" bold size="label">
        {label}:
      </Text>
      <Text as="span" size="body" appearance={!value ? "INFO" : undefined}>
        {value || "N/A"}
      </Text>
    </>
  );
};
