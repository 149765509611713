import { useState } from "react";

export enum SORT_CODE_SELECTOR_VIEWS {
  SELECT_SORT_CODE = "Select Sort Code",
  CREATE_NEW_SORT_CODE = "Create New Sort Code",
}

export const useSortCodeSelectorViews = () => {
  const [sortCodeSelectorView, setSortCodeSelectorView] = useState<SORT_CODE_SELECTOR_VIEWS>(
    SORT_CODE_SELECTOR_VIEWS.SELECT_SORT_CODE
  );

  const updateLocationView = (value: any) => {
    setSortCodeSelectorView(value);
  };

  return { updateLocationView, sortCodeSelectorView };
};
