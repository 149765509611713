import { atom } from "recoil";
import { SortationCenterSortCodeMap } from "@deliverr/commons-clients";

export enum SortationStateKey {
  SORT_CODE_MAPPING = "SORT_CODE_MAPPING_STATE",
}

export const sortCodeMappingState = atom<SortationCenterSortCodeMap>({
  key: SortationStateKey.SORT_CODE_MAPPING,
  default: {},
});
