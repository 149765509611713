import { useState } from "react";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { handleNonComplianceError } from "utils/handleNonComplianceError";

type FormValues = {
  sellerId: string;
};

export const useSellerIdentificationAction = (identifySeller: (sellerId: string) => Promise<void>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({});

  const sellerId = useController({
    name: "sellerId",
    defaultValue: "",
    control,
    rules: {
      required: true,
      maxLength: 100,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsSubmitting(true);
    try {
      await identifySeller(data.sellerId);
      setIsSubmitting(false);
      reset();
      return;
    } catch (error) {
      // some better error handling of 4** errors.
      setIsSubmitting(false);
      handleNonComplianceError({ fn: "useSellerIdentificationAction.onSubmit" }, error as Error);
    }
  };

  return {
    sellerId,
    onSubmit: handleSubmit(onSubmit),
    isSubmitting,
  };
};
