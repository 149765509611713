import { ApiClient } from "@deliverr/base-client";
import { DeliverrError } from "@deliverr/errors";
import { LabelaryErrors } from "./LabelaryErrors";

export class LabelaryClient extends ApiClient {
  constructor() {
    super({ baseURL: process.env.REACT_APP_LABELARY_URL! });
  }

  public async convertZPLToPDF(zpl: string): Promise<string> {
    try {
      const resp = await this.request({
        url: `/v1/printers/8dpmm/labels/4x6/`,
        method: "POST",
        data: zpl,
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf",
        },
      });
      return resp.data;
    } catch (err) {
      throw new DeliverrError(LabelaryErrors.FORMAT_CONVERSION_FAILED);
    }
  }
}
