import { Button, Card, Input, Text, BoxReset } from "@deliverr/ui";
import { Container } from "components/Container";
import { useBoxAssignmentTool } from "./useBoxAssignmentTool";
import { AttributionResults } from "../AttributionResults";
import { StyledForm } from "../AsnAssignmentTool";
import { Controller } from "react-hook-form";

export const BoxAssignmentTool = () => {
  const {
    eventId,
    boxAssignmentFields,
    onSubmitPreview,
    previewResults,
    resetAll,
    applyAsnAssignment,
    control,
    append,
    remove,
    isSubmitting,
  } = useBoxAssignmentTool();

  return (
    <Container>
      <Card>
        <StyledForm onSubmit={onSubmitPreview}>
          <Text size="bodyLarge" bold>
            Box Assignment Tool
          </Text>
          <Text size="body">
            This tool is used to spread the units from a receiving event to specific boxes on the ASN.
          </Text>

          <Input
            {...eventId.field}
            helpText="The id of the event that you'd like to preview/assign"
            placeholder="002a36a8-be27-584a-a834-3e7d3a7123e9"
            label="Event Id"
            required
            hasError={!!eventId.fieldState.error}
            errorMessage={eventId.fieldState.error?.message}
          />
          <Text size="label" bold>
            Box Assignments
          </Text>
          {boxAssignmentFields.map((x, index) => (
            <div key={`${x.boxLabel}-${index}`}>
              <Text>Box {index + 1}</Text>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Controller
                  control={control}
                  name={`boxAssignments.${index}.boxLabel` as const}
                  render={({ field }) => (
                    <Input
                      {...field}
                      helpText="The box label that you'd like to assign units to"
                      placeholder="CD123456789"
                      label={`Box Label`}
                      required
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`boxAssignments.${index}.qty` as const}
                  render={({ field }) => (
                    <Input
                      {...field}
                      helpText="The number of units you'd like to assign to this box"
                      placeholder="10"
                      label={`Quantity`}
                      required
                      type="number"
                      min={0}
                    />
                  )}
                />
                <Button onClick={() => remove(index)} loading={isSubmitting}>
                  Remove
                </Button>
              </div>
            </div>
          ))}

          {!previewResults ? (
            <>
              <Button onClick={() => append({ boxLabel: "", qty: 0 })} loading={isSubmitting}>
                Add another box
              </Button>{" "}
              <Button type="submit" loading={isSubmitting}>
                Preview
              </Button>
            </>
          ) : null}
        </StyledForm>
      </Card>
      {previewResults ? (
        <BoxReset marginTop="S4">
          <AttributionResults
            attributionResults={previewResults}
            onCancel={resetAll}
            onApprove={applyAsnAssignment}
            isSubmitting={isSubmitting}
          />
        </BoxReset>
      ) : null}
    </Container>
  );
};
