import { OrganizationStatus } from "../OrganizationStatus";
import { OrganizationStatusReason } from "../OrganizationStatusReason";

export const OrganizationStatusReasonMap: Record<string, OrganizationStatusReason[]> = {
  [OrganizationStatus.ACTIVE]: [
    OrganizationStatusReason.NegotiatedPaymentSchedule,
    OrganizationStatusReason.TestAccount,
    OrganizationStatusReason.PaymentApplicationPending,
    OrganizationStatusReason.ChurnRisk,
    OrganizationStatusReason.OpenDispute,
  ],
  [OrganizationStatus.INACTIVE]: [
    OrganizationStatusReason.Offboarded,
    OrganizationStatusReason.NegotiatedPaymentSchedule,
    OrganizationStatusReason.TestAccount,
    OrganizationStatusReason.PaymentApplicationPending,
    OrganizationStatusReason.ChurnRisk,
    OrganizationStatusReason.OpenDispute,
    OrganizationStatusReason.Suspended,
    OrganizationStatusReason.ExceedingDueTolerance,
  ],
  [OrganizationStatus.SUSPENDED]: [OrganizationStatusReason.Suspended, OrganizationStatusReason.ExceedingDueTolerance],
};
