import { toast } from "@deliverr/ui";
import { logError } from "utils/logger";
import { useClientsWithAuth } from "../../../hooks/auth/useClientsWithAuth";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { useState } from "react";

type FormValues = {
  masterAirwayBill: string;
};

export const useNetCHBProcess = () => {
  const { parcelClient } = useClientsWithAuth();
  const { control, handleSubmit, reset } = useForm<FormValues>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const masterAirwayBill = useController({
    name: "masterAirwayBill",
    control,
    rules: {
      required: true,
    },
    defaultValue: "",
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsSubmitting(true);
    try {
      await parcelClient.createMasterAirwayBillJob(data);
      toast.success("Master Airwaybill Number processed successfully!", { position: "top-right" });
    } catch (err) {
      logError({ fn: "useNetCHBProcess.handleProcess" }, err as Error);
      toast.error("Master Airwaybill Number processing failed!", { position: "top-right" });
    } finally {
      setIsSubmitting(false);
      reset();
    }
  };

  return {
    masterAirwayBill,
    handleSubmit: handleSubmit(onSubmit),
    isSubmitting,
  };
};
