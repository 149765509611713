import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { Sort } from "types/Sort";

export interface SearchRequest {
  searchTerm?: string;
  filters?: string[];
  customizedOpenSearchFilters?: QueryDslQueryContainer[];
  page?: number;
  pageSize?: number;
  sort?: Sort;
  highlightMatches?: boolean;
  clearCacheOnSearch?: boolean;
}

export interface SearchResultsResponse {
  nbHits: number;
  nbPages: number;
  hitsPerPage: number;
  page: number;
}

export interface SearchResults {
  hits: any[];
  response: SearchResultsResponse;
}

export abstract class SearchService {
  async search(
    query: string,
    pageNum: number = 0,
    sortBy?: Sort,
    filters: string[] = [],
    resultsSize?: number
  ): Promise<SearchResults> {
    return this.execute({
      searchTerm: query,
      page: pageNum,
      pageSize: resultsSize,
      filters,
      sort: sortBy,
    });
  }

  clearCache(): SearchService {
    return this;
  }

  public abstract execute(request: SearchRequest): Promise<SearchResults>;
}
