interface FormSelectOption {
  value: string;
  label: string;
}

export enum BusinessDay {
  MON = "Mon",
  TUE = "Tue",
  WED = "Wed",
  THU = "Thu",
  FRI = "Fri",
  SAT = "Sat",
  SUN = "Sun",
}

export const BusinessDayNames: Record<BusinessDay, string> = {
  [BusinessDay.MON]: "Monday",
  [BusinessDay.TUE]: "Tuesday",
  [BusinessDay.WED]: "Wednesday",
  [BusinessDay.THU]: "Thursday",
  [BusinessDay.FRI]: "Friday",
  [BusinessDay.SAT]: "Saturday",
  [BusinessDay.SUN]: "Sunday",
};

export const BusinessDayFormOptions: FormSelectOption[] = [
  {
    value: BusinessDay.MON,
    label: BusinessDayNames[BusinessDay.MON],
  },
  {
    value: BusinessDay.TUE,
    label: BusinessDayNames[BusinessDay.TUE],
  },
  {
    value: BusinessDay.WED,
    label: BusinessDayNames[BusinessDay.WED],
  },
  {
    value: BusinessDay.THU,
    label: BusinessDayNames[BusinessDay.THU],
  },
  {
    value: BusinessDay.FRI,
    label: BusinessDayNames[BusinessDay.FRI],
  },
  {
    value: BusinessDay.SAT,
    label: BusinessDayNames[BusinessDay.SAT],
  },
  {
    value: BusinessDay.SUN,
    label: BusinessDayNames[BusinessDay.SUN],
  },
];
