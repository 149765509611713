import { useState } from "react";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useForm, useController, SubmitHandler } from "react-hook-form";
import { logError } from "utils/logger";
import { AttributionResults } from "@deliverr/legacy-inbound-client/lib/models/ReceiveAttribution";
import { useOnFormValueChange } from "hooks/useOnFormValueChange";

type FormValues = {
  targetAsnId: number;
  boxLabel: string;
};

export const useAsnPackageLink = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewResults, setPreviewResults] = useState<AttributionResults>();
  const { control, handleSubmit, reset, getValues, watch } = useForm<FormValues>({});
  const { inboundClient } = useClientsWithAuth();

  useOnFormValueChange(watch, () => setPreviewResults(undefined));

  const targetAsnIdController = useController({
    name: "targetAsnId",
    control,
    rules: {
      required: true,
      validate: (value) => value > 0,
    },
    defaultValue: 0,
  });

  const boxLabelController = useController({
    name: "boxLabel",
    control,
    rules: {
      required: true,
    },
    defaultValue: "",
  });

  const onSubmitUpdateAsnPackageLink: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsSubmitting(true);
      const previewResults = await inboundClient.previewAsnPackageLinkAttribution({
        asnId: Number(data.targetAsnId),
        boxLabel: data.boxLabel,
      });
      setIsSubmitting(false);
      if (previewResults.error) {
        if (previewResults.error.message) {
          toast.error(previewResults.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }

      setPreviewResults(previewResults.data);
      toast.success("Preview successful", { position: "top-right" });
    } catch (error) {
      logError({ fn: "useAsnPackageLink.onSubmitUpdateAsnPackageLink" }, error as Error);
      if ((error as any).response?.data?.error?.payload) {
        toast.error((error as any).response.data.error.payload, { position: "top-right" });
      } else {
        toast.error("Something went wrong, try again or contact engineering", { position: "top-right" });
        toast.error((error as any).message, { position: "top-right" });
      }
    }
    setIsSubmitting(false);
  };

  function resetAll() {
    reset();
    setIsSubmitting(false);
    setPreviewResults(undefined);
  }

  async function persistAttribution() {
    try {
      const submissionValues = getValues();
      if (!submissionValues.targetAsnId) {
        toast.error("ASN Id is required");
        return;
      }
      if (!submissionValues.boxLabel) {
        toast.error("Box Label is required");
        return;
      }
      setIsSubmitting(true);
      const persistResults = await inboundClient.persistAsnPackageLinkAttribution({
        asnId: Number(submissionValues.targetAsnId),
        boxLabel: submissionValues.boxLabel,
      });
      setIsSubmitting(false);
      if (persistResults.error) {
        if (persistResults.error.message) {
          toast.error(persistResults.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering", { position: "top-right" });
        return;
      }
      toast.success("Attribution committed successfully", { position: "top-right" });
      resetAll();
    } catch (error) {
      setIsSubmitting(false);
      logError({ fn: "useAsnPackageLink.persistAttribution" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering", { position: "top-right" });
    }
  }

  return {
    onSubmitUpdateAsnPackageLink: handleSubmit(onSubmitUpdateAsnPackageLink),
    targetAsnIdController,
    boxLabelController,
    resetAll,
    isSubmitting,
    previewResults,
    persistAttribution,
  };
};
