import { Button } from "@deliverr/ui";
import { useViewFacilityZones } from "./useViewFacilityZones";

import { FacilityZonesFormAndTable } from "../FacilityZonesFormAndTable/FacilityZonesFormAndTable";

export const ViewFacilityZones = () => {
  const { facilityIdController, currentFacilityId, facilityZonesData, isSubmitting, onSubmit, removeZone } =
    useViewFacilityZones();

  const facilityZoneRowData: [string, JSX.Element][] = facilityZonesData.map((zone) => {
    return [
      zone.zoneName,
      <Button
        onClick={() => {
          removeZone(zone.zoneId, zone.zoneName);
        }}
        loading={isSubmitting}
      >
        Delete
      </Button>,
    ];
  });

  return (
    <FacilityZonesFormAndTable
      onSubmit={onSubmit}
      facilityIdInputController={facilityIdController}
      isSubmitting={isSubmitting}
      rowValues={facilityZoneRowData}
      currentFacilityId={currentFacilityId}
    />
  );
};
