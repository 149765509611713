import { AttributionResults as AttributionResultsType } from "@deliverr/legacy-inbound-client/lib/models/ReceiveAttribution";
import { BoxReset, Button, Card, Table, Text } from "@deliverr/ui";
import _ from "lodash";

export interface AttributionResultsProps {
  attributionResults: AttributionResultsType;
  onCancel: () => void;
  onApprove: () => void;
  isSubmitting: boolean;
}

export const AttributionResults = ({
  attributionResults,
  onCancel,
  onApprove,
  isSubmitting,
}: AttributionResultsProps) => {
  return (
    <div>
      <Card className="mt-lg">
        <Text size="bodyLarge" bold>
          Preview Results
        </Text>
        <BoxReset marginTop="S4">
          <Text size="body" bold>
            Shipment Attribution Changes
          </Text>
        </BoxReset>
        <Table
          widths={["33%", "33%", "33%"]}
          headings={["SHIPMENT", "DSKU", "CHANGE"]}
          rows={_(attributionResults.inboundTransferAttributionDeltas)
            .groupBy((a) => a.shipmentId + "-" + a.dsku)
            .map((group, key) => {
              let [shipmentId, dsku] = key.split("-");
              return {
                shipmentId,
                dsku,
                attributedDelta: _.sumBy(group, "attributedDelta"),
              };
            })
            .value()
            .map((delta) => [
              <div>{delta.shipmentId}</div>,
              <div>{delta.dsku}</div>,
              <div>{delta.attributedDelta > 0 ? `+${delta.attributedDelta}` : `${delta.attributedDelta}`}</div>,
            ])}
          caption="Preview of attribution changes that will be made to the inbound event."
        />

        <BoxReset marginTop="S8">
          <Text size="body" bold>
            Box Attribution Changes
          </Text>
        </BoxReset>
        <Table
          widths={["33%", "33%", "33%"]}
          headings={["CDSKU", "DSKU", "CHANGE"]}
          rows={attributionResults.boxAttributionDeltas.map((delta) => [
            <div>{delta.boxLabel}</div>,
            <div>{delta.dsku}</div>,
            <div>{delta.attributedDelta > 0 ? `+${delta.attributedDelta}` : `${delta.attributedDelta}`}</div>,
          ])}
          caption="Preview of attribution changes that will be made to the inbound event."
        />
      </Card>
      <BoxReset marginTop="S4" marginBottom="S4">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button style={{ marginRight: "16px" }} secondary onClick={onCancel} loading={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={onApprove} loading={isSubmitting}>
            Approve
          </Button>
        </div>
      </BoxReset>
    </div>
  );
};
