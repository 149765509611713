import { Route } from "react-router-dom";
import { Path } from "paths";
import { NavBar } from "components/NavBar";
import { Container } from "components/Container";
import { ReceiveAttribution } from "./ReceiveAttribution";
import { AsnPackageLink } from "./AsnPackageLink";
import { AsnAssignmentTool } from "./AsnAssignmentTool";
import { BoxAssignmentTool } from "./BoxAssignmentTool";
import { ManageReceivingZones } from "./ManageReceivingZones";
import { EventAsnSplitTool } from "./EventAsnSplitTool";
import { ShipmentCompletionTool } from "./ShipmentCompletionTool";
import { BoxReconciliationTool } from "./BoxReconciliationTool/BoxReconciliationTool";

export const inboundHeaderRoutes = [
  <Route
    key={"inboundListHeader"}
    path={Path.inboundsList}
    element={<NavBar breadcrumbs={[{ pageName: "Inbounds" }]} />}
  />,
  <Route
    key={"receiveAttributionHeader"}
    path={Path.receiveAttribution}
    element={<NavBar breadcrumbs={[{ pageName: "Inbounds" }, { pageName: "Receive Attribution" }]} />}
  />,
  <Route
    key={"boxAssignmentToolHeader"}
    path={Path.boxAssignmentTool}
    element={<NavBar breadcrumbs={[{ pageName: "Inbounds" }, { pageName: "Box Assignment Tool" }]} />}
  />,
  <Route
    key={"updateAsnPackageLinkHeader"}
    path={Path.updateAsnPackageLink}
    element={<NavBar breadcrumbs={[{ pageName: "Inbounds" }, { pageName: "Update ASN Package Link" }]} />}
  />,
  <Route
    key={"eventAsnSplitToolHeader"}
    path={Path.eventAsnSplitTool}
    element={<NavBar breadcrumbs={[{ pageName: "Inbounds" }, { pageName: "Event ASN Split Tool" }]} />}
  />,
  <Route
    key={"asnAssignmentToolHeader"}
    path={Path.asnAssignmentTool}
    element={<NavBar breadcrumbs={[{ pageName: "Inbounds" }, { pageName: "Assign ASN to adjustments" }]} />}
  />,
  <Route
    key={"manageReceivingZonesHeader"}
    path={Path.manageReceivingZones}
    element={<NavBar breadcrumbs={[{ pageName: "Inbounds" }, { pageName: "Manage Receiving Zones" }]} />}
  />,
  <Route
    key={"shipmentCompletionToolHeader"}
    path={Path.shipmentCompletionTool}
    element={<NavBar breadcrumbs={[{ pageName: "Inbounds" }, { pageName: "Shipment Completion Tool" }]} />}
  />,
  <Route
    key={"boxReconciliationToolHeader"}
    path={Path.boxReconciliationTool}
    element={<NavBar breadcrumbs={[{ pageName: "Inbounds" }, { pageName: "Box Reconciliation Tool" }]} />}
  />,
];

export const inboundRoutes = [
  <Route
    key={"inboundList"}
    path={Path.inboundsList}
    element={<Container>Select a tool from the dropdown on the left hand side</Container>}
  />,
  <Route key={"receiveAttribution"} path={Path.receiveAttribution} element={<ReceiveAttribution />} />,
  <Route key={"boxAssignmentTool"} path={Path.boxAssignmentTool} element={<BoxAssignmentTool />} />,
  <Route key={"updateAsnPackageLink"} path={Path.updateAsnPackageLink} element={<AsnPackageLink />} />,
  <Route key={"asnAssignmentTool"} path={Path.asnAssignmentTool} element={<AsnAssignmentTool />} />,
  <Route key={"eventAsnSplitTool"} path={Path.eventAsnSplitTool} element={<EventAsnSplitTool />} />,
  <Route key={"manageReceivingZones"} path={Path.manageReceivingZones} element={<ManageReceivingZones />} />,
  <Route key={"shipmentCompletionTool"} path={Path.shipmentCompletionTool} element={<ShipmentCompletionTool />} />,
  <Route key={"boxReconciliationTool"} path={Path.boxReconciliationTool} element={<BoxReconciliationTool />} />,
];
