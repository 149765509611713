import { Button, Card, Text, ThemeProps, Input } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { NetCHBProcessInstructions } from "./NetCHBProcessInstructions";
import { useNetCHBProcess } from "./useNetCHBProcess";

const StyledCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.S6};
  `
);

const Header = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S4};
  `
);

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const NetCHBUpload: React.FC = () => {
  const { masterAirwayBill, handleSubmit, isSubmitting } = useNetCHBProcess();
  return (
    <Container>
      <StyledCard>
        <Header>
          <Text size="bodyLarge" bold>
            NetCHB Results
          </Text>
          <Text size="body">This tool is used to process NetCHB results for Section 321 parcel shipments.</Text>
        </Header>
        <StyledForm onSubmit={handleSubmit}>
          <Input
            {...masterAirwayBill.field}
            name="masterAirwayBill"
            helpText="The master airway bill number"
            placeholder="Enter MAWB#"
            label="MAWB#"
            required
            type="string"
          />
          <NetCHBProcessInstructions />
          <Button loading={isSubmitting} disabled={isSubmitting} type="submit">
            Process
          </Button>
        </StyledForm>
      </StyledCard>
    </Container>
  );
};
