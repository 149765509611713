import { LaneType, NetworkLane, ShippingMethod } from "@deliverr/carrier-network-types";
import { Control, useController } from "react-hook-form";
import { BusinessDay, BusinessDayNames } from "./BusinessDay";
import { UpdateLaneFormValues } from "./UpdateLaneFormValues";

export const useInitializeFormControllers = (
  control: Control<UpdateLaneFormValues>,
  lane: NetworkLane,
  shippingMethods: ShippingMethod[]
) => {
  // Lane Description
  const descriptionController = useController({
    name: "description",
    control,
    defaultValue: lane.description,
    rules: {
      required: true,
    },
  });

  // Is Lane Active
  const isActiveController = useController({
    name: "isActive",
    control,
    defaultValue: {
      label: lane.isActive ? "Yes" : "No",
      value: lane.isActive,
    },
    rules: {
      required: true,
    },
  });

  // Lane Shipping Methods
  const shippingMethodsController = useController({
    name: "shippingMethods",
    control,
    defaultValue: lane.shippingMethods
      ? (lane.shippingMethods
          .map((shippingMethodName) => {
            const shippingMethod = shippingMethods.find((method) => method.name === shippingMethodName);

            return shippingMethod
              ? {
                  label: shippingMethod.name,
                  value: shippingMethod.name,
                }
              : undefined;
          })
          .filter((val) => !!val) as { label: string; value: string }[])
      : [],
    rules: {
      required: true,
    },
  });

  // Local Origin Cutoff Time
  const localOriginCutoffTimeController = useController({
    name: "localOriginCutoffTime",
    control,
    defaultValue: lane.localOriginCutoffTime,
    rules: {
      required: true,
      pattern: {
        value: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
        message: "Please enter a valid time in HH:MM format",
      },
    },
  });

  // Local Origin Node Departure Time
  const localOriginNodeDepartureTimeController = useController({
    name: "localOriginNodeDepartureTime",
    control,
    defaultValue: lane.localOriginNodeDepartureTime,
    rules: {
      required: false,
      pattern: {
        value: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
        message: "Please enter a valid time in HH:MM format",
      },
    },
  });

  // Transit Hours
  const transitHoursController = useController({
    name: "transitHours",
    control,
    defaultValue: lane.transitHours?.toString() ?? "",
    disabled: lane.laneType !== LaneType.SC_TO_SC,
  });

  // Lane Shipping Methods
  const activeBusinessDaysController = useController({
    name: "activeBusinessDays",
    control,
    defaultValue: lane.activeBusinessDays.map((day) => {
      const businessDay = day as BusinessDay;
      if (!BusinessDayNames[businessDay]) {
        return {
          value: day,
          label: "Unknown Day",
        };
      }
      return {
        value: day,
        label: BusinessDayNames[businessDay],
      };
    }),
  });

  return {
    description: descriptionController,
    isActive: isActiveController,
    shippingMethods: shippingMethodsController,
    localOriginCutoffTime: localOriginCutoffTimeController,
    localOriginNodeDepartureTime: localOriginNodeDepartureTimeController,
    transitHours: transitHoursController,
    activeBusinessDays: activeBusinessDaysController,
  };
};
