import { AttributionResults } from "@deliverr/legacy-inbound-client/lib/models/ReceiveAttribution";
import { toast } from "@deliverr/ui";
import { useQuery } from "hooks";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useOnFormValueChange } from "hooks/useOnFormValueChange";
import { useState } from "react";
import { useForm, useController, SubmitHandler } from "react-hook-form";
import { useMount } from "react-use";
import { logError } from "utils/logger";

type FormValues = {
  eventId: string;
};

export const useReceiveAttribution = () => {
  const query = useQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [previewResults, setPreviewResults] = useState<AttributionResults>();
  const { control, handleSubmit, reset, getValues, setValue, watch } = useForm<FormValues>({});
  const { inboundClient } = useClientsWithAuth();

  useOnFormValueChange(watch, () => setPreviewResults(undefined));

  const eventId = useController({
    name: "eventId",
    control,
    rules: {
      required: true,
    },
    defaultValue: "",
  });

  useMount(async () => {
    if (query.get("eventId")) {
      const decodedEventId = atob(query.get("eventId")!);
      setValue("eventId", decodedEventId);
      await onSubmitPreview({ eventId: decodedEventId });
    }
  });

  const onSubmitPreview: SubmitHandler<FormValues> = async (data) => {
    setIsSubmitting(true);
    try {
      const previewResults = await inboundClient.previewAttribution({
        warehouseEventId: data.eventId,
      });
      setIsSubmitting(false);
      if (previewResults.error) {
        if (previewResults.error.message) {
          toast.error(previewResults.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }

      setPreviewResults(previewResults.data);
      toast.success("Preview successful", { position: "top-right" });
    } catch (error) {
      setIsSubmitting(false);
      logError({ fn: "useReceiveAttribution.onSubmitPreview" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  function resetAll() {
    reset({});
    setPreviewResults(undefined);
    setIsSubmitting(false);
  }

  async function persistAttribution() {
    try {
      const submissionValues = getValues();
      if (!submissionValues.eventId) {
        toast.error("Event Id is required");
        return;
      }
      setIsSubmitting(true);
      const persistResults = await inboundClient.persistAttribution({
        warehouseEventId: submissionValues.eventId,
      });
      setIsSubmitting(false);
      if (persistResults.error) {
        if (persistResults.error.message) {
          toast.error(persistResults.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering", { position: "top-right" });
        return;
      }
      toast.success("Attribution committed successfully", { position: "top-right" });
      resetAll();
    } catch (error) {
      setIsSubmitting(false);
      logError({ fn: "useReceiveAttribution.persistAttribution" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering", { position: "top-right" });
    }
  }

  return {
    onSubmitPreview: handleSubmit(onSubmitPreview),
    eventId,
    previewResults,
    resetAll,
    persistAttribution,
    isSubmitting,
  };
};
