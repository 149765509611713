import { IssueGetResponse, NonComplianceClient } from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";

export async function getUnderReceiveDetails({
  issue,
  client,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
}> {
  const underReceiveIssue = await client.getIssueUnderReceiveById({ issueId: issue.id });

  const issueDetails = {
    issue: underReceiveIssue.issue,
    images: underReceiveIssue.images,
    issueEntities: underReceiveIssue.issueEntities,
    zendeskTickets: underReceiveIssue.zendeskTickets,
    fields: [
      { type: FIELD_TYPE.DSKU, value: underReceiveIssue.dsku },
      { type: FIELD_TYPE.EXPECTED_QUANTITY, value: underReceiveIssue.expectedQty },
    ],
    reimbursementData: null,
    disputeData: null,
  };

  return {
    issueDetails,
  };
}
