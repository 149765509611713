import styled from "@emotion/styled";
import { DefaultTheme } from "@deliverr/ui";

const StyledBreadcrumb = styled.ol<{}, DefaultTheme>(
  ({ theme }) => `
    list-style: none;
    padding: ${theme.spacing.S4};
    margin: 0;
    display: inline-block;
    white-space: nowrap;
`
);

export const Breadcrumb = (props: any) => <StyledBreadcrumb {...props} role="navigation" aria-label="breadcrumbs" />;

const StyledItem = styled.li<{}, DefaultTheme>(
  ({ theme }) => `
    display: inline-block;
    & a {
        color: ${theme.colors.NEUTRAL[500]};
        font-family: ${theme.font.family.TITLE!};
        font-size: ${theme.font.size.F4};
        text-overflow: ellipsis;
        :hover {
        color: ${theme.colors.BLUE[300]};
        }
    }
    :last-child a {
        font-weight: ${theme.font.weight.BOLD as string};
    }
    :nth-of-type(n + 2):before {
        color: ${theme.colors.NEUTRAL[200]};
        font-size: ${theme.font.size.F4};
        content: "\\203A";
        display: inline-block;
        padding: 0 ${theme.spacing.S4};
    }
`
);

Breadcrumb.Item = StyledItem;
