import { ShortShipReasonCode } from "@deliverr/non-compliance-client";
import { useState } from "react";
import { useForm, useController, SubmitHandler } from "react-hook-form";
import { handleNonComplianceError } from "utils/handleNonComplianceError";

type FormValues = {
  reasonCode: { value: ShortShipReasonCode; label: string } | null;
  supportNotes: { value: string; label: string } | null;
};

export enum KittingInstructions {
  LEFTOVER_COMPONENTS_DISPOSED = "LEFTOVER_COMPONENTS_DISPOSED",
  LEFTOVER_COMPONENTS_D2C = "LEFTOVER_COMPONENTS_D2C",
  LEFTOVER_COMPONENTS_RS = "LEFTOVER_COMPONENTS_RS",
  LEFTOVER_COMPONENTS_SHIP_TO_MERCHANT = "LEFTOVER_COMPONENTS_SHIP_TO_MERCHANT",
}

export const KittingEnumToHumanReadable = {
  [KittingInstructions.LEFTOVER_COMPONENTS_DISPOSED]: "Dispose of the remaining components",
  [KittingInstructions.LEFTOVER_COMPONENTS_D2C]: "Send the remaining components to D2C",
  [KittingInstructions.LEFTOVER_COMPONENTS_RS]: "Send the remaining components to RS",
  [KittingInstructions.LEFTOVER_COMPONENTS_SHIP_TO_MERCHANT]: "Ship the remaining components to the merchant",
};

export const useSetReasonCodeAction = (params: {
  setReasonCode: (params: { reasonCode: ShortShipReasonCode; supportNotes: string | null }) => Promise<void>;
}) => {
  const { setReasonCode } = params;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({});

  const reasonCode = useController({
    name: "reasonCode",
    defaultValue: null,
    control,
    rules: {
      required: true,
    },
  });

  const supportNotes = useController({
    name: "supportNotes",
    defaultValue: null,
    control,
    rules: {
      required: false,
    },
  });

  const handleUnknownError = (error: Error) => {
    setIsSubmitting(false);
    handleNonComplianceError({ fn: "useSetReasonCodeAction.handleUnknownError" }, error as Error);
  };

  const onSubmitForm: SubmitHandler<FormValues> = async (data) => {
    setIsSubmitting(true);

    try {
      if (setReasonCode && data.reasonCode) {
        await setReasonCode({ reasonCode: data.reasonCode.value, supportNotes: data.supportNotes?.value || null });
        setIsSubmitting(false);
        reset();
      }
      return;
    } catch (error) {
      handleUnknownError(error as Error);
    }
  };

  return {
    reasonCode,
    supportNotes,
    onSubmit: handleSubmit(onSubmitForm),
    isSubmitting,
  };
};
