import { Input, Modal, Stack, StyledButton, Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FC } from "react";

interface EditAmountModalProps {
  toleranceAmount: number;
  setToleranceAmount: (amount: number) => void;
  isEditAmountModalOpen: boolean;
  closeEditAmountModal: () => void;
  updateToleranceAmount: () => void;
  isUpdateAmountDisabled: boolean;
}

const StyledButtonStack = styled(Stack)`
  width: 100%;
  justify-content: flex-end;
`;

export const EditAmountModal: FC<EditAmountModalProps> = ({
  toleranceAmount,
  setToleranceAmount,
  isEditAmountModalOpen,
  closeEditAmountModal,
  updateToleranceAmount,
  isUpdateAmountDisabled,
}) => {
  return (
    <Modal show={isEditAmountModalOpen} onClose={closeEditAmountModal}>
      <Stack gap="S4">
        <Title as="h3" displayAs="h4">
          Edit Tolerance Amount
        </Title>
        <Input
          onChange={(e: any) => setToleranceAmount(e.target.value)}
          required
          label="Tolerance Amount"
          value={toleranceAmount}
        />

        <StyledButtonStack direction="HORIZONTAL">
          <StyledButton secondary onClick={closeEditAmountModal}>
            Cancel
          </StyledButton>
          <StyledButton onClick={updateToleranceAmount} disabled={isUpdateAmountDisabled}>
            Update Amount
          </StyledButton>
        </StyledButtonStack>
      </Stack>
    </Modal>
  );
};
