import { Route } from "react-router-dom";
import { Path } from "paths";
import { NavBar } from "components/NavBar";
import { UnlabeledBoxTool } from "./UnlabeledBoxTool/UnlabeledBoxTool";
import { IssueActionTool } from "./IssueActionTool/IssueActionTool";

export const nonComplianceHeaderRoutes = [
  <Route
    key={"issueCancellationToolHeader"}
    path={Path.issueCancellationTool}
    element={<NavBar breadcrumbs={[{ pageName: "Non Compliance" }, { pageName: "Issue Cancellation Tool" }]} />}
  />,
  <Route
    key={"unlabeledBoxToolHeader"}
    path={Path.unlabeledBoxTool}
    element={<NavBar breadcrumbs={[{ pageName: "Non Compliance" }, { pageName: "Unlabeled Box Tool" }]} />}
  />,
  <Route
    key={"issueActionToolHeader"}
    path={Path.issueActionTool}
    element={<NavBar breadcrumbs={[{ pageName: "Non Compliance" }, { pageName: "Issue Action Tool" }]} />}
  />,
];

export const nonComplianceRoutes = [
  <Route key={"unlabeledBoxToolHeader"} path={Path.unlabeledBoxTool} element={<UnlabeledBoxTool />} />,
  <Route key={"issueActionTool"} path={Path.issueActionTool} element={<IssueActionTool />} />,
];
