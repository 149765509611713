import logger from "../../logger";
import { datadogLogs } from "@datadog/browser-logs";
import { InitConfiguration } from "@datadog/browser-core";
import { getIsValidDataDogEnv } from "./isValidDataDogEnv";

export function initDataDog() {
  if (!getIsValidDataDogEnv()) {
    return;
  }

  const baseConfig: Pick<InitConfiguration, "clientToken" | "site" | "service" | "env" | "version"> = {
    clientToken: "pub7bf017dbe0565eac12dc4d5be68171ab",
    site: "datadoghq.com",
    service: "supportportal-prod",
    env: "production",
  };

  // Initialize Browser Logger
  datadogLogs.init({
    ...baseConfig,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });

  logger.info({ fn: "initDataDog" }, "Initializing DataDog!");
}
