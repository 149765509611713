import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";

import { AuthProvider, EmotionCache, ProtectedRoute } from "./base";
import { CenterSpinner } from "./components/LoadingSpinner";
import { SupportApp } from "./SupportApp";
import "react-medium-image-zoom/dist/styles.css";

export const App: React.FC = () => {
  return (
    <RecoilRoot>
      <Suspense fallback={<CenterSpinner loading />}>
        <EmotionCache>
          <Router>
            <AuthProvider>
              <Routes>
                <Route path="*" element={<ProtectedRoute component={SupportApp} />} />
              </Routes>
            </AuthProvider>
          </Router>
        </EmotionCache>
      </Suspense>
    </RecoilRoot>
  );
};
