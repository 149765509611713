import { BoxReset, Button, Card, Grid, Input, Text } from "@deliverr/ui";
import { useShipmentAssignmentCard } from "./useShipmentAssignmentCard";
import { AttributionResults } from "components/inbounds/AttributionResults";
import { UnlabeledBoxData } from "@deliverr/legacy-inbound-client";

type BoxAssignmentCardProps = {
  boxLabel: string;
  boxData: UnlabeledBoxData[];
  onAssignedToShipment: (boxLabel: string) => void;
  onCancel: () => void;
};

export const ShipmentAssignmentCard: React.FC<BoxAssignmentCardProps> = ({
  boxLabel,
  onAssignedToShipment,
  onCancel,
  boxData,
}) => {
  const {
    previewResults,
    resetAll,
    applyShipmentAssignment,
    isSubmittingPreview,
    isSubmittingCommit,
    shipmentId,
    setShipmentId,
    onSubmitPreview,
  } = useShipmentAssignmentCard({
    boxLabel,
    onAssignedToShipment,
  });
  return (
    <Card style={{ alignSelf: "center" }}>
      <Grid>
        <div>
          <Text bold>Box Label: {boxLabel}</Text>
          {boxData.map(({ dsku, totalUnits }) => (
            <Text key={dsku}>
              {dsku}: {totalUnits} units
            </Text>
          ))}
        </div>
        {!previewResults && (
          <>
            <Input
              label="Shipment Id"
              helpText="The id of the Shipment you believe these received units should be attributed to"
              required
              disabled={!!previewResults}
              value={shipmentId}
              onChange={(e) => setShipmentId(e.target.value)}
            />
            <Grid columns="1fr 1fr">
              <Button secondary onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={isSubmittingPreview} onClick={onSubmitPreview}>
                Preview Attribution
              </Button>
            </Grid>
          </>
        )}
        {previewResults ? (
          <BoxReset marginTop="S4">
            <AttributionResults
              attributionResults={previewResults}
              onCancel={resetAll}
              onApprove={applyShipmentAssignment}
              isSubmitting={isSubmittingCommit}
            />
          </BoxReset>
        ) : null}
      </Grid>
    </Card>
  );
};
