import React from "react";
import { Button, IconV2, Modal, Text, BoxReset, Stack, Title, mobileTheme } from "@deliverr/ui";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";

import styled from "@emotion/styled";

interface TextProps {
  title: string;
  text?: string;
}

type GenericWarningModalProps = TextProps &
  (
    | {
        onCancel: () => void;
        onConfirm: () => void;
      }
    | {
        onCancel?: never;
        onConfirm: () => void;
      }
    | {
        onCancel: () => void;
        onConfirm?: never;
      }
  );
const ModalStack = styled(Stack)`
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 5%;
`;

export const GenericWarningModal: React.FC<GenericWarningModalProps> = ({ onCancel, onConfirm, title, text }) => {
  return (
    <Modal hasCloseButton={false}>
      <ModalStack center gap="S4">
        <IconV2 type={"exclamation-triangle"} color={mobileTheme.colors.RED[300]} size={"3x"} />
        {title && (
          <Title as="h2" displayAs="h3" textAlign={TextAlign.center}>
            {title}
          </Title>
        )}
        {text && (
          <BoxReset marginBottom="S2">
            <Text textAlign={TextAlign.center} appearance="INFO">
              {text}
            </Text>
          </BoxReset>
        )}
        <ButtonContainer>
          {onConfirm && (
            <Button block data-testid="generic-warning-confirm-btn" onClick={onConfirm}>
              Confirm
            </Button>
          )}
          {onCancel && (
            <Button block onClick={onCancel} secondary>
              Back
            </Button>
          )}
        </ButtonContainer>
      </ModalStack>
    </Modal>
  );
};
