import { IssueImageGetResponse } from "@deliverr/non-compliance-client";
import { Grid, Text } from "@deliverr/ui";
import Zoom from "react-medium-image-zoom";

interface ThumbnailsProps {
  images: IssueImageGetResponse[];
}
export const Thumbnails = ({ images }: ThumbnailsProps) => {
  return (
    <div style={{ width: "100%" }}>
      <Text as="span" bold size="label">
        Images:
      </Text>
      <Grid columns="repeat(auto-fill, minmax(100px, 1fr))" gap="S2">
        {images.map((image) => (
          <div key={image.s3Key}>
            <Text>{image.imageType}</Text>
            <Zoom>
              <img src={image.url} alt={image.s3Key} style={{ width: "100%" }} />
            </Zoom>
          </div>
        ))}
      </Grid>
    </div>
  );
};
