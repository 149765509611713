import { useState } from "react";

export enum ZONE_MANAGEMENT_VIEWS {
  ADD_FACILITY_ZONE = "Add Facility Zone",
  LIST_DELETE_FACILITY_ZONES = "View Facility Zones",
  ADD_MERCHANT_ZONE_ASSIGNMENTS = "Assign Merchants To Zones",
  LIST_DELETE_MERCHANT_ZONE_ASSIGNMENTS = "View Merchant Zone Assignments",
}

export const useManageReceivingZones = () => {
  const [managementView, setManagementView] = useState<ZONE_MANAGEMENT_VIEWS>(ZONE_MANAGEMENT_VIEWS.ADD_FACILITY_ZONE);

  const updateLocationView = (value: any) => {
    setManagementView(value);
  };

  return { updateLocationView, managementView };
};
