import { DefaultTheme } from "@deliverr/ui";
import { IconV2, IconV2Types } from "@deliverr/ui/lib-esm/IconV2";
import { Appearance } from "@deliverr/ui/lib-esm/shared";
import styled from "@emotion/styled";
import React, { FC, ReactNode } from "react";
import { sidebarTheme } from "./SidebarTheme";

const Content = styled.div<{ secondary?: boolean }, DefaultTheme>(
  ({ secondary, theme }) => `
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 ${theme.spacing.S2};
    font-size: ${secondary ? theme.font.size.F1 : theme.font.size.F2};
    font-weight: ${theme.font.weight.BOLD as string};
  `
);

const Icon = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(${sidebarTheme.item.iconSize} + ${theme.spacing.S3});
    height: ${sidebarTheme.item.iconSize};
    padding-right: ${theme.spacing.S3};

    > svg {
      width: ${sidebarTheme.item.iconSize};
      height: ${sidebarTheme.item.iconSize};
    }
  `
);

const Description = styled.div`
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const AttentionIcon = styled.svg<{ active?: boolean; attentionIcon: Appearance }, DefaultTheme>(
  ({ active, attentionIcon, theme }) => `
    flex-shrink: 0;
    padding-left: ${theme.spacing.S2};
    width: calc(18px + ${theme.spacing.S2});

    path {
      fill: ${active ? theme.colors.NEUTRAL["00"] : theme.colors[theme.config.colorByAppearance[attentionIcon]]["300"]};
    }
  `
);

const ItemCount = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    margin: auto;
    margin-right: 0;
    padding: 0 ${theme.spacing.S1};
    color: ${theme.colors.NEUTRAL["00"]};
    background-color: ${theme.colors.RED[300]};
    font-weight: ${theme.font.weight.BOLD};
    border-radius: ${theme.border.radius.R2};
  `
);

export interface SidebarItemContentProps {
  icon?: IconV2Types;
  logo?: ReactNode;
  description: JSX.Element | string;
  secondary?: boolean;
  active?: boolean;
  attentionIcon?: Appearance;
  itemCount?: number;
}

/**
 * Content area within sidebar item. This is used along with SidebarItemContainer to build a sidebar item.
 */
export const SidebarItemContent: FC<SidebarItemContentProps> = ({
  icon,
  logo,
  description,
  secondary,
  active,
  attentionIcon,
  itemCount,
}) => (
  <Content secondary={secondary}>
    {icon && (
      <Icon>
        <IconV2 type={icon} />
      </Icon>
    )}
    {logo && <Icon>{logo}</Icon>}
    <Description>{description}</Description>
    {attentionIcon && (
      <AttentionIcon
        active={active}
        attentionIcon={attentionIcon}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9 0.8125C4.32422 0.8125 0.5625 4.57422 0.5625 9.25C0.5625 13.9258 4.32422 17.6875 9 17.6875C13.6758 17.6875 17.4375 13.9258 17.4375 9.25C17.4375 4.57422 13.6758 0.8125 9 0.8125ZM8.15625 5.59375C8.15625 5.13672 8.54297 4.75 9 4.75C9.45703 4.75 9.84375 5.13672 9.84375 5.59375V10.0938C9.84375 10.5508 9.45703 10.9375 9 10.9375C8.54297 10.9375 8.15625 10.5508 8.15625 10.0938V5.59375ZM9 14.3125C8.40234 14.3125 7.91016 13.8203 7.91016 13.2227C7.91016 12.5898 8.40234 12.0977 9 12.0977C9.59766 12.0977 10.0898 12.5898 10.0898 13.2227C10.0898 13.8203 9.59766 14.3125 9 14.3125Z" />
      </AttentionIcon>
    )}
    {itemCount !== undefined && itemCount > 0 && <ItemCount>{itemCount}</ItemCount>}
  </Content>
);
