import { NetworkLane } from "@deliverr/carrier-network-types";
import { createColumnHelper, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { ActiveLanePill } from "./ActiveLanePill";
import { fuzzyFilter } from "./laneTableUtils";

export const useLaneTable = (tableLaneData: NetworkLane[]) => {
  const [globalFilter, setGlobalFilter] = useState("");

  // Generate table columns on mount
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<NetworkLane>();
    return [
      columnHelper.accessor("description", {
        header: "Lane Description",
      }),
      columnHelper.accessor("laneType", {
        header: "Lane Type",
        cell: (info) => <>{info.getValue()}</>,
      }),
      columnHelper.accessor("originNodeId", {
        header: "Origin Node",
      }),
      columnHelper.accessor("destinationNodeId", {
        header: "Destination Node",
      }),
      columnHelper.accessor("activeBusinessDays", {
        header: "Active Business Days",
        cell: (info) => (
          <span title={info.getValue().join(", ")}>
            {info
              .getValue()
              .map((day) => day.substring(0, 1))
              .join(", ")}
          </span>
        ),
      }),
      columnHelper.accessor("localOriginCutoffTime", {
        header: "Local Origin Cutoff Time",
      }),
      columnHelper.accessor("isActive", {
        header: "Active Lane",
        cell: (info) => {
          const isLaneActive = info.getValue();
          return <ActiveLanePill isActive={isLaneActive}>{isLaneActive ? "Yes" : "No"}</ActiveLanePill>;
        },
      }),
    ];
  }, []);

  // Create the table instance
  const table = useReactTable({
    data: [...tableLaneData],
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    // Sets the row ID to the lane ID
    getRowId: (row) => String(row.id),
  });

  return {
    table,
    globalFilter,
    setGlobalFilter,
  };
};
