import { Stack, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { LoadingSpinner } from "components/LoadingSpinner";
import React from "react";
import { Field, NetworkButton, NetworkButtonGroup, NetworkCard, NetworkTitle, SplitInfoSection } from "../components";
import { formatISODate } from "../utils";
import { NetworkLaneEditor } from "./NetworkLaneEditor";
import { useNetworkLaneDetails } from "./useNetworkLaneDetails";

const StyledList = styled.ul<ThemeProps>`
  ${({ theme }) => `
    max-height: 200px;
    overflow-y: auto;
    font-size: ${theme.font.size.F2};
    color: ${theme.colors.NEUTRAL[300]};
  `}
`;

export const NetworkLaneDetails: React.FC = () => {
  const {
    loading,
    isEditingLane,
    lane,
    originNode,
    destinationNode,
    handleNodeDetailsClick,
    handleBackClick,
    toggleLaneEditor,
    handleLaneEditorOnFinish,
  } = useNetworkLaneDetails();

  return (
    <Container fullWidth={false}>
      {lane && isEditingLane ? (
        <NetworkLaneEditor lane={lane} handleBackClick={toggleLaneEditor} onFinish={handleLaneEditorOnFinish} />
      ) : (
        <>
          <NetworkCard>
            <LoadingSpinner loading={loading} text="Loading Lane Details">
              {lane ? (
                <>
                  <NetworkTitle size="h2">Network Lane Information</NetworkTitle>
                  <SplitInfoSection>
                    <Stack gap="S1">
                      <Field label="Lane ID" value={lane.id} />
                      <Field label="Lane Type" value={lane.laneType} />
                      <Field label="Description" value={lane.description} />
                      <Field label="Active Lane" value={lane.isActive ? "Yes" : "No"} />
                      <Field label="Created At" value={formatISODate(lane.createdAt)} />
                      <Field label="Updated At" value={formatISODate(lane.updatedAt)} />
                    </Stack>
                    <Stack>
                      <Text size="bodyLarge" bold>
                        Shipping Methods
                      </Text>
                      {lane?.shippingMethods ? (
                        <StyledList>
                          {lane.shippingMethods.map((method) => (
                            <li key={method}>{method}</li>
                          ))}
                        </StyledList>
                      ) : (
                        <Text>No shipping methods for lane</Text>
                      )}
                    </Stack>
                  </SplitInfoSection>
                </>
              ) : (
                <Stack>
                  <Text size="h2">Error</Text>
                  <Text>No lane found for the provided lane ID</Text>
                </Stack>
              )}
            </LoadingSpinner>
          </NetworkCard>
          {!loading && lane && (
            <>
              <NetworkCard>
                <SplitInfoSection>
                  <Stack gap="S1">
                    <NetworkTitle size="h2">Origin Node</NetworkTitle>
                    <Field label="ID" value={originNode?.id} />
                    <Field label="Type" value={originNode?.type} />
                    <Field label="Description" value={originNode?.description} />
                    <Field label="Timezone" value={originNode?.timeZone} />
                    <Field label="Active Node" value={originNode?.isActive ? "Yes" : "No"} />
                    <NetworkButton secondary value={originNode?.id} onClick={handleNodeDetailsClick}>
                      View Origin Node Details
                    </NetworkButton>
                  </Stack>
                  <Stack gap="S1">
                    <NetworkTitle size="h2">Destination Node</NetworkTitle>
                    <Field label="ID" value={destinationNode?.id} />
                    <Field label="Type" value={destinationNode?.type} />
                    <Field label="Description" value={destinationNode?.description} />
                    <Field label="Timezone" value={destinationNode?.timeZone} />
                    <Field label="Active Node" value={destinationNode?.isActive ? "Yes" : "No"} />
                    <NetworkButton secondary value={destinationNode?.id} onClick={handleNodeDetailsClick}>
                      View Destination Node Details
                    </NetworkButton>
                  </Stack>
                </SplitInfoSection>
              </NetworkCard>
              <NetworkCard>
                <NetworkTitle size="h2">Lane Timing and Capacity</NetworkTitle>
                <SplitInfoSection>
                  <Stack gap="S1">
                    <Text bold size="bodyLarge">
                      Timing
                    </Text>
                    <Field label="Local Origin Cutoff Time" value={lane.localOriginCutoffTime} />
                    <Field label="Local Origin Node Departure Time" value={lane.localOriginNodeDepartureTime} />
                    <Field label="Transit Hours" value={lane.transitHours} />
                    <Field label="Active Business Days" value={`[${lane.activeBusinessDays.join(", ")}]`} />
                    <Field label="Holidays" value={`[${lane.holidays.join(", ")}]`} />
                  </Stack>
                  <Stack gap="S1">
                    <Text bold size="bodyLarge">
                      Capacity and Cost
                    </Text>
                    <Field label="Max Package Count" value={lane.maxPackageCount} />
                    <Field label="Max Volume (Cubic Ft)" value={lane.maxVolumeInCubicFt} />
                    <Field label="Transit Cost Per Pound" value={lane.transitCost?.costPerPound} />
                    <Field label="Transit Cost Per Cubic Ft" value={lane.transitCost?.costPerCubicFt} />
                    <Field label="Transit Cost Per Package" value={lane.transitCost?.costPerPackage} />
                  </Stack>
                </SplitInfoSection>
              </NetworkCard>
            </>
          )}
          {!loading && (
            <NetworkCard>
              <NetworkButtonGroup>
                <NetworkButton secondary onClick={handleBackClick}>
                  Back
                </NetworkButton>
                {lane && <NetworkButton onClick={toggleLaneEditor}>Edit Lane</NetworkButton>}
              </NetworkButtonGroup>
            </NetworkCard>
          )}
        </>
      )}
    </Container>
  );
};
