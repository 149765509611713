import { Container } from "components/Container";
import { NavBar } from "components/NavBar";
import { Path } from "paths";
import { Route } from "react-router-dom";
import { NetCHBUpload } from "./NetCHBUpload";

export const parcelHeaderRoutes = [
  <Route key="parcelistHeader" path={Path.parcelList} element={<NavBar breadcrumbs={[{ pageName: "Parcel" }]} />} />,
  <Route
    key="netCHBUploadHeader"
    path={Path.parcelNetCHBProcess}
    element={<NavBar breadcrumbs={[{ pageName: "Parcel" }, { pageName: "Process NetCHB Results" }]} />}
  />,
];

export const parcelRoutes = [
  <Route
    key="parcelList"
    path={Path.parcelList}
    element={<Container>Select a tool from the dropdown on the left hand side</Container>}
  />,
  <Route key="parcelNetCHBUpload" path={Path.parcelNetCHBProcess} element={<NetCHBUpload />} />,
];
