import { Button, Input, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useSellerIdentificationAction } from "./useSellerIdentificationAction";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const SellerIdentificationAction: React.FC<{ identifySeller: (sellerId: string) => Promise<void> }> = ({
  identifySeller,
}) => {
  const { sellerId, onSubmit, isSubmitting } = useSellerIdentificationAction(identifySeller);

  return (
    <StyledForm onSubmit={onSubmit}>
      <Text bold>Seller Id identification</Text>
      <Text size="body">
        If the Seller Id identified during submission is not correct please update it below. We will validate the Seller
        Id exists
      </Text>

      <Input
        {...sellerId.field}
        label="Seller Id"
        required
        maxLength={100}
        type="string"
        hasError={!!sellerId.fieldState.error}
        errorMessage={sellerId.fieldState.error?.message}
      />
      <Button type="submit" loading={isSubmitting}>
        Submit Seller Id
      </Button>
    </StyledForm>
  );
};
