import React from "react";
import { Button, Card, Text, Grid, Title, Stack, Anchor, Table, Input } from "@deliverr/ui";
import { Container } from "components/Container";
import { useUnlabeledBoxTool } from "./useUnlabeledBoxTool";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ShipmentAssignmentCard } from "./ShipmentAssignmentCard";
import { format } from "date-fns";
import { DateFormat } from "../components/DisplayDate";
import { Path } from "paths";
import { UnlabeledBoxData } from "@deliverr/legacy-inbound-client";

export const UnlabeledBoxTool = () => {
  const {
    loadingBoxes,
    unlabeledBoxesByBoxLabel,
    setSelectedBox,
    selectedBox,
    onAssignedToShipment,
    dskuController,
    sellerIdController,
    facilityIdController,
    startDateController,
    endDateController,
  } = useUnlabeledBoxTool();

  return (
    <Container>
      <Grid fullWidth style={{ justifyItems: "center" }}>
        {selectedBox ? (
          <Stack style={{ width: "70%" }}>
            <ShipmentAssignmentCard
              onAssignedToShipment={onAssignedToShipment}
              boxLabel={selectedBox}
              boxData={unlabeledBoxesByBoxLabel[selectedBox]}
              onCancel={() => setSelectedBox(null)}
            />
            <BoxDataCard boxLabel={selectedBox} boxData={unlabeledBoxesByBoxLabel[selectedBox]} />
          </Stack>
        ) : loadingBoxes ? (
          <LoadingSpinner loading />
        ) : (
          <>
            <Title displayAs="h2">Boxes Awaiting Attribution</Title>
            <Grid columns="1fr 1fr 1fr 1fr 1fr" gap="S2" fullWidth>
              <Input {...dskuController.field} label="DSKU" />
              <Input {...facilityIdController.field} label="Facility ID" />
              <Input {...sellerIdController.field} label="Seller ID" />
              <Input {...startDateController.field} label="Start Date" type="date" />
              <Input {...endDateController.field} label="End Date" type="date" />
            </Grid>
            {Object.entries(unlabeledBoxesByBoxLabel).length > 0 ? (
              Object.entries(unlabeledBoxesByBoxLabel).map(([boxLabel, boxData]) => {
                return (
                  <BoxDataCard key={boxLabel} boxLabel={boxLabel} boxData={boxData} setSelectedBox={setSelectedBox} />
                );
              })
            ) : (
              <Text>No boxes requiring attribution at the moment</Text>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

const BoxDataCard: React.FC<{
  boxLabel: string;
  boxData: UnlabeledBoxData[];
  setSelectedBox?: (label: string) => void;
}> = ({ boxLabel, boxData, setSelectedBox }) => {
  const { facilityId, boxCreatedAt, missingContainerLabelIssueId, unexpectedSkuIssueIds } = boxData[0]!;
  return (
    <Card key={boxLabel} style={{ minWidth: "70%" }}>
      <Stack>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Text>Label: {boxLabel}</Text>
          <Text>Warehouse: {facilityId}</Text>
          <Text>Created: {format(boxCreatedAt, DateFormat.ShortMonthDayYear)}</Text>
        </div>

        <Table
          widths={["33%", "33%", "33%"]}
          headings={["DSKU", "Seller ID", "Units"]}
          rows={boxData.map(({ dsku, totalUnits, sellerId }) => [
            <Text>{dsku}</Text>,
            <Text>{sellerId}</Text>,
            <Text>{totalUnits} units</Text>,
          ])}
          caption="box data"
        />

        <Text>
          Missing Label Issue:{" "}
          {missingContainerLabelIssueId ? (
            <Anchor target="_blank" href={`/${Path.issueActionTool}?issueId=${missingContainerLabelIssueId}`}>
              {missingContainerLabelIssueId}
            </Anchor>
          ) : (
            "N/A"
          )}
        </Text>
        {
          <div>
            <Text>Unexpected Sku Issues:</Text>
            {unexpectedSkuIssueIds.length > 0 ? (
              unexpectedSkuIssueIds.map((issueId) => (
                <div key={issueId}>
                  <Anchor target="_blank" href={`/${Path.issueActionTool}?issueId=${issueId}`}>
                    {issueId}
                  </Anchor>
                </div>
              ))
            ) : (
              <Text>N/A</Text>
            )}
          </div>
        }
        {setSelectedBox ? (
          <Button onClick={() => setSelectedBox(boxLabel)} style={{ width: "100%" }}>
            Select
          </Button>
        ) : null}
      </Stack>
    </Card>
  );
};
