import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { SidebarState, sidebarTheme, useSidebarContext } from "../Sidebar";

const StyledPage = styled.div<{ state: SidebarState }, DefaultTheme>(
  ({ state }) => `
    min-height: 0;
    flex: 1 1 0;
    overflow-x: auto;
    overflow-y: scroll;
    margin-left: ${state === "PINNED_OPEN" ? sidebarTheme.menu.width : "0"};
  `
);

export const Page = ({ children }: { children: React.ReactNode }) => {
  const { state } = useSidebarContext();
  const { pathname } = useLocation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <StyledPage ref={ref} state={state}>
      {children}
    </StyledPage>
  );
};
