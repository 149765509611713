import { Input } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";

const Wrapper = styled.div`
  position: relative;
  width: 20%;
  margin-bottom: 20px;
`;

interface SearchBarProps {
  currentSearchTerm: string;
  handleSearch: (currentSearchTerm: string) => void;
}

export const SearchBar: FC<SearchBarProps> = ({ currentSearchTerm, handleSearch }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  return (
    <Wrapper>
      <Input
        data-testid="search_bar"
        prefixIcon="search"
        placeholder="Search Seller ID"
        value={currentSearchTerm}
        onChange={handleChange}
      />
    </Wrapper>
  );
};
