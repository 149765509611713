import { GetNetworkResponse } from "@deliverr/carrier-network-types";
import { ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { CytoscapeGraph } from "./CytoscapeGraph";
import { useLaneGraph } from "./useLaneGraph";

interface LaneGraphProps {
  network: GetNetworkResponse;
  handleNodeOrEdgeSelection: (originNode: string, destinationNode?: string) => void;
  resetNodeOrEdgeSelection: () => void;
}

const LaneGraphContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-bottom: 1rem;
    border-bottom: 1px solid ${theme.colors.NEUTRAL[80]};
`
);

export const LaneGraph: React.FC<LaneGraphProps> = ({
  network,
  handleNodeOrEdgeSelection,
  resetNodeOrEdgeSelection,
}) => {
  const { elements } = useLaneGraph(network);

  return (
    <LaneGraphContainer>
      <CytoscapeGraph
        elements={elements}
        onNodeOrEdgeSelection={handleNodeOrEdgeSelection}
        onResetNodeOrEdgeSelection={resetNodeOrEdgeSelection}
      />
    </LaneGraphContainer>
  );
};
