import { NetworkLane } from "@deliverr/carrier-network-types";
import { DefaultTheme, Input, InputProps, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";

import { LaneTableBody } from "./LaneTableBody";
import { LaneTableHeader } from "./LaneTableHeader";
import { useLaneTable } from "./useLaneTable";

const StyledInput = styled(Input)<InputProps, DefaultTheme>`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing.S3};
  `}
`;

const StyledTable = styled.table<ThemeProps>`
  ${({ theme }) => `
    width: 100%;
    font-size: ${theme.font.size.F2};
    color: ${theme.colors.NEUTRAL[500]};
  `}
`;

interface NetworkLaneTableProps {
  laneTableData: NetworkLane[];
}

export const LaneTable: React.FC<NetworkLaneTableProps> = ({ laneTableData }) => {
  const { table, globalFilter, setGlobalFilter } = useLaneTable(laneTableData);

  return (
    <div>
      <StyledInput
        placeholder="Search all columns..."
        value={globalFilter ?? ""}
        onChange={(e) => setGlobalFilter(String(e.target.value))}
      />
      <StyledTable>
        <LaneTableHeader headerGroups={table.getHeaderGroups()} />
        <LaneTableBody rowModel={table.getRowModel()} />
      </StyledTable>
    </div>
  );
};
