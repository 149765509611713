import { IssueGetResponse, NonComplianceClient } from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";

export async function getOverReceivedSkuDetails({
  issue,
  client,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
}> {
  const overReceivedSkuIssue = await client.getIssueOverReceiveById({ issueId: issue.id });

  const issueDetails = {
    issue: overReceivedSkuIssue.issue,
    images: overReceivedSkuIssue.images,
    issueEntities: overReceivedSkuIssue.issueEntities,
    zendeskTickets: overReceivedSkuIssue.zendeskTickets,
    fields: [
      { type: FIELD_TYPE.DSKU, value: overReceivedSkuIssue.dsku },
      { type: FIELD_TYPE.EXPECTED_QUANTITY, value: overReceivedSkuIssue.expectedQty },
    ],
    reimbursementData: null,
    disputeData: null,
  };

  return {
    issueDetails,
  };
}
