import { Button, Card, FormGroup, Select, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useDockDoorLabels } from "./useDockDoorLabels";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const DockDoorLabels: React.FC = () => {
  const {
    onSubmit,
    sortationCenters,
    dockDoors,
    dockDoorController,
    sortationCenterController,
    watchSortationCenter,
    loading,
    isSubmitting,
  } = useDockDoorLabels();

  return (
    <Container>
      <StyledCard>
        <LoadingSpinner loading={loading} text="Loading Sortation Centers...">
          <Text size="bodyLarge" bold>
            Download Dock Door Labels
          </Text>
          <Text size="body">This tool is used to download dock door labels for a sortation center in PDF format</Text>
          <StyledForm onSubmit={onSubmit}>
            <FormGroup>
              <Select
                {...sortationCenterController.field}
                label="Select a sortation center"
                options={sortationCenters.map((sc) => ({ value: sc, label: sc }))}
                hasError={!!sortationCenterController.fieldState.error}
              />
              {sortationCenterController.fieldState.error && (
                <Text size="caption" appearance="DANGER">
                  Please select a sortation center
                </Text>
              )}
            </FormGroup>
            {watchSortationCenter && (
              <FormGroup>
                <Select
                  {...dockDoorController.field}
                  label="Select a dock door"
                  options={dockDoors.map((dd) => ({
                    value: dd.dockDoorId,
                    label: dd.dockDoorName,
                  }))}
                  hasError={!!dockDoorController.fieldState.error}
                />
                {dockDoorController.fieldState.error && (
                  <Text size="caption" appearance="DANGER">
                    Please select a dock door
                  </Text>
                )}
              </FormGroup>
            )}
            <Button type="submit" loading={isSubmitting}>
              Download Label
            </Button>
          </StyledForm>
        </LoadingSpinner>
      </StyledCard>
    </Container>
  );
};
