import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import { initDataDog } from "utils/third-party/DataDog/DataDog";

function renderApp() {
  initDataDog();
  ReactDOM.render(<App />, document.getElementById("root"));
}

renderApp();
