import { useForm, useController, SubmitHandler } from "react-hook-form";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { toast } from "@deliverr/ui";
import { logError } from "utils/logger";
import { useState } from "react";
import { ZoneManagementInputRules } from "../InputPatterns";

type AddFacilityZonesFormValues = {
  facilityId: string;
  zoneName: string;
};

export const useAddFacilityZones = () => {
  const { control, handleSubmit, resetField, reset } = useForm<AddFacilityZonesFormValues>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { inboundClient } = useClientsWithAuth();

  const { facilityIdInput, zoneNameInput } = ZoneManagementInputRules;

  const facilityId = useController({
    name: "facilityId",
    control,
    rules: {
      required: true,
      pattern: { value: facilityIdInput.pattern, message: facilityIdInput.message },
    },
    defaultValue: "",
  });

  const zoneName = useController({
    name: "zoneName",
    control,
    rules: {
      required: true,
      pattern: { value: zoneNameInput.pattern, message: zoneNameInput.message },
    },
    defaultValue: "",
  });

  const onSubmit: SubmitHandler<AddFacilityZonesFormValues> = async (data) => {
    // when adding zones in rapid succession, dismiss the last toast to prevent pile up
    toast.dismiss();
    setIsSubmitting(true);
    try {
      const response = await inboundClient.activateFacilityZone(data.facilityId, data.zoneName.trim());
      setIsSubmitting(false);
      if (response.error) {
        reset();
        if (response.error.message) {
          toast.error(response.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }
      //reset just the zone name field because presumably user will want to add more zones to the same facility
      resetField("zoneName");
      toast.success(`Zone ${data.zoneName} added to ${data.facilityId}`, { position: "top-right" });
    } catch (error) {
      setIsSubmitting(false);
      reset();
      logError({ fn: "useAddFacilityZones.onSubmit" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  return {
    zoneNameMaxLength: zoneNameInput.maxLength,
    facilityId,
    zoneName,
    onSubmit: handleSubmit(onSubmit),
    isSubmitting,
  };
};
