import { LogisticsSearchConfig } from "./LogisticsSearchConfig";
import { LogisticsSearchClient, SearchOptions } from "@deliverr/logistics-search-client";
import { SearchRequest, SearchResults, SearchService } from "./SearchService";
import { logError } from "utils/logger";
import { addOpenSearchFilters } from "./OpenSearchUtils";

export abstract class OpenSearchService extends SearchService {
  protected client!: LogisticsSearchClient;
  protected readonly config: LogisticsSearchConfig;

  constructor(config: LogisticsSearchConfig) {
    super();

    this.config = config;
    this.client = new LogisticsSearchClient(this.config.indexName, {
      baseURL: process.env.REACT_APP_LOGISTICS_SEARCH_URL!,
      headers: {
        Authorization: `Bearer ` + this.config.token,
      },
    });
  }

  public async execute(request: SearchRequest): Promise<SearchResults> {
    try {
      const searchOptions = this.buildSearchOptions(request);
      const size = request.pageSize ?? this.config.searchConfig.hitsPerPage;
      const requestParams = searchOptions.hydrate ? { hydrate: true } : undefined;
      const results = await this.client.search(
        {
          query: searchOptions.query,
          sort: searchOptions.sort,
          page: searchOptions.page,
          size: searchOptions.size,
          highlight: searchOptions.highlight,
        },
        requestParams
      );
      return this.processSearchResults(results, size, request);
    } catch (err) {
      logError({ fn: "OpenSearchService.execute" }, err as Error);
    }
    return {
      hits: [],
      response: {
        nbHits: 0,
        nbPages: 0,
        hitsPerPage: 1,
        page: 1,
      },
    };
  }

  protected processSearchResults(results: any, size: number, request?: SearchRequest): SearchResults {
    const hits = results.hits.hits.map((hit: any) => {
      let rsp = {
        id: hit._id,
        ...hit._source,
        raw: {
          ...hit._source,
        },
      };
      if (hit.inner_hits) {
        rsp = {
          ...rsp,
          innerHits: hit.inner_hits,
        };
      }
      return rsp;
    });
    const nbPages = Math.ceil(results.hits?.total.value / size);
    return {
      hits,
      response: {
        nbHits: results.hits?.total.value,
        nbPages,
        hitsPerPage: size,
        page: request?.page ?? 1,
      },
    };
  }

  protected buildSearchOptions(request: SearchRequest): SearchOptions {
    const size = request.pageSize ?? this.config.searchConfig.hitsPerPage;

    const openSearchQuery = addOpenSearchFilters(request.customizedOpenSearchFilters ?? []);

    let searchOptions: SearchOptions = {
      query: openSearchQuery,
      page: (request?.page ?? 0) + 1,
      size,
    };

    if (request?.sort && request?.sort?.fieldName) {
      const sort = [
        {
          [request.sort.fieldName]: {
            order: request.sort.direction,
            ...(request.sort.missing && { missing: request.sort.missing }),
            ...(request.sort.unmappedType && { unmapped_type: request.sort.unmappedType }),
          },
        },
      ];
      searchOptions = {
        ...searchOptions,
        sort,
      };
    }

    return searchOptions;
  }
}
