export const downloadCsv = (
  sortationCenterId: string,
  shippingMethod: string,
  sortCode: string,
  zipCodes: string[]
): void => {
  // Create CSV with new line as delimiter
  const csvData = zipCodes.join("\n");

  // Create csv blob for download
  const blob = new Blob([csvData], { type: "text/csv" });

  // Create invisible object link and click it to download the file
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${sortationCenterId}_${shippingMethod}_${sortCode}.csv`;
  a.click();

  // Clean up
  window.URL.revokeObjectURL(url);
};
