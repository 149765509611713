import { createColumnHelper, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo } from "react";
import { CreditBlockItem, SortFields } from "../CreditBlockItem";
import { CreditBlockListStatus } from "./CreditBlockStatus/CreditBlockListStatus";
import SortIcon from "../SortIcon";
import { DEFAULT_TOLERANCE_VALUE } from "components/finance/constants";

export const useCreditBlockTable = (currentPageItems: CreditBlockItem[], sort: SortFields, handleSort: () => void) => {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<CreditBlockItem>();
    return [
      columnHelper.accessor("sellerId", {
        header: "Seller ID",
      }),
      columnHelper.accessor("dueToleranceValuePast30Days", {
        header: "Past Due Tolerance Amount",
        cell: (info) => {
          const value = info.getValue();
          return value ? value : DEFAULT_TOLERANCE_VALUE;
        },
      }),
      columnHelper.accessor("totalBalance", {
        header: () => (
          <div>
            Total Balance
            <SortIcon sort={sort} handleSort={handleSort} />
          </div>
        ),
        cell: (info) => {
          const value = info.getValue();
          return value ?? 0;
        },
        enableSorting: true,
      }),
      columnHelper.accessor("balanceDue", {
        header: "Past Due",
        cell: (info) => {
          const value = info.getValue();
          return value ?? 0;
        },
      }),
      columnHelper.accessor("balanceDuePast30Days", {
        header: "30+ Days Past Due",
        cell: (info) => {
          const value = info.getValue();
          return value ?? 0;
        },
      }),
      columnHelper.accessor("status", {
        header: "Account Status",
        cell: (info) => <CreditBlockListStatus status={info.getValue()} />,
      }),
      columnHelper.accessor("statusReason", {
        header: "Status Reason",
        cell: (info) => {
          const value = info.getValue();
          return value ?? "...";
        },
      }),
    ];
  }, [sort, handleSort]);

  const table = useReactTable({
    data: currentPageItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => String(row.sellerId),
  });

  return {
    table,
  };
};
