import { toast } from "@deliverr/ui";
import {
  IllegibleLotUnitDisposition,
  IssueGetResponse,
  IssueStatus,
  NonComplianceClient,
} from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { ActionsProps } from "./components/Actions/Actions";
import { handleNonComplianceError } from "utils/handleNonComplianceError";

export async function getIllegibleLotDetails({
  issue,
  client,
  setIsLoading,
  refreshIssue,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
  setIsLoading: (isLoading: boolean) => void;
  refreshIssue: () => void;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
  actionProps: Pick<ActionsProps, "dispose" | "manualActionRequired" | "markAsComplete"> | null;
}> {
  const illegibleLotIssue = await client.getIssueIllegibleLotById({ issueId: issue.id });

  const issueDetails = {
    issue: illegibleLotIssue.issue,
    images: illegibleLotIssue.images,
    issueEntities: illegibleLotIssue.issueEntities,
    zendeskTickets: illegibleLotIssue.zendeskTickets,
    fields: [
      { type: FIELD_TYPE.UNIT_DISPOSITION, value: illegibleLotIssue.unitDisposition },
      { type: FIELD_TYPE.BARCODE, value: illegibleLotIssue.barcode },
      { type: FIELD_TYPE.DSKU, value: illegibleLotIssue.dsku },
    ],
    reimbursementData: null,
    disputeData: null,
  };

  const markAsComplete = async () => {
    setIsLoading(true);
    try {
      if (illegibleLotIssue) {
        await client.completeIssueIllegibleLot({ issueId: illegibleLotIssue.issue.id });
        await refreshIssue();
        setIsLoading(false);
        toast.success("Issue marked as complete", { position: "top-right" });
      } else {
        setIsLoading(false);
        toast.error("No issue present", { position: "top-right" });
      }
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "useIllegibleLotTool.markAsComplete" }, error as Error);
    }
  };

  const setDisposition = async (disposition: IllegibleLotUnitDisposition) => {
    setIsLoading(true);
    try {
      if (illegibleLotIssue) {
        await client.approveProductDispositionForIllegibleLot({
          issueId: illegibleLotIssue.issue.id,
          disposition,
        });
        await refreshIssue();
        setIsLoading(false);
        toast.success("Unit disposition updated", { position: "top-right" });
      } else {
        setIsLoading(false);
        toast.error("No issue present", { position: "top-right" });
      }
    } catch (error) {
      handleNonComplianceError({ fn: "useIllegibleLotTool.setDisposition" }, error as Error);
      setIsLoading(false);
    }
  };

  const showDispositionAction =
    illegibleLotIssue.issue.status === IssueStatus.AWAITING_SUPPORT && illegibleLotIssue.unitDisposition === null;
  const showMarkAsCompleteAction = illegibleLotIssue.unitDisposition === IllegibleLotUnitDisposition.OTHER;

  return {
    issueDetails,
    actionProps: {
      dispose: showDispositionAction ? () => setDisposition(IllegibleLotUnitDisposition.DISPOSE) : null,
      manualActionRequired: showDispositionAction ? () => setDisposition(IllegibleLotUnitDisposition.OTHER) : null,
      markAsComplete: showMarkAsCompleteAction ? markAsComplete : null,
    },
  };
}
