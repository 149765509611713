import { Pill } from "@deliverr/ui";
import { OrganizationStatus } from "components/finance/OrganizationStatus";
import { useCreditBlockListStatus } from "./useCreditBlockListStatus";

interface OwnProps {
  status: OrganizationStatus;
}

export const CreditBlockListStatus = ({ status }: OwnProps) => {
  const { color } = useCreditBlockListStatus(status);
  return status ? <Pill color={color}>{status}</Pill> : null;
};
