import { useAuth0 } from "@auth0/auth0-react";
import { AUTH_AUDIENCE } from "base/Auth";
import { useMemo } from "react";

export const useBearerToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(() => {
    const accessToken = getAccessTokenSilently({
      audience: AUTH_AUDIENCE,
    });
    return {
      accessToken,
    };
  }, [getAccessTokenSilently]);
};
