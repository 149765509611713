import { Button, Input, Text, Box, Grid } from "@deliverr/ui";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import { FacilityZonesFormAndTable } from "../FacilityZonesFormAndTable/FacilityZonesFormAndTable";
import { useAddMerchantZoneAssignments } from "./useAddMerchantZoneAssignments";
import { StyledForm } from "components/inbounds/AsnAssignmentTool";

export const AddMerchantZoneAssignments = () => {
  const {
    facilityIdController,
    merchantsController,
    currentFacilityId,
    currentZoneName,
    facilityZonesData,
    isSubmitting,
    isAssigningMerchantToZone,
    onFacilityIdSubmit,
    onMerchantSubmit,
    onCancel,
    setFacilityZoneAndSwitchToAddSellerView,
  } = useAddMerchantZoneAssignments();

  const facilityZoneRowData: [string, JSX.Element][] = facilityZonesData.map((zone) => {
    return [
      zone.zoneName,
      <Button
        onClick={() => {
          setFacilityZoneAndSwitchToAddSellerView(zone.zoneId, zone.zoneName);
        }}
        loading={isSubmitting}
      >
        Assign Merchants
      </Button>,
    ];
  });

  return isAssigningMerchantToZone ? (
    <>
      <Box marginTop={"S4"}>
        <Text size="h2" textAlign={TextAlign.center} as="header">
          Assigning Merchants To
        </Text>
        <Text size="bodyLarge" textAlign={TextAlign.center} as="header">
          <b>{`${currentFacilityId}`}</b>
        </Text>
        <Text size="bodyLarge" textAlign={TextAlign.center} as="header">
          Zone <b>{`${currentZoneName}`}</b>
        </Text>
      </Box>
      <StyledForm onSubmit={onMerchantSubmit}>
        <Input
          {...merchantsController.field}
          label="Merchant(s)"
          placeholder="gweentoysinc,flexport,etc"
          required
          hasError={!!merchantsController.fieldState.error}
          errorMessage={merchantsController.fieldState.error?.message}
        />
        <Grid columns="1fr 1fr" gap="S2">
          <Button type="submit" loading={isSubmitting}>
            Assign
          </Button>
          <Button onClick={onCancel} loading={isSubmitting} secondary>
            Cancel
          </Button>
        </Grid>
      </StyledForm>
    </>
  ) : (
    <FacilityZonesFormAndTable
      onSubmit={onFacilityIdSubmit}
      facilityIdInputController={facilityIdController}
      isSubmitting={isSubmitting}
      rowValues={facilityZoneRowData}
      currentFacilityId={currentFacilityId}
    />
  );
};
