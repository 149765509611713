export const ZoneManagementInputRules = {
  facilityIdInput: {
    pattern: /^[A-Z0-9_]+$/,
    message: "Only uppercase letters, numbers, and underscores allowed",
  },
  zoneNameInput: {
    pattern: /^[A-Z0-9]+([ ][A-Z0-9]+)*$/,
    message: "Only uppercase letters, numbers, and spaces allowed. No trailing spaces.",
    maxLength: 12,
  },
  merchantIdInput: {
    pattern: /^[a-zA-Z0-9]*$/,
    message: "Only letters and numbers allowed",
  },
  merchantIdsInput: {
    pattern: /^[a-zA-Z0-9,]*$/,
    message: "Only letters, numbers, and commas allowed. Delimit merchants using commas.",
  },
};
