import { Container } from "components/Container";
import { NavBar } from "components/NavBar";
import { Path } from "paths";
import { Route } from "react-router-dom";
import { NetworkLaneDetails } from "./NetworkLaneDetails";
import { NetworkLanes } from "./NetworkLanes";
import { NetworkNodeDetails } from "./NetworkNodeDetails";

export const carrierNetworkHeaderRoutes = [
  <Route
    key="carrierNetworkListHeader"
    path={Path.carrierNetworkList}
    element={<NavBar breadcrumbs={[{ pageName: "Carrier Network" }]} />}
  />,
  <Route
    key="carrierNetworkLanesHeader"
    path={Path.carrierNetworkLanes}
    element={<NavBar breadcrumbs={[{ pageName: "Carrier Network" }, { pageName: "Network Lane Manager" }]} />}
  />,
  <Route
    key="carrierNetworkLaneDetailsHeader"
    path={Path.carrierNetworkLaneDetails}
    element={
      <NavBar
        breadcrumbs={[
          { pageName: "Carrier Network" },
          { pageName: "Network Lane Manager" },
          { pageName: "Lane Details" },
        ]}
      />
    }
  />,
  <Route
    key="carrierNetworkNodeDetailsHeader"
    path={Path.carrierNetworkNodeDetails}
    element={
      <NavBar
        breadcrumbs={[
          { pageName: "Carrier Network" },
          { pageName: "Network Lane Manager" },
          { pageName: "Node Details" },
        ]}
      />
    }
  />,
];

export const carrierNetworkRoutes = [
  <Route
    key="carrierNetworkList"
    path={Path.carrierNetworkList}
    element={<Container>Select a tool from the dropdown on the left hand side</Container>}
  />,
  <Route key="carrierNetworkLanes" path={Path.carrierNetworkLanes} element={<NetworkLanes />} />,
  <Route key="carrierNetworkLaneDetails" path={Path.carrierNetworkLaneDetails} element={<NetworkLaneDetails />} />,
  <Route key="carrierNetworkNodeDetails" path={Path.carrierNetworkNodeDetails} element={<NetworkNodeDetails />} />,
];
