import { atom, selector, ReadOnlySelectorOptions } from "recoil";
import { ModalInstance, ModalsState } from "./ModalTypes";
import { SupportPortalModal } from "./SupportPortalModalMap";

enum SupportPortalStateKey {
  MODALS = "MODALS",
  VISIBLE_MODAL = "VISIBLE_MODAL",
}

export const visibleSupportPoralModalsState = atom<ModalsState | Record<never, string>>({
  key: SupportPortalStateKey.MODALS,
  default: {},
});

export const getVisibleModal: ReadOnlySelectorOptions<ModalInstance | undefined>["get"] = ({ get }) => {
  const visibleModals = get(visibleSupportPoralModalsState);
  const modalInstance = Object.entries(visibleModals).find(([modalType, props]) => !!modalType && !!props);

  if (!modalInstance) {
    return;
  }
  return {
    type: modalInstance[0] as SupportPortalModal,
    props: modalInstance[1],
  };
};

export const visibleSupportPortalModalValue = selector<ModalInstance | undefined>({
  key: SupportPortalStateKey.VISIBLE_MODAL,
  get: getVisibleModal,
});
