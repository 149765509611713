import { DockDoor } from "@deliverr/sortation-client";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { uniq } from "lodash/fp";
import { useState } from "react";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { useAsync } from "react-use";
import { logError } from "utils/logger";
import { downloadPdf } from "./downloadPdf";

type FormValues = {
  sortationCenter: { label: string; value: string };
  dockDoor: { label: string; value: string };
};

export const useDockDoorLabels = () => {
  // Local State
  const [sortationCenters, setSortationCenters] = useState<string[]>([]);
  const [dockDoors, setDockDoors] = useState<DockDoor[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // API Clients
  const { sortationApiClient } = useClientsWithAuth();

  // React Hook Form
  const { control, handleSubmit, watch } = useForm<FormValues>({});

  const sortationCenterController = useController({
    name: "sortationCenter",
    control,
    rules: {
      required: true,
    },
  });

  const dockDoorController = useController({
    name: "dockDoor",
    control,
    rules: {
      required: true,
    },
  });

  const watchSortationCenter = watch("sortationCenter");

  // Retrieve sortation centers on component mount
  const { loading } = useAsync(async () => {
    try {
      const currentDockDoors = await sortationApiClient.getDockDoors();
      const uniqueSortationCenters = uniq(currentDockDoors.map((d) => d.sortationCenterId)).sort();
      setSortationCenters(uniqueSortationCenters);
      setDockDoors(currentDockDoors);
    } catch (err) {
      logError({ fn: "useDockDoorLabels.useAsync" }, err as Error);
      toast.error("Unable to retrieve sortation centers", { position: "top-right" });
    }
  }, []);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsSubmitting(true);

    const sortationCenterId = data.sortationCenter.value;
    const dockDoorId = data.dockDoor.value;
    try {
      // Retrieve label for selected dock door
      const dockDoorLabel = await sortationApiClient.getDockDoorLabel(dockDoorId);
      await downloadPdf(sortationCenterId, dockDoorLabel.labelContents);

      setIsSubmitting(false);
      toast.success("Successfully downloaded dock door label", { position: "top-right" });
    } catch (error) {
      setIsSubmitting(false);
      logError({ fn: "useDockDoorLabels.onSubmit" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    sortationCenters,
    dockDoors: dockDoors
      .filter((dd) => !watchSortationCenter || dd.sortationCenterId === watchSortationCenter.value)
      .sort((a, b) => a.dockDoorName.localeCompare(b.dockDoorName)),
    dockDoorController,
    sortationCenterController,
    watchSortationCenter,
    loading,
    isSubmitting,
  };
};
