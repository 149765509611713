import { useAuth0 } from "@auth0/auth0-react";
import { DELIVERR_URL, getClaimsFromLocalStorage } from "base";
import { intersection } from "lodash";
import { useEffect, useState } from "react";
import { ROLES } from "types/Auth";

export const useIsCreditRiskAdmin = () => {
  const { getIdTokenClaims } = useAuth0();
  const [isCreditRiskAdmin, setIsCreditRiskAdmin] = useState<boolean>(false);

  const checkRole = async (): Promise<void> => {
    let claims;
    // get auth0 user info from local storage
    // @ts-ignore
    claims = window.Cypress
      ? getClaimsFromLocalStorage().body.decodedToken.user[DELIVERR_URL]
      : (await getIdTokenClaims())?.[DELIVERR_URL];
    const userRole = [...(claims.apps ?? []), ...claims.roles];
    const acceptedRoles = [ROLES.CREDIT_RISK_ADMIN];
    const isAuthorized = intersection(userRole, acceptedRoles).length > 0;
    setIsCreditRiskAdmin(isAuthorized ? true : false);
  };

  useEffect(() => {
    checkRole();
  }, [getIdTokenClaims]);

  return isCreditRiskAdmin;
};
