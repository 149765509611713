import { useAuth0 } from "@auth0/auth0-react";
import { Button, DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC } from "react";
import { SidebarState, sidebarTheme, useSidebarContext } from "../Sidebar";
import { Breadcrumb } from "./Breadcrumb";

const NavbarContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    box-shadow: rgba(0, 0, 50, 0.12) 0px 1px 4px;
    background-color: white;
    height: 4.5rem;
    z-index: 3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: ${theme.spacing.S3};
  `
);

const Breadcrumbs = styled.div<{ state: SidebarState }, DefaultTheme>(({ state, theme }) => {
  const marginLeft = state === "PINNED_OPEN" ? theme.spacing.S6 : sidebarTheme.openTarget.width;

  return `
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-left: calc(${marginLeft} - ${theme.spacing.S4});
    transition: margin-left ${sidebarTheme.transitionDuration};
  `;
});

const Children = styled.span`
  display: inline-block;
`;

const NavBarMenu = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    display: flex;
    margin-right: 1rem;

    @media (min-width: ${theme.breakpoints.MD}) {
      margin-right: 2rem;
    }
  `
);

export interface NavBreadcrumb {
  pageName: string;
  component?: any;
  path?: string;
}

export interface NavBarProps {
  breadcrumbs: NavBreadcrumb[];
  children?: JSX.Element[] | JSX.Element;
}

export const NavBar: FC<NavBarProps> = ({ breadcrumbs, children }) => {
  const { logout, isAuthenticated } = useAuth0();
  const { state } = useSidebarContext();

  return (
    <NavbarContainer>
      <Breadcrumbs state={state}>
        <Breadcrumb>
          {breadcrumbs.map((breadcrumb) => (
            <Breadcrumb.Item key={breadcrumb.pageName}>{breadcrumb.component || breadcrumb.pageName}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <Children>{children}</Children>
      </Breadcrumbs>
      <NavBarMenu>
        {isAuthenticated ? <Button onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button> : null}
      </NavBarMenu>
    </NavbarContainer>
  );
};
