import { IssueGetResponse, NonComplianceClient } from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { ActionsProps } from "./components/Actions/Actions";
import { toast } from "@deliverr/ui";
import { handleNonComplianceError } from "../../../utils/handleNonComplianceError";

export async function getMissingContainerLabelDetails({
  issue,
  client,
  setIsLoading,
  refreshIssue,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
  setIsLoading: (isLoading: boolean) => void;
  refreshIssue: () => void;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
  actionProps: Partial<ActionsProps> | null;
}> {
  const missingContainerLabelIssue = await client.getIssueMissingContainerLabelById({ issueId: issue.id });

  const issueDetails = {
    issue: missingContainerLabelIssue.issue,
    images: missingContainerLabelIssue.images,
    issueEntities: missingContainerLabelIssue.issueEntities,
    zendeskTickets: missingContainerLabelIssue.zendeskTickets,
    fields: [
      { type: FIELD_TYPE.BARCODE, value: missingContainerLabelIssue.barcode },
      { type: FIELD_TYPE.DSKU, value: missingContainerLabelIssue.dsku },
    ],
    reimbursementData: null,
    disputeData: null,
  };

  const identifySeller = async (sellerId: string) => {
    setIsLoading(true);
    try {
      await client.completeSellerIdentificationForMissingContainerLabel({
        issueId: issue.id,
        sellerId,
      });
      await refreshIssue();
      setIsLoading(false);
      toast.success("Success: Updated the sellerId on the issue", { position: "top-right" });
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "getMissingContainerLabelDetails.identifySeller" }, error as Error);
    }
  };

  return {
    issueDetails,
    actionProps: {
      identifySeller,
    },
  };
}
