import { Button, Input, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useIssueCancellationAction } from "./useIssueCancellationAction";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const IssueCancellationAction: React.FC<{ cancelIssue: (reason: string) => Promise<void> }> = ({
  cancelIssue,
}) => {
  const { reason, onSubmit, isSubmitting } = useIssueCancellationAction(cancelIssue);

  return (
    <StyledForm onSubmit={onSubmit}>
      <br />
      <hr />
      <br />
      <Text bold>Issue Cancellation</Text>
      <Text size="body">
        This tool is used to mark a non compliance issue as cancelled. Only do this if you are confident that no further
        action is required on this issue
      </Text>

      <Input
        {...reason.field}
        helpText="Short description for the reason of cancellation"
        label="Reason"
        required
        maxLength={50}
        type="string"
        hasError={!!reason.fieldState.error}
        errorMessage={reason.fieldState.error?.message}
      />
      <Button type="submit" loading={isSubmitting} appearance={"DANGER"}>
        Cancel Issue
      </Button>
    </StyledForm>
  );
};
