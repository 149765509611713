import React from "react";
import { Button, Card, Input, Select, Switch, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { useSortCodeManager } from "./useSortCodeManager";
import { LoadingSpinner } from "components/LoadingSpinner";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const CardTitleContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
  `
);

const StyledFormContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    > * {
        margin-bottom: ${theme.spacing.S4};
    }
  `
);

const StyledButton = styled(Button)`
  margin-left: auto;
`;

export const SortCodeManager: React.FC = () => {
  const {
    state,
    selectedSortationCenter,
    isNewShippingMethod,
    setIsNewShippingMethod,
    shippingMethodInputValue,
    canContinue,
    sortationCenterOptions,
    shippingMethodOptions,
    handleSortationCenterSelection,
    handleShippingMethodSelection,
    handleFormContinue,
  } = useSortCodeManager();

  return (
    <Container>
      <StyledCard>
        <LoadingSpinner loading={state.loading}>
          <CardTitleContainer>
            <Text size="bodyLarge" bold>
              Sort Code Manager
            </Text>
            <Text size="body">This tool is used to manage sort codes for sortation center's</Text>
          </CardTitleContainer>
          <StyledFormContainer>
            <Select
              onChange={handleSortationCenterSelection}
              options={sortationCenterOptions}
              label="Select a sortation center"
            />
            {isNewShippingMethod ? (
              <Input
                label="Enter a new shipping method"
                value={shippingMethodInputValue}
                onChange={handleShippingMethodSelection}
              />
            ) : (
              <Select
                onChange={handleShippingMethodSelection}
                isDisabled={!selectedSortationCenter}
                options={shippingMethodOptions}
                label={"Select a shipping method"}
              />
            )}
            <Switch
              disabled={!selectedSortationCenter}
              label={<Text size="caption">Is this a new shipping method?</Text>}
              checked={isNewShippingMethod}
              onChange={setIsNewShippingMethod}
            />
          </StyledFormContainer>
          <StyledButton disabled={!canContinue} onClick={handleFormContinue}>
            Continue
          </StyledButton>
        </LoadingSpinner>
      </StyledCard>
    </Container>
  );
};
