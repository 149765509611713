import { NonComplianceError, NonComplianceErrorCodes, SharedErrorCodes } from "@deliverr/non-compliance-client";
import { toast } from "@deliverr/ui";
import { logError } from "utils/logger";

export function handleNonComplianceError(ctx: any, error: any, resetFn?: () => void) {
  const getNonComplianceErrorMessage = (error: NonComplianceError) => {
    // This will check for validation errors from the tsoa controller and map each failed key to a string that
    // shows it's value and the error message
    if (error.code === SharedErrorCodes.INVALID_INPUT) {
      // map over the object.values of error.payload and return a string like `value: message`
      // error.payload has a message and a value key
      return `${(Object.values(error.payload) as { message: string; value: string }[])
        .map((value) => `${value.value}: ${value.message}`)
        .join(", ")}
        requestId: ${
          // @ts-ignore
          error.requestId
        }`;
    }

    if (NonComplianceErrorCodes[error.code as NonComplianceErrorCodes]) {
      return error.message;
    }

    return "";
  };

  logError(ctx, error);
  if (error.isAxiosError) {
    const errorCode = error?.response?.data?.code as NonComplianceErrorCodes | SharedErrorCodes;
    if (
      NonComplianceErrorCodes[errorCode as NonComplianceErrorCodes] ||
      SharedErrorCodes[errorCode as SharedErrorCodes]
    ) {
      return toast.error(getNonComplianceErrorMessage(error?.response?.data));
    }
  }

  toast.error(error?.payload?.message ?? error?.message);
}
