import { Input, Button } from "@deliverr/ui";
import { useAddFacilityZones } from "./useAddFacilityZones";
import { StyledForm } from "components/inbounds/AsnAssignmentTool";

export const AddFacilityZones = () => {
  const { facilityId, zoneName, onSubmit, isSubmitting, zoneNameMaxLength } = useAddFacilityZones();
  return (
    <StyledForm onSubmit={onSubmit}>
      <Input
        {...facilityId.field}
        label="Facility ID"
        placeholder="TST_LOG"
        required
        hasError={!!facilityId.fieldState.error}
        errorMessage={facilityId.fieldState.error?.message}
      />
      <Input
        {...zoneName.field}
        label="Zone"
        placeholder="A01A"
        required
        hasError={!!zoneName.fieldState.error}
        errorMessage={zoneName.fieldState.error?.message}
        maxLength={zoneNameMaxLength}
      />
      <Button type="submit" loading={isSubmitting}>
        Add Zone
      </Button>
    </StyledForm>
  );
};
