import styled from "@emotion/styled";
import { Stack, Title } from "@deliverr/ui";

const EmptyListMessageCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 300px;
`;

const EmptyMessage = () => {
  return (
    <EmptyListMessageCard>
      <Stack gap={null} center>
        <Title displayAs="h3">{"Seller Not Found"}</Title>
        <p>Please try a different filter.</p>
      </Stack>
    </EmptyListMessageCard>
  );
};

export default EmptyMessage;
