import { datadogLogs } from "@datadog/browser-logs";
import {
  compositeStream,
  conditionalStream,
  consoleStream,
  datadogLoggerStream,
  LogContext,
  logger,
  minimumLevelStream,
  setGlobalContext,
  setStream,
} from "@deliverr/ui-logging";
import { getIsValidDataDogEnv } from "./third-party/DataDog/isValidDataDogEnv";

export const setLogUser = (user: { email?: string }) => {
  setGlobalContext({
    user,
  });
};

setStream(
  compositeStream(
    conditionalStream(() => typeof window !== "undefined" && window.location.host.includes("localhost"))(
      minimumLevelStream("info")(consoleStream)
    ),
    conditionalStream(getIsValidDataDogEnv)(compositeStream(datadogLoggerStream(datadogLogs.logger)))
  )
);

/**
 * Alternatives for the default logging methods
 */

export const logStart = (ctx: LogContext): LogContext => {
  const msg = `starting ${ctx.fn}`;
  logger.info(ctx, msg);
  return ctx;
};

export const logError = (ctx: LogContext, err: Error) => {
  logger.error({ ...ctx, err }, `error during ${ctx.fn}`);
};

export const log = (ctx: LogContext, msg: string, payload?: Record<string, unknown>) => {
  logger.info({ ...ctx, ...payload }, msg);
};

export default logger;
