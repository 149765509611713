import { Route } from "react-router-dom";
import { Path } from "paths";
import { NavBar } from "components/NavBar";
import { Container } from "components/Container";
import { SortCodeManager } from "./SortCodeManager";
import { SortCodeSelector } from "./SortCodeManager/SortCodeSelector";
import { SortCodeEditor } from "./SortCodeManager/SortCodeEditor";
import { DockDoorLabels } from "./DockDoorLabels";

export const sortationHeaderRoutes = [
  <Route
    key="sortationListHeader"
    path={Path.sortationList}
    element={<NavBar breadcrumbs={[{ pageName: "Sortation" }]} />}
  />,
  <Route
    key="sortCodeManagerHeader"
    path={Path.sortCodeManager}
    element={<NavBar breadcrumbs={[{ pageName: "Sortation" }, { pageName: "Sort Code Manager" }]} />}
  />,
  <Route
    key="sortCodeSelectorHeader"
    path={Path.sortCodeSelector}
    element={
      <NavBar
        breadcrumbs={[{ pageName: "Sortation" }, { pageName: "Sort Code Manager" }, { pageName: "Select Sort Code" }]}
      />
    }
  />,
  <Route
    key="sortCodeEditorHeader"
    path={Path.sortCodeEditor}
    element={
      <NavBar
        breadcrumbs={[
          { pageName: "Sortation" },
          { pageName: "Sort Code Manager" },
          { pageName: "Select Sort Code" },
          { pageName: "Edit Sort Code" },
        ]}
      />
    }
  />,
  <Route
    key="dockDoorLabelsHeader"
    path={Path.dockDoorLabels}
    element={<NavBar breadcrumbs={[{ pageName: "Sortation" }, { pageName: "Dock Door Labels" }]} />}
  />,
];

export const sortationRoutes = [
  <Route
    key="sortationList"
    path={Path.sortationList}
    element={<Container>Select a tool from the dropdown on the left hand side</Container>}
  />,
  <Route key="sortCodeManager" path={Path.sortCodeManager} element={<SortCodeManager />} />,
  <Route key="sortCodeSelector" path={Path.sortCodeSelector} element={<SortCodeSelector />} />,
  <Route key="sortCodeEditor" path={Path.sortCodeEditor} element={<SortCodeEditor />} />,
  <Route key="dockDoorLabels" path={Path.dockDoorLabels} element={<DockDoorLabels />} />,
];
