import { Button, Card, Input, Text, BoxReset } from "@deliverr/ui";
import { Container } from "components/Container";
import { useEventAsnSplitTool } from "./useEventAsnSplitTool";
import { AttributionResults } from "../AttributionResults";
import { StyledForm } from "../AsnAssignmentTool";
import { Controller } from "react-hook-form";

export const EventAsnSplitTool = () => {
  const {
    eventId,
    asnAssignmentFields,
    onSubmitPreview,
    previewResults,
    resetAll,
    applyAsnAssignments,
    control,
    append,
    remove,
    isSubmitting,
  } = useEventAsnSplitTool();

  return (
    <Container>
      <Card>
        <StyledForm onSubmit={onSubmitPreview}>
          <Text size="bodyLarge" bold>
            Event Asn Split Tool
          </Text>
          <Text size="body">This tool is used to split units from a single receiving event onto multiple ASNs.</Text>

          <Input
            {...eventId.field}
            helpText="The id of the event that you'd like to preview/assign"
            placeholder="002a36a8-be27-584a-a834-3e7d3a7123e9"
            label="Event Id"
            required
            hasError={!!eventId.fieldState.error}
            errorMessage={eventId.fieldState.error?.message}
          />
          <Text size="label" bold>
            Asn Assignments
          </Text>
          {asnAssignmentFields.map((x, index) => (
            <div key={`${x.asnLabel}-${index}`}>
              <Text>ASN {index + 1}</Text>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Controller
                  control={control}
                  name={`asnAssignments.${index}.asnLabel` as const}
                  render={({ field }) => (
                    <Input
                      {...field}
                      helpText="The ASN label that you'd like to assign units to"
                      placeholder="123456789"
                      label={`ASN Label`}
                      required
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`asnAssignments.${index}.qty` as const}
                  render={({ field }) => (
                    <Input
                      {...field}
                      helpText="The number of units you'd like to assign to this ASN"
                      placeholder="10"
                      label={`Quantity`}
                      required
                      type="number"
                      min={0}
                    />
                  )}
                />
                <Button onClick={() => remove(index)} loading={isSubmitting}>
                  Remove
                </Button>
              </div>
            </div>
          ))}

          {!previewResults ? (
            <>
              <Button onClick={() => append({ asnLabel: "", qty: 0 })} loading={isSubmitting}>
                Add another ASN
              </Button>
              <Button type="submit" loading={isSubmitting}>
                Preview
              </Button>
            </>
          ) : null}
        </StyledForm>
      </Card>
      {previewResults ? (
        <BoxReset marginTop="S4">
          <AttributionResults
            attributionResults={previewResults}
            onCancel={resetAll}
            onApprove={applyAsnAssignments}
            isSubmitting={isSubmitting}
          />
        </BoxReset>
      ) : null}
    </Container>
  );
};
