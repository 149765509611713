import { Input, Button, Box } from "@deliverr/ui";
import { StyledForm } from "components/inbounds/AsnAssignmentTool";
import { StyledTable, StyledTableContainer } from "../FacilityZonesFormAndTable/FacilityZonesFormAndTable";
import { useViewMerchantZoneAssignments } from "./useViewMerchantZoneAssignments";

export const ViewMerchantZoneAssignments = () => {
  const {
    facilityIdInputController,
    merchantIdInputController,
    zoneAsssignmentsData,
    isSubmitting,
    onSubmit,
    unassignMerchantFromZone,
  } = useViewMerchantZoneAssignments();

  const viewMerchantZoneAssignmentsTableHeadings = ["Facility", "Zone", "Merchant", "Action"];
  const rowValues: [string, string, string, JSX.Element][] = zoneAsssignmentsData.map((zoneAssignment) => [
    zoneAssignment.facilityId,
    zoneAssignment.zoneName,
    zoneAssignment.sellerId,
    <Button
      onClick={() => {
        unassignMerchantFromZone(zoneAssignment.id, zoneAssignment.zoneName, zoneAssignment.sellerId);
      }}
      loading={isSubmitting}
    >
      Unassign
    </Button>,
  ]);
  return (
    <>
      <StyledForm onSubmit={onSubmit}>
        <Input
          {...facilityIdInputController.field}
          label="Facility ID"
          placeholder="TST_LOG"
          hasError={!!facilityIdInputController.fieldState.error}
          errorMessage={facilityIdInputController.fieldState.error?.message}
        />
        <Input
          {...merchantIdInputController.field}
          label="Merchant ID"
          placeholder="gweentoysinc"
          hasError={!!merchantIdInputController.fieldState.error}
          errorMessage={merchantIdInputController.fieldState.error?.message}
        />
        <Button type="submit" loading={isSubmitting}>
          View Merchant Zone Assignments
        </Button>
      </StyledForm>
      {rowValues.length > 0 && (
        <>
          <Box marginTop="S4">
            <StyledTableContainer>
              <StyledTable
                headings={viewMerchantZoneAssignmentsTableHeadings}
                rows={rowValues}
                caption={"Merchant Zone Assignments"}
              ></StyledTable>
            </StyledTableContainer>
          </Box>
        </>
      )}
    </>
  );
};
