import { GetNetworkResponse, NetworkLane } from "@deliverr/carrier-network-types";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useState, useCallback } from "react";
import { useAsync } from "react-use";

export const useNetworkLanes = () => {
  // Entire network response from the carrier network API
  const [network, setNetwork] = useState<GetNetworkResponse>({ lanes: [], nodes: [] });

  // Network data for the table. This exists to filter the network data based on selected node or edge
  const [laneTableData, setLaneTableData] = useState<NetworkLane[]>([]);

  const { carrierNetworkClient } = useClientsWithAuth();

  /**
   * Callback function that filters the table data based on the selected node or edge in the graph
   * @param originNode - The origin node ID
   * @param destinationNode - The destination node ID
   */
  const handleNodeOrEdgeSelection = useCallback(
    (originNode: string, destinationNode?: string) => {
      if (originNode) {
        const filteredLanes: NetworkLane[] = network.lanes.filter((lane) => {
          // If the destination node is not provided, filter by origin for both origin and destination
          if (!destinationNode) {
            return lane.originNodeId === originNode || lane.destinationNodeId === originNode;
          }

          // Filter by both origin and destination node if both are provided
          return lane.originNodeId === originNode && lane.destinationNodeId === destinationNode;
        });

        setLaneTableData(filteredLanes);
      } else {
        setLaneTableData(network.lanes);
      }
    },
    [network]
  );

  /**
   * Callback function that resets the table data to the original network lanes
   * This is used when the user deselects a node or edge in the graph
   */
  const resetNodeOrEdgeSelection = useCallback(() => {
    setLaneTableData(network.lanes);
  }, [network]);

  /**
   * Fetch the network data from the carrier network API layer
   * If the network data is successfully fetched, set the network state and the table lane data
   */
  const { loading } = useAsync(async () => {
    try {
      const networkResponse = await carrierNetworkClient.getNetwork();

      // If there are no lanes, show an error toast
      if (networkResponse.lanes.length === 0) {
        toast.error(`No network lanes found.`, {
          position: "top-right",
        });
      }
      setNetwork(networkResponse);
      setLaneTableData(networkResponse.lanes);
    } catch (err) {
      toast.error(`Unable to retrieve network lanes. Please contact engineering.`, {
        position: "top-right",
      });
    }
  }, []);

  return {
    network,
    laneTableData,
    loading,
    handleNodeOrEdgeSelection,
    resetNodeOrEdgeSelection,
  };
};
