import { DefaultTheme, ToastContainer } from "@deliverr/ui";
import styled from "@emotion/styled";
import React, { FC, ReactNode } from "react";
import { Routes } from "react-router-dom";
import { SidebarState, sidebarTheme, useSidebarContext } from "../Sidebar";
import { Path } from "../../paths";
import { Page } from "../Page";
import { Sidebar } from "../Sidebar";
import { SupportPortalModalRenderer } from "components/Modal/SupportPortalModalRenderer";

const Layout = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    background-color: ${theme.colors.NEUTRAL[40]};
    min-height: 100vh;
  `
);

const MainContainer = styled.div<{}, DefaultTheme>(
  () => `
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    height: 100vh;
  `
);

const Header = styled.div<{ state: SidebarState }, DefaultTheme>(
  ({ state }) => `
    z-index: 3;
    margin-left: ${state === "PINNED_OPEN" ? sidebarTheme.menu.width : "0"};
    transition: margin-left ${sidebarTheme.transitionDuration};
  `
);
export interface AppLayoutProps {
  headerRoutes?: ReactNode[];
  pageRoutes: ReactNode[];
  sidebarContent: ReactNode;
  sidebarFooter?: ReactNode;
}

export const AppLayout: FC<AppLayoutProps> = ({ headerRoutes, pageRoutes, sidebarContent, sidebarFooter }) => {
  const { state } = useSidebarContext();

  return (
    <Layout>
      <Sidebar rootPath={Path.root} content={sidebarContent} footer={sidebarFooter} />
      <MainContainer id="mainContainer">
        <Header state={state}>
          <Routes>{headerRoutes}</Routes>
        </Header>
        <Page>
          <Routes>{pageRoutes}</Routes>
        </Page>
        <SupportPortalModalRenderer />
      </MainContainer>
      <ToastContainer position="bottom-right" />
    </Layout>
  );
};
