import { useEffect, useState } from "react";
import { useAsync } from "react-use";
import { useNavigate } from "react-router-dom";
import { SortationCenter } from "@deliverr/commons-clients";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useRecoilState } from "recoil";
import { sortCodeMappingState } from "../sortationState";

export const useSortCodeManager = () => {
  // Local State
  const [sortationCenters, setSortationCenters] = useState<SortationCenter[]>([]);
  const [selectedSortationCenter, setSelectedSortationCenter] = useState<string>();
  const [shippingMethodOptions, setShippingMethodOptions] = useState<{ label: string; value: string }[]>([]);
  const [isNewShippingMethod, setIsNewShippingMethod] = useState(false);
  const [shippingMethodInputValue, setShippingMethodInputValue] = useState("");
  const [selectedShippingMethodValue, setSelectedShippingMethodValue] = useState<string>("");

  // Global State
  const [sortCodeMapping, setSortCodeMapping] = useRecoilState(sortCodeMappingState);

  const { sortationApiClient } = useClientsWithAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedSortationCenter) {
      const shippingMethods = Object.keys(sortCodeMapping[selectedSortationCenter] ?? {});
      setShippingMethodOptions(
        shippingMethods.map((shippingMethod) => ({
          label: shippingMethod,
          value: shippingMethod,
        }))
      );
    }
  }, [selectedSortationCenter, sortCodeMapping]);

  const state = useAsync(async () => {
    // Retrieve sortation centers
    try {
      const sortationCenters = await sortationApiClient.getSortationCenters();
      setSortationCenters(sortationCenters);
    } catch (err) {
      toast.error("Unable to retrieve sortation center's", { position: "top-right" });
      return;
    }

    // Retrieve sort code mapping
    try {
      const sortCodeMapping = await sortationApiClient.getSortCodeMapping();
      setSortCodeMapping(sortCodeMapping);
    } catch (err) {
      toast.error("Unable to retrieve sort code mapping", { position: "top-right" });
      return;
    }
  });

  const handleSortationCenterSelection = (e: any) => {
    if (e.value !== selectedSortationCenter) {
      setShippingMethodOptions([]);
    }
    setSelectedSortationCenter(e.value);
  };

  const handleShippingMethodSelection = (e: any) => {
    if (isNewShippingMethod) {
      const upperCaseValue = e.target.value.toUpperCase();
      setShippingMethodInputValue(upperCaseValue);
    } else {
      setSelectedShippingMethodValue(e.value);
    }
  };

  const handleFormContinue = () => {
    navigate(
      `/sortation/sort-code-manager/${selectedSortationCenter}/${
        isNewShippingMethod ? shippingMethodInputValue : selectedShippingMethodValue
      }`
    );
  };

  return {
    // State
    state,
    selectedSortationCenter,
    isNewShippingMethod,
    setIsNewShippingMethod,
    shippingMethodInputValue,
    canContinue:
      selectedSortationCenter && (isNewShippingMethod ? shippingMethodInputValue : selectedShippingMethodValue),

    // Form Options
    sortationCenterOptions: sortationCenters.map((sortationCenter) => ({
      label: sortationCenter.id,
      value: sortationCenter.id,
    })),
    shippingMethodOptions,

    // Event Handlers
    handleSortationCenterSelection,
    handleShippingMethodSelection,
    handleFormContinue,
  };
};
