import {
  IssueEntityGetResponse,
  IssueGetResponse,
  IssueImageGetResponse,
  IssueZendeskTicketGetResponse,
} from "@deliverr/non-compliance-client";
import { Accordion, Grid, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { DateFormat, DisplayDate } from "components/nonCompliance/components/DisplayDate";
import { Field } from "components/nonCompliance/components/Field";
import { CommonIssueDetails } from "components/nonCompliance/components/CommonIssueDetails";
import { useState } from "react";

const SubHeading = styled.h3<ThemeProps>`
  font-size: ${({ theme }) => theme.font.size.F2};
  margin-top: ${({ theme }) => theme.spacing.S4};
  margin-bottom: ${({ theme }) => theme.spacing.S2};
`;

export enum FIELD_TYPE {
  UNIT_DISPOSITION = "Unit disposition",
  BARCODE = "Barcode",
  DSKU = "Dsku",
  EXPECTED_QUANTITY = "Expected quantity",
  IMMEDIATE_DISPOSAL = "Requires immediate disposal",
  SUPPORT_NOTES = "Support notes",
  REASON_CODE = "Reason code",
  EXPIRATION_DATE = "Expiration Date",
}

export interface ReimbursementDataProps {
  reimbursedAt: string | null;
  reimbursementUnitCost: number;
}

export interface DisputeDataProps {
  inDispute: boolean;
  disputeOpenedAt: string | null;
  disputeClosedAt: string | null;
}

export interface IssueSpecificDetailsProps {
  fields: { type: FIELD_TYPE | string; value: any | null }[];
  reimbursementData?: ReimbursementDataProps | null;
  disputeData?: DisputeDataProps | null;
  images: Array<IssueImageGetResponse>;
  issueEntities: Array<IssueEntityGetResponse>;
  zendeskTickets: Array<IssueZendeskTicketGetResponse>;
  issue: IssueGetResponse;
}

export const IssueSpecificDetails: React.FC<IssueSpecificDetailsProps> = ({
  fields,
  reimbursementData,
  disputeData,
  issueEntities,
  images,
  zendeskTickets,
  issue,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const toggleOpen = () => setIsOpen((state) => !state);

  return (
    <Grid columns="1fr 1fr" gap="S2">
      <CommonIssueDetails issue={issue} issueEntities={issueEntities} images={images} zendeskTickets={zendeskTickets} />
      <Accordion
        indicator
        divider
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        headerContent={<h2>Issue specific details</h2>}
      >
        <Grid columns="1fr 1fr" gap="S2">
          {fields.map((field) => (
            <Field key={field.type} label={field.type} value={field.value} />
          ))}
        </Grid>
        {reimbursementData && <ReimbursementData reimbursementData={reimbursementData} />}
        {disputeData && <DisputeData disputeData={disputeData} />}
      </Accordion>
    </Grid>
  );
};

const DisputeData = ({ disputeData }: { disputeData: DisputeDataProps }) => {
  return (
    <>
      <SubHeading>Dispute data:</SubHeading>
      <Grid columns="1fr 1fr" gap="S2">
        <Field label="In dispute" value={disputeData.inDispute ? "Yes" : "No"} />
        <Field
          label="Dispute opened at"
          value={
            <DisplayDate
              date={disputeData.disputeOpenedAt ? new Date(disputeData.disputeOpenedAt) : null}
              format={DateFormat.DateAtTime}
            />
          }
        />
        <Field
          label="Dispute closed at"
          value={
            <DisplayDate
              date={disputeData.disputeClosedAt ? new Date(disputeData.disputeClosedAt) : null}
              format={DateFormat.DateAtTime}
            />
          }
        />
      </Grid>
    </>
  );
};

const ReimbursementData = ({ reimbursementData }: { reimbursementData: ReimbursementDataProps }) => {
  return (
    <>
      <SubHeading>Reimbursement data:</SubHeading>
      <Grid columns="1fr 1fr" gap="S2">
        <Field
          label="Lastest reimburesement at"
          value={
            <DisplayDate
              date={reimbursementData.reimbursedAt ? new Date(reimbursementData.reimbursedAt) : null}
              format={DateFormat.DateAtTime}
            />
          }
        />
        <Field
          label="Reimbursement unit cost"
          value={reimbursementData.reimbursementUnitCost.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        />
      </Grid>
    </>
  );
};
