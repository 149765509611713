import { CreateOrUpdateNetworkLaneRequest, NetworkLane } from "@deliverr/carrier-network-types";
import { toast } from "@deliverr/ui";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useClientsWithAuth } from "../../../../hooks/auth/useClientsWithAuth";
import { useFetchShippingMethods } from "../../hooks/useFetchShippingMethods";
import { replaceNullWithUndefined } from "../../utils/replaceNullWithUndefined";
import { UpdateLaneFormValues } from "./UpdateLaneFormValues";
import { useInitializeFormControllers } from "./useInitializeFormControllers";

export const useNetworkLaneEditor = (lane: NetworkLane, onFinish: () => void) => {
  // Component State
  const [isSubmitting, setIsSubmitting] = useState(false);

  // React Hook Form
  const { control, handleSubmit, setValue } = useForm<UpdateLaneFormValues>({});

  // Local hooks
  const { carrierNetworkClient } = useClientsWithAuth();
  const { shippingMethods, loading } = useFetchShippingMethods();
  const controllers = useInitializeFormControllers(control, lane, shippingMethods);

  // Update shipping methods form values once the shipping methods have loaded
  useEffect(() => {
    if (shippingMethods.length > 0) {
      // Shipping method selector values
      const shippingMethodsFormValue: { label: string; value: string }[] = [];

      // Iterate over all shipping methods and add them to the default shipping methods
      lane.shippingMethods?.forEach((stringShippingMethod) => {
        const shippingMethod = shippingMethods.find((shippingMethod) => shippingMethod.name === stringShippingMethod);
        if (shippingMethod) {
          shippingMethodsFormValue.push({
            label: shippingMethod.name,
            value: shippingMethod.name,
          });
        }
      });

      setValue("shippingMethods", shippingMethodsFormValue);
    }
  }, [shippingMethods, lane.shippingMethods, setValue]);

  const onSubmit: SubmitHandler<UpdateLaneFormValues> = async (data) => {
    setIsSubmitting(true);

    // Format form data
    const description = data.description;
    const isActive = data.isActive.value;
    const flattenedShippingMethods = data.shippingMethods
      .map((method) => method.value)
      .sort((a, b) => a.localeCompare(b));
    const localOriginCutoffTime = data.localOriginCutoffTime;
    const localOriginNodeDepartureTime =
      data.localOriginNodeDepartureTime?.length > 0 ? data.localOriginNodeDepartureTime : undefined;
    const transitHours = data.transitHours?.length > 0 ? parseInt(data.transitHours) : undefined;
    const activeBusinessDays = data.activeBusinessDays.map((day) => day.value);

    const { id, createdAt, updatedAt, ...rest } = lane;

    // Required because the API returns null for optional undefined fields but won't accept anything other than
    // `undefined` on the PUT request 🫠
    const sanitizedLane = replaceNullWithUndefined(rest);

    const updateLaneRequest: CreateOrUpdateNetworkLaneRequest = {
      ...sanitizedLane,
      description,
      isActive,
      shippingMethods: flattenedShippingMethods,
      localOriginCutoffTime,
      localOriginNodeDepartureTime,
      transitHours,
      activeBusinessDays,
    };

    try {
      await carrierNetworkClient.updateLane(lane.id.toString(), updateLaneRequest);
      toast.success("Successfully updated lane", { position: "top-right" });
      onFinish();
    } catch (err) {
      toast.error("Failed to update lane", { position: "top-right" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    loading,
    onSubmit: handleSubmit(onSubmit),
    controllers,
    isSubmitting,
    shippingMethods,
  };
};
