import { IssueGetResponse, NonComplianceClient } from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";

export async function getUnexpectedSkuDetails({
  issue,
  client,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
}> {
  const unexpectedSkuIssue = await client.getIssueUnexpectedSkuById({ issueId: issue.id });

  const issueDetails = {
    issue: unexpectedSkuIssue.issue,
    images: unexpectedSkuIssue.images,
    issueEntities: unexpectedSkuIssue.issueEntities,
    zendeskTickets: unexpectedSkuIssue.zendeskTickets,
    fields: [{ type: FIELD_TYPE.DSKU, value: unexpectedSkuIssue.dsku }],
    reimbursementData: null,
    disputeData: null,
  };

  return {
    issueDetails,
  };
}
