import React, { ComponentType, FC, useEffect, useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { CenterSpinner } from "../../components/LoadingSpinner";
import intersection from "lodash.intersection";
import { ROLES } from "../../types/Auth";
import { Logout } from "components/Logout/Logout";

export const ProtectedRoute = ({ component }: React.PropsWithChildren<any>) => {
  const Component = withAuthenticationRequired(withRoleBasedRedirect(component), {
    onRedirecting: () => <CenterSpinner loading />,
  });

  return <Component />;
};

export const DELIVERR_URL = "https://deliverr.com/";

export const getClaimsFromLocalStorage = () =>
  JSON.parse(
    window.localStorage.getItem(
      `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_AUDIENCE}::${process.env.REACT_APP_AUTH0_SCOPE}`
    ) ?? ""
  );

export const withRoleBasedRedirect =
  <P extends object>(Component: ComponentType<P>): FC<P> =>
  (props: P): JSX.Element => {
    const { getIdTokenClaims } = useAuth0();
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

    const checkRole = async (): Promise<void> => {
      let claims;
      // get auth0 user info from local storage for e2e tests
      // @ts-ignore
      claims = window.Cypress
        ? getClaimsFromLocalStorage().body.decodedToken.user[DELIVERR_URL]
        : (await getIdTokenClaims())?.[DELIVERR_URL];
      const userApps = [...(claims.apps ?? []), ...claims.roles];
      const acceptedApps = [ROLES.SUPPORT_ROLE];
      const canAccessApp = intersection(userApps, acceptedApps).length > 0;

      // if user is authenticated and has access to the app pass them through
      // else if user is authenticated but does not have access to the app, reroute them to appropriate app
      if (canAccessApp) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    };

    useEffect(() => {
      checkRole();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getIdTokenClaims]);

    return isAuthorized ? <Component {...props} /> : <Logout />;
  };
