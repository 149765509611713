import { useNavigate } from "react-router-dom";
import { SortationCenterError } from "@deliverr/commons-clients";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { useAsync } from "react-use";
import Papa from "papaparse";
import { isEqual, uniq } from "lodash/fp";
import { downloadCsv } from "./sortCodeEditorUtils";

const zipRegex = /^\d{5}$/;

export const useSortCodeEditor = () => {
  // Local State
  const [isNewSortCode, setIsNewSortCode] = useState(false);
  const [previousZipCodes, setPreviousZipCodes] = useState<string[]>([]);
  const [zipCodes, setZipCodes] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [reason, setReason] = useState<string>("");

  // Client Hooks
  const { sortationApiClient } = useClientsWithAuth();

  // Router Hooks
  const navigate = useNavigate();
  const { sortationCenterId, shippingMethod, sortCode } = useParams() as {
    sortationCenterId: string;
    shippingMethod: string;
    sortCode: string;
  };

  // On component load, retrieve zip codes
  const state = useAsync(async () => {
    try {
      const zipMap = await sortationApiClient.getSortCodeZipMapping(sortCode, sortationCenterId, shippingMethod);

      const zips = zipMap?.[sortationCenterId]?.[shippingMethod]?.[sortCode];
      if (zips) {
        setPreviousZipCodes(zips);
        setZipCodes(zips);
      } else {
        setIsNewSortCode(true);
      }
    } catch (err) {
      toast.error(`Unable to retrieve zip codes for ${sortationCenterId}, ${shippingMethod}, ${sortCode}`, {
        position: "top-right",
      });
    }
  });

  const handleSetZipCodes = useCallback(
    (val: string) => {
      // Normalize input to remove non-numeric characters
      const newVal = val.replace(/[^\d,]+/g, "");
      const zipArray = newVal.length ? newVal.split(",") : [];
      setZipCodes(zipArray);

      // Check against regex, 0, and empty string (empty string is allowed since we will trim it on submission)
      const invalidZipCodes = zipArray.filter((zip) => !zipRegex.test(zip) && zip !== "0");

      // Set error message if there are invalid zip codes
      if (invalidZipCodes.length) {
        setErrorMessage(`Invalid zip code list. Please check to ensure that all zip codes are valid.`);
      } else if (uniq(zipArray).length !== zipArray.length) {
        setErrorMessage(`Duplicate zip codes found. Please check to ensure that all zip codes are unique.`);
      } else {
        setErrorMessage("");
      }
    },
    [setZipCodes, setErrorMessage]
  );

  // Download zip codes as a single column CSV file
  const handleDownload = useCallback(() => {
    try {
      downloadCsv(sortationCenterId, shippingMethod, sortCode, zipCodes);
    } catch (err) {
      toast.error(`Unable to download zip codes for ${sortationCenterId}, ${shippingMethod}, ${sortCode}`, {
        position: "top-right",
      });
    }
  }, [sortationCenterId, shippingMethod, sortCode, zipCodes]);

  const handleUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) {
        toast.error("Unable to upload file", {
          position: "top-right",
        });
        return;
      }

      Papa.parse(file, {
        complete: (result: Papa.ParseResult<any>) => {
          console.log("Parsed Result:", result);
          const extractedZipCodes = result.data.map((row: string[]) => row[0]);
          handleSetZipCodes(extractedZipCodes.join(","));
        },
        header: false,
        skipEmptyLines: true,
      });
    },
    [handleSetZipCodes]
  );

  const handleSave = useCallback(async () => {
    try {
      if (isEqual(previousZipCodes.sort(), zipCodes.sort())) {
        toast.warning(`No changes were detected in zip codes.`, {
          position: "top-right",
        });
        return;
      }

      await sortationApiClient.createOrUpdateSortCodeMapping(
        sortationCenterId,
        shippingMethod,
        sortCode,
        zipCodes,
        reason
      );

      setIsNewSortCode(false);
      setPreviousZipCodes(zipCodes);

      toast.success(`Zip codes for ${sortationCenterId}, ${shippingMethod}, ${sortCode} have been saved`, {
        position: "top-right",
      });
    } catch (err: any) {
      if (err && err.message === SortationCenterError.SORTATION_CENTER_NOT_FOUND) {
        toast.error(`Failed to save mapping: Sortation center ${sortationCenterId} not found`, {
          position: "top-right",
        });
      } else {
        toast.error(`Failed to save sort code mapping please contact the Carrier Network team`, {
          position: "top-right",
        });
      }
    }
  }, [sortationCenterId, shippingMethod, sortCode, previousZipCodes, zipCodes, reason, sortationApiClient]);

  const handleDelete = useCallback(async () => {
    try {
      await sortationApiClient.deleteSortCodeMapping(sortationCenterId, shippingMethod, sortCode, reason);
      navigate(`/sortation/sort-code-manager/${sortationCenterId}/${shippingMethod}`);
    } catch (err: any) {
      if (err && err.message === SortationCenterError.SORTATION_CENTER_NOT_FOUND) {
        toast.error(`Failed to delete sort code: Sortation center ${sortationCenterId} not found`, {
          position: "top-right",
        });
      } else {
        toast.error(`Failed to delete sort code please contact the Carrier Network team`, {
          position: "top-right",
        });
      }
    }
  }, [sortationApiClient, sortationCenterId, shippingMethod, sortCode, reason, navigate]);

  // Back to the previous page
  const handleBack = useCallback(() => {
    navigate(`/sortation/sort-code-manager/${sortationCenterId}/${shippingMethod}`);
  }, [sortationCenterId, shippingMethod, navigate]);

  return {
    state,
    isNewSortCode,
    zipCodes,
    errorMessage,
    reason,
    sortationCenterId,
    shippingMethod,
    sortCode,
    setReason,
    handleSetZipCodes,
    handleDownload,
    handleUpload,
    handleSave,
    handleDelete,
    handleBack,
  };
};
