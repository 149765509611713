import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useState } from "react";
import { useForm, useController, SubmitHandler } from "react-hook-form";
import { logError } from "utils/logger";

type FormValues = {
  shipmentId: number;
  completionReason: string;
};

export const useShipmentCompletionTool = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({});
  const { inboundClient } = useClientsWithAuth();

  const shipmentId = useController({
    name: "shipmentId",
    defaultValue: 0,
    control,
    rules: {
      required: true,
    },
  });

  const completionReason = useController({
    name: "completionReason",
    control,
    defaultValue: "",
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsSubmitting(true);
    try {
      const results = await inboundClient.markShipmentReceivingCompleted({
        shipmentId: Number(data.shipmentId),
        completionReason: data.completionReason,
      });
      setIsSubmitting(false);
      if (results.error) {
        if (results.error.message) {
          toast.error(results.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }
      reset();
      toast.success("Shipment completed", { position: "top-right" });
      return;
    } catch (error) {
      setIsSubmitting(false);
      logError({ fn: "useShipmentCompletionTool.onSubmit" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  };

  return {
    shipmentId,
    completionReason,
    onSubmit: handleSubmit(onSubmit),
    isSubmitting,
  };
};
