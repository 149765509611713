import { SidebarMode } from "./SidebarContext";

export interface SidebarTheme {
  /** Duration of animations such as sliding in or out and transitioning opacity and visibility. */
  transitionDuration: string;

  /** Standard top/bottom margin between sections of sidebar items. */
  sectionMargin: string;

  /** Properties of modal overlay that is visible when sidebar is open in mobile mode. */
  modalOverlay: {
    opacity: string;
  };

  /** Properties of sidebar menu. */
  menu: {
    width: string;
    padding: string;
    background: string;
    hoverOpen: {
      margin: string;
      borderRadius: string;
    };
  };

  /** Properties of target in upper left corner of app for opening hover or mobile menu. */
  openTarget: {
    height: string;
    width: string;
  };

  /** Properties of open and hide buttons. */
  toggleButton: {
    height: string;
    width: string;
  };

  /** Properties of Deliverr logo. */
  logo: {
    height: string;
  };

  /** Properties of individual sidebar items and buttons. */
  item: {
    borderRadius: string;
    hoverBackgroundColor: string;
    iconSize: string;
    height: Record<SidebarMode, string>;
    margin: Record<SidebarMode, string>;
  };
}

export const sidebarTheme: SidebarTheme = {
  transitionDuration: "0.25s",
  sectionMargin: "0.5rem",
  modalOverlay: {
    opacity: "0.4",
  },
  menu: {
    width: "15rem",
    padding: "0 1rem 1rem 1rem",
    background: "linear-gradient(3.86deg, #041627 0%, #0d223d 99.67%)",
    hoverOpen: {
      margin: "1.25rem 0",
      borderRadius: "0 10px 10px 0",
    },
  },
  openTarget: {
    height: "4.5rem",
    width: "4.5rem",
  },
  toggleButton: {
    height: "2.5rem",
    width: "2.5rem",
  },
  logo: {
    height: "1.25rem",
  },
  item: {
    borderRadius: "6px",
    hoverBackgroundColor: "rgba(255, 255, 255, 0.1)",
    iconSize: "1.25rem",
    height: {
      MOBILE: "2.5rem",
      DESKTOP: "2rem",
    },
    margin: {
      MOBILE: "0.5rem 0",
      DESKTOP: "0.125rem 0",
    },
  },
};
