import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { isPlainObject } from "lodash";

export const addOpenSearchFilters = (openSearchFilters: QueryDslQueryContainer[]) => {
  const combinedQuery: QueryDslQueryContainer = mergeOpenSearchFilters([...openSearchFilters]);

  return combinedQuery;
};

/**
 * Merges multiple OpenSearch filters into a single object that's a valid OpenSearch query.
 *
 * @param filters List of filters to merge
 * @returns Combined OpenSearch query
 */
export const mergeOpenSearchFilters = (filters: QueryDslQueryContainer[]): QueryDslQueryContainer => {
  const queryDslBoolQueryKeys = ["must", "should", "must_not", "filter", "minimum_should_match"];
  return filters.reduce((acc: any, filter) => {
    Object.entries(filter).forEach(([key, value]) => {
      if (!(key in acc)) {
        // Initialize the key with an empty array or object if it hasn't been seen yet
        acc[key] = Array.isArray(value) || queryDslBoolQueryKeys.includes(key) ? [] : {};
      }

      if (Array.isArray(value)) {
        // Concatenate arrays
        acc[key] = acc[key].concat(value);
      } else if (isPlainObject(value)) {
        if (queryDslBoolQueryKeys.includes(key)) {
          // We don't want to merge bool queries, so we just concatenate them
          // Otherwise, we get an incorrectly shaped query with duplicated
          // properties inside an object.
          acc[key] = (acc[key] || []).concat(mergeOpenSearchFilters([value]));
        } else {
          // Otherwise, we recursively merge objects
          acc[key] = mergeOpenSearchFilters([acc[key], value]);
        }
      } else {
        acc[key] = value;
      }
    });
    return acc;
  }, {} as QueryDslQueryContainer);
};
