import { toast } from "@deliverr/ui";
import {
  DamagedProductUnitDisposition,
  IssueGetResponse,
  IssueStatus,
  NonComplianceClient,
} from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { ActionsProps } from "./components/Actions/Actions";
import { handleNonComplianceError } from "utils/handleNonComplianceError";

export async function getDamagedProductDetails({
  issue,
  client,
  setIsLoading,
  refreshIssue,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
  setIsLoading: (isLoading: boolean) => void;
  refreshIssue: () => void;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
  actionProps: Pick<ActionsProps, "resolveDispute" | "dispose" | "manualActionRequired" | "markAsComplete"> | null;
}> {
  const damagedProductIssue = await client.getIssueDamagedProductById({ issueId: issue.id });
  const damagedProductIssueExtended = await client.getIssueDamagedProductExtendedById({
    issueId: issue.id,
  });

  const issueDetails = {
    issue: damagedProductIssue.issue,
    images: damagedProductIssue.images,
    issueEntities: damagedProductIssue.issueEntities,
    zendeskTickets: damagedProductIssue.zendeskTickets,
    fields: [
      { type: FIELD_TYPE.UNIT_DISPOSITION, value: damagedProductIssue.unitDisposition },
      { type: FIELD_TYPE.BARCODE, value: damagedProductIssue.barcode },
      { type: FIELD_TYPE.DSKU, value: damagedProductIssue.dsku },
      { type: FIELD_TYPE.IMMEDIATE_DISPOSAL, value: damagedProductIssue.immediateDisposal ? "Yes" : "No" },
      { type: FIELD_TYPE.SUPPORT_NOTES, value: damagedProductIssue.notes },
    ],
    reimbursementData: {
      reimbursedAt: damagedProductIssueExtended.reimbursedAt,
      reimbursementUnitCost: damagedProductIssueExtended.reimbursementUnitCost,
    },
    disputeData: {
      inDispute: damagedProductIssueExtended.inDispute,
      disputeOpenedAt: damagedProductIssueExtended.disputeOpenedAt,
      disputeClosedAt: damagedProductIssueExtended.disputeClosedAt,
    },
  };

  const setDisposition = async (disposition: DamagedProductUnitDisposition) => {
    setIsLoading(true);
    try {
      if (damagedProductIssue) {
        await client.approveProductDispositionForDamagedProduct({
          issueId: damagedProductIssue.issue.id,
          disposition,
        });
        await refreshIssue();
        setIsLoading(false);
        toast.success("Unit disposition updated", { position: "top-right" });
      } else {
        setIsLoading(false);
        toast.error("No issue present", { position: "top-right" });
      }
    } catch (error) {
      handleNonComplianceError({ fn: "useDamagedProductTool.setDisposition" }, error as Error);
      setIsLoading(false);
    }
  };

  const markAsComplete = async () => {
    setIsLoading(true);
    try {
      if (damagedProductIssue) {
        await client.completeIssueDamagedProduct({ issueId: damagedProductIssue.issue.id });
        await refreshIssue();
        setIsLoading(false);
        toast.success("Issue marked as complete", { position: "top-right" });
      } else {
        setIsLoading(false);
        toast.error("No issue present", { position: "top-right" });
      }
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "useDamagedProductTool.markAsComplete" }, error as Error);
    }
  };

  const resolveDispute = async () => {
    setIsLoading(true);
    try {
      if (damagedProductIssue) {
        await client.resolveIssueDamagedProductDispute({ issueId: damagedProductIssue.issue.id });
        await refreshIssue();
        setIsLoading(false);
        toast.success("Dispute marked as resolved", { position: "top-right" });
      } else {
        setIsLoading(false);
        toast.error("Error marking dispute as resolved", { position: "top-right" });
      }
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "useDamagedProductTool.resolveDispute" }, error as Error);
    }
  };

  const showDispositionAction =
    damagedProductIssue.issue.status === IssueStatus.AWAITING_SUPPORT && damagedProductIssue.unitDisposition === null;
  const showMarkAsCompleteAction = damagedProductIssue.unitDisposition === DamagedProductUnitDisposition.OTHER;
  const showResolveDispute = Boolean(damagedProductIssueExtended.inDispute);

  return {
    issueDetails,
    actionProps: {
      resolveDispute: showResolveDispute ? resolveDispute : null,
      dispose: showDispositionAction ? () => setDisposition(DamagedProductUnitDisposition.DISPOSE) : null,
      manualActionRequired: showDispositionAction ? () => setDisposition(DamagedProductUnitDisposition.OTHER) : null,
      markAsComplete: showMarkAsCompleteAction ? markAsComplete : null,
    },
  };
}
