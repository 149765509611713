import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { AUTH_AUDIENCE } from "./audience";

interface AuthProviderProps {
  app?: string;
}

export const AuthProvider: React.FC<AuthProviderProps & { children: React.ReactNode }> = ({ app = "", children }) => {
  const domain = process.env.REACT_APP_CUSTOM_AUTH0_LOGIN_DOMAIN!;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
  const navigate = useNavigate();

  const onRedirectCallback: Auth0ProviderOptions["onRedirectCallback"] = (appNameState) => {
    navigate(appNameState?.returnTo ?? window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
      audience={AUTH_AUDIENCE}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
