import { ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";

const InstructionsContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    margin-bottom: ${theme.spacing.S4};
  `
);

const InstructionsTitle = styled.h3<ThemeProps>(
  ({ theme }) => `
    font-size: ${theme.font.size.F3};
    color: ${theme.colors.NEUTRAL["500"]};
    font-weight: bold;
    margin-bottom: ${theme.spacing.S1};
  `
);

const InstructionList = styled.ol<ThemeProps>(
  ({ theme }) => `
    list-style-type: decimal;
    margin-left: ${theme.spacing.S4};
    > li {
      font-size: ${theme.font.size.F2};
      margin-bottom: ${theme.spacing.S1};
    }
  `
);

export const NetCHBProcessInstructions: React.FC = () => {
  return (
    <InstructionsContainer>
      <InstructionsTitle>Instructions</InstructionsTitle>
      <InstructionList>
        <li>Find the Freight shipment within Flexport Core using the FLEX-ID.</li>
        <li>Look for the "Master Air Waybill" document.</li>
        <li>The document itself, and document name will contain the MAWB#.</li>
        <li>Enter the MAWB# in the field above.</li>
      </InstructionList>
    </InstructionsContainer>
  );
};
