import { useAuth0 } from "@auth0/auth0-react";
import { useSetRecoilState } from "recoil";
import * as Cookie from "js-cookie";

import { useCallback, useEffect } from "react";
import { UserState, userState } from "base/Auth/userState";
import { COOKIE_KEYS, ROLES } from "types/Auth";
import { setLogUser } from "utils/logger";

const DELIVERR_URL = "https://deliverr.com/";

// Helper to check if a role is valid
function isValidRole(role: string): role is ROLES {
  return Object.values(ROLES).includes(role as ROLES);
}

export function useUserState() {
  const setAuthUser = useSetRecoilState(userState);
  const { user, isAuthenticated, isLoading } = useAuth0();

  const updateUserInfo = useCallback(async () => {
    if (user?.[DELIVERR_URL] && isAuthenticated && !isLoading) {
      const roles: string[] = user[DELIVERR_URL]?.roles || [];

      const userData: UserState = {
        userName: user.nickname!,
        email: user.email!,
        isAdmin: roles.includes(ROLES.SUPPORT_ROLE),
        roles: roles.filter((role) => isValidRole(role)) as ROLES[],
      };

      setAuthUser(userData);
      setLogUser({ email: userData.email });

      Cookie.set(COOKIE_KEYS.EMAIL, user.email!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, setAuthUser, user]);

  useEffect(() => {
    updateUserInfo();
  }, [updateUserInfo]);

  return {
    isAuthenticated,
    isLoading,
  };
}
