import { NetworkNode } from "@deliverr/carrier-network-types";
import { toast } from "@deliverr/ui";
import { useState } from "react";
import { useParams } from "react-router";
import { useAsync } from "react-use";
import { useClientsWithAuth } from "../../../hooks/auth/useClientsWithAuth";

export const useNetworkNodeDetails = () => {
  const { nodeId } = useParams() as {
    nodeId: string;
  };

  const [node, setNode] = useState<NetworkNode>();

  const { carrierNetworkClient } = useClientsWithAuth();

  const { loading } = useAsync(async () => {
    try {
      // Fetch node details
      const networkNodeResp = await carrierNetworkClient.getNode(nodeId);
      setNode(networkNodeResp);
    } catch (err) {
      toast.error(`Unable to retrieve the network node for id:${nodeId}.`, {
        position: "top-right",
      });
    }
  }, [nodeId]);

  return {
    loading,
    node,
  };
};
