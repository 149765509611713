import styled from "@emotion/styled";
import cytoscape, { ElementDefinition } from "cytoscape";
import { useEffect, useRef } from "react";
import { NODE_STYLES, EDGE_STYLES, EDGE_SELECTED_STYLES, NODE_SELECTED_STYLES } from "./GraphStyles";

interface CytoscapeComponentProps {
  elements: ElementDefinition[];
  onNodeOrEdgeSelection: (originNode: string, destinationNode?: string) => void;
  onResetNodeOrEdgeSelection: () => void;
}

const StyledGraphContainer = styled.div`
  width: 100%;
  height: 40vh;
`;

export const CytoscapeGraph: React.FC<CytoscapeComponentProps> = ({
  elements,
  onNodeOrEdgeSelection,
  onResetNodeOrEdgeSelection,
}) => {
  const cyRef = useRef<cytoscape.Core | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Create the Cytoscape graph
  useEffect(() => {
    if (!containerRef.current) return;

    // Initialize Cytoscape with the provided elements
    const cy = cytoscape({
      container: containerRef.current,
      elements,
      style: [NODE_STYLES, NODE_SELECTED_STYLES, EDGE_STYLES, EDGE_SELECTED_STYLES],
      layout: {
        /**
         * The cose (Compound Spring Embedder) layout uses a physics simulation to lay out graphs. It works well with
         * noncompound graphs and it has additional logic to support compound graphs well.
         */
        name: "cose",

        // Reduce padding to fit nodes in container better
        padding: 10,
      },
    });

    // Capture all click events within the Cytoscape graph
    cy.on("tap", function (evt) {
      if (evt.target === cy) {
        // Background click event
        onResetNodeOrEdgeSelection();
      } else if (evt.target.isNode()) {
        // Node click event
        onNodeOrEdgeSelection(evt.target.id());
      } else if (evt.target.isEdge()) {
        // Edge click event
        const edgeSource = evt.target.source();
        const edgeTarget = evt.target.target();
        onNodeOrEdgeSelection(edgeSource.id(), edgeTarget.id());
      } else {
        // Fall back to reset if we don't properly capture the click event
        onResetNodeOrEdgeSelection();
      }
    });

    cyRef.current = cy;

    // Cleanup on unmount
    return () => {
      cy.destroy();
    };
  }, [elements, onNodeOrEdgeSelection, onResetNodeOrEdgeSelection]);

  // Return the styled container which Cytoscape injects itself into
  return <StyledGraphContainer ref={containerRef} />;
};
