import {
  IssueGetResponse,
  IssueStatus,
  MismatchedDimensionsGetResponseCurrentProductDimensions,
  NonComplianceClient,
  ProductDimensionsGetResponse,
} from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { Field } from "../components/Field";
import { Grid, Text, toast } from "@deliverr/ui";
import { format } from "date-fns";
import { ActionsProps } from "./components/Actions/Actions";
import { handleNonComplianceError } from "../../../utils/handleNonComplianceError";
import React from "react";

const enum MismatchedDimensionsField {
  MISMATCH_TYPE = "Mismatch type",
  APPROVED_AT = "Approved at",
  REJECTED_AT = "Rejected at",
  CURRENT_DIMENSIONS = "Current dimensions",
  CAPTURED_DIMENSIONS = "Captured dimensions",
  FULFILLMENT_FEES = "Fulfillment fees",
  WEIGHT = "Weight",
  WEIGHT_UNIT = "Weight unit",
  HEIGHT = "Height",
  LENGTH = "Length",
  LENGTH_UNIT = "Length unit",
  WIDTH = "Width",
  DIM_SOURCE_TYPE = "Dimension source type",
}

const enum ReviewType {
  APPROVE = "Approve",
  REJECT = "Reject",
}

export async function getMismatchedDimensionsDetails({
  issue,
  client,
  setIsLoading,
  refreshIssue,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
  setIsLoading: (isLoading: boolean) => void;
  refreshIssue: () => void;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
  actionsProps: Required<Pick<ActionsProps, "markAsComplete" | "approveProductDimensions" | "rejectProductDimensions">>;
}> {
  const mismatchedDimensions = await client.getIssueMismatchedDimensionsById({ issueId: issue.id });

  const issueDetails = {
    issue: mismatchedDimensions.issue,
    images: mismatchedDimensions.images,
    issueEntities: mismatchedDimensions.issueEntities,
    zendeskTickets: mismatchedDimensions.zendeskTickets,
    fields: [
      { type: MismatchedDimensionsField.MISMATCH_TYPE, value: formatMismatchType(mismatchedDimensions.mismatchType) },
      { type: FIELD_TYPE.DSKU, value: mismatchedDimensions.dsku },
      { type: FIELD_TYPE.BARCODE, value: mismatchedDimensions.barcode },
      {
        type: MismatchedDimensionsField.APPROVED_AT,
        value: mismatchedDimensions.approvedAt
          ? format(new Date(mismatchedDimensions.approvedAt), "MMMM dd, yyyy HH:MM:SS")
          : null,
      },
      {
        type: MismatchedDimensionsField.REJECTED_AT,
        value: mismatchedDimensions.rejectedAt
          ? format(new Date(mismatchedDimensions.rejectedAt), "MMMM dd, yyyy HH:MM:SS")
          : null,
      },
      {
        type: MismatchedDimensionsField.CURRENT_DIMENSIONS,
        value: <ProductDimensions dimensions={mismatchedDimensions.currentProductDimensions} />,
      },
      {
        type: MismatchedDimensionsField.CAPTURED_DIMENSIONS,
        value: <ProductDimensions dimensions={mismatchedDimensions.capturedProductDimensions} />,
      },
      {
        type: MismatchedDimensionsField.FULFILLMENT_FEES,
        value: (
          <FulfillmentFeeInfo
            currentFulfillmentFee={mismatchedDimensions.currentFulfillmentFee}
            capturedFulfillmentFee={mismatchedDimensions.capturedFulfillmentFee}
          />
        ),
      },
    ],
  };

  const markAsComplete = async () => {
    setIsLoading(true);
    try {
      if (mismatchedDimensions) {
        await client.completeIssueMismatchedDimensions({ issueId: mismatchedDimensions.issue.id });
        await refreshIssue();
        setIsLoading(false);
        toast.success("Issue marked as complete", { position: "top-right" });
      } else {
        setIsLoading(false);
        toast.error("No issue present", { position: "top-right" });
      }
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "getMismatchedDimensionsDetails.markAsComplete" }, error as Error);
    }
  };

  const reviewProductDimensions = (reviewType: ReviewType) => {
    return async () => {
      setIsLoading(true);
      try {
        if (mismatchedDimensions) {
          if (reviewType === ReviewType.APPROVE) {
            await client.approveProductDimensionsForIssueMismatchedDimensions({
              issueId: mismatchedDimensions.issue.id,
            });
          } else {
            await client.rejectProductDimensionsForIssueMismatchedDimensions({
              issueId: mismatchedDimensions.issue.id,
            });
          }
          await refreshIssue();
          setIsLoading(false);
          toast.success(`Product dimensions ${reviewType === ReviewType.APPROVE ? "approved" : "rejected"}`, {
            position: "top-right",
          });
        } else {
          setIsLoading(false);
          toast.error("No issue present", { position: "top-right" });
        }
        return;
      } catch (error) {
        setIsLoading(false);
        handleNonComplianceError({ fn: "getMismatchedDimensionsDetails.reviewProductDimensions" }, error as Error);
      }
    };
  };

  const showMarkAsComplete =
    (!!mismatchedDimensions.rejectedAt || !!mismatchedDimensions.approvedAt) &&
    mismatchedDimensions.issue.status === IssueStatus.AWAITING_SUPPORT;
  const showReviewProductDimensions =
    !mismatchedDimensions.approvedAt &&
    !mismatchedDimensions.rejectedAt &&
    mismatchedDimensions.issue.status === IssueStatus.AWAITING_SUPPORT;

  return {
    issueDetails,
    actionsProps: {
      markAsComplete: showMarkAsComplete ? markAsComplete : null,
      approveProductDimensions: showReviewProductDimensions ? reviewProductDimensions(ReviewType.APPROVE) : null,
      rejectProductDimensions: showReviewProductDimensions ? reviewProductDimensions(ReviewType.REJECT) : null,
    },
  };
}

const ProductDimensions = ({
  dimensions,
}: {
  dimensions: MismatchedDimensionsGetResponseCurrentProductDimensions | ProductDimensionsGetResponse | null;
}) => {
  if (!dimensions)
    return (
      <Text as="span" size="body" appearance="INFO">
        {"N/A"}
      </Text>
    );

  return (
    <Grid columns="1fr 1fr" gap="S2">
      <Field label={MismatchedDimensionsField.WEIGHT} value={dimensions.weight} />
      <Field label={MismatchedDimensionsField.WEIGHT_UNIT} value={dimensions.weightUnit} />
      <Field label={MismatchedDimensionsField.HEIGHT} value={dimensions.height} />
      <Field label={MismatchedDimensionsField.LENGTH} value={dimensions.length} />
      <Field label={MismatchedDimensionsField.LENGTH_UNIT} value={dimensions.lengthUnit} />
      <Field label={MismatchedDimensionsField.WIDTH} value={dimensions.width} />
      <Field label={MismatchedDimensionsField.DIM_SOURCE_TYPE} value={dimensions.dimSourceType} />
    </Grid>
  );
};

const FulfillmentFeeInfo = ({
  currentFulfillmentFee,
  capturedFulfillmentFee,
}: {
  currentFulfillmentFee: number | null;
  capturedFulfillmentFee: number | null;
}) => {
  // Logic to determine the message based on the fees
  let message: React.ReactNode;

  if (currentFulfillmentFee === null && capturedFulfillmentFee === null) {
    message = "There is no fulfillment fee information for this issue";
  } else if (capturedFulfillmentFee === null) {
    message = "There is no captured fulfillment fee information for this issue";
  } else if (currentFulfillmentFee === null) {
    message = `The first time captured fulfillment fee is $${capturedFulfillmentFee.toFixed(2)}`;
  } else if (currentFulfillmentFee === capturedFulfillmentFee) {
    message = "There is no change in the fulfillment fee";
  } else {
    const difference = Math.abs(capturedFulfillmentFee - currentFulfillmentFee).toFixed(2);
    const comparison = capturedFulfillmentFee > currentFulfillmentFee ? "greater" : "less";
    message = (
      <div>
        The captured fulfillment fee is ${difference} <b>{comparison}</b> than the previous fulfillment fee
      </div>
    );
  }

  return (
    <>
      <Grid columns="1fr 1fr" gap="S2">
        <Field
          label="Current fulfillment fee"
          value={currentFulfillmentFee !== null ? `$${currentFulfillmentFee.toFixed(2)}` : "N/A"}
        />
        <Field
          label="Captured fulfillment fee"
          value={capturedFulfillmentFee !== null ? `$${capturedFulfillmentFee.toFixed(2)}` : "N/A"}
        />
      </Grid>
      <Field label="Fulfillment Fee Change" value={message} />
    </>
  );
};

function formatMismatchType(mismatchType: string | undefined): string | undefined {
  return (
    mismatchType &&
    mismatchType
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  );
}
