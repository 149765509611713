// Utility function to replace null values with undefined
export const replaceNullWithUndefined = <T>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(replaceNullWithUndefined) as unknown as T;
  } else if (obj !== null && typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, replaceNullWithUndefined(value === null ? undefined : value)])
    ) as T;
  }
  return obj;
};
