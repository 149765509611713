import React from "react";
import { IconV2 } from "@deliverr/ui";
import { SortDir } from "types/SortDir";
import { SortFields } from "./CreditBlockItem";

interface SortIconProps {
  sort: SortFields;
  handleSort: () => void;
}

const SortIcon: React.FC<SortIconProps> = ({ sort, handleSort }) => {
  return (
    <span onClick={handleSort} style={{ cursor: "pointer" }}>
      {sort.direction === SortDir.ASC ? <IconV2 type="arrow-up" /> : <IconV2 type="arrow-down" />}
    </span>
  );
};

export default SortIcon;
