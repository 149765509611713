import { Text } from "@deliverr/ui";
import { formatDate } from "date-fns";

export enum DateFormat {
  TimeAndDate = "h:mma M/d/yyyy",
  DateAtTime = "M/d/yyyy 'at' hh:mma",
  Date = "M/d/yyyy",
  LongDate = "mM/dD/YYYY",
  YearDayMonth = "yyyy-dd-MM",
  MonthAndDay = "LLLL d",
  MonthDayYear = "MMMM d',' yyyy",
  ShortMonthAndDay = "MMM d",
  ShortMonthDayYear = "MMM d',' yyyy",
  ShortMonthDayTime = "MMM dd hh:mm aa",
  ShortMonthDayYearTimeWithCommaSeparator = "MMM dd',' yyyy',' hh:mm aa",
}

export const MonthDayYearRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;

interface DisplayDateProps {
  date: Date | null;
  format: DateFormat;
}

export const DisplayDate = ({ date, format }: DisplayDateProps) => {
  if (!date) return null;

  return <Text as="span">{formatDate(date, format)}</Text>;
};
