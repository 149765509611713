import { toast } from "@deliverr/ui";
import { useState } from "react";
import { logError } from "utils/logger";
import { useAsyncFn } from "react-use";
import { inboundClient } from "base/clients";
import { AttributionResults } from "@deliverr/legacy-inbound-client/lib/models/ReceiveAttribution";

export const useShipmentAssignmentCard = ({
  boxLabel,
  onAssignedToShipment,
}: {
  boxLabel: string;
  onAssignedToShipment: (boxLabel: string) => void;
}) => {
  const [shipmentId, setShipmentId] = useState<string>("");
  const [previewResults, setPreviewResults] = useState<AttributionResults | null>(null);

  const [{ loading: isSubmittingPreview }, onSubmitPreview] = useAsyncFn(async () => {
    try {
      if (!shipmentId) {
        toast.error("shipment Id is required");
        return;
      }
      const previewResults = await inboundClient.assignShipmentToUnlabeledBoxPreview({
        boxLabel,
        shipmentId: Number(shipmentId),
      });

      if (previewResults.error) {
        if (previewResults.error.message) {
          toast.error(previewResults.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering");
        return;
      }

      if (previewResults.data) {
        toast.success("Preview successful", { position: "top-right" });
        setPreviewResults(previewResults.data);
      }
    } catch (error: any) {
      // Validation errors are not returned like the regular receiving errors that
      // show up in the try block under previewResults.error
      if (error?.response?.data?.error?.payload) {
        toast.error(error.response.data.error.payload, { position: "top-right" });
        return;
      }
      logError({ fn: "useReceiveAttribution.onSubmitPreview" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering");
    }
  }, [shipmentId, boxLabel, inboundClient, previewResults, setPreviewResults]);

  function resetAll() {
    setPreviewResults(null);
    setShipmentId("");
  }

  const [{ loading: isSubmittingCommit }, applyShipmentAssignment] = useAsyncFn(async () => {
    try {
      if (!shipmentId) {
        toast.error("Shipment Id is required");
        return;
      }

      const persistResults = await inboundClient.assignShipmentToUnlabeledBoxCommit({
        boxLabel,
        shipmentId: Number(shipmentId),
      });

      if (persistResults.error) {
        if (persistResults.error.message) {
          toast.error(persistResults.error.message, { position: "top-right" });
          return;
        }
        toast.error("Something went wrong, try again or contact engineering", { position: "top-right" });
        return;
      }
      toast.success("Attribution committed successfully", { position: "top-right" });
      resetAll();
      onAssignedToShipment(boxLabel);
    } catch (error: any) {
      logError({ fn: "useReceiveAttribution.applyAsnAssignment" }, error as Error);
      toast.error("Something went wrong, try again or contact engineering", { position: "top-right" });
    }
  }, [shipmentId, boxLabel, inboundClient]);

  return {
    previewResults,
    resetAll,
    onSubmitPreview,
    applyShipmentAssignment,
    isSubmittingPreview,
    isSubmittingCommit,
    shipmentId,
    setShipmentId,
  };
};
