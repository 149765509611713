import { Card } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { LoadingSpinner } from "components/LoadingSpinner";
import { LaneGraph } from "./LaneGraph";
import { LaneTable } from "./LaneTable";
import { useNetworkLanes } from "./useNetworkLanes";

const StyledContainer = styled(Container)`
  // Matches the top margin in the Container component
  margin-bottom: 30px;
`;

export const NetworkLanes: React.FC = () => {
  const { loading, network, laneTableData, handleNodeOrEdgeSelection, resetNodeOrEdgeSelection } = useNetworkLanes();

  return (
    <StyledContainer fullWidth={true}>
      <Card>
        <LoadingSpinner loading={loading} text="Loading Network Lanes">
          <LaneGraph
            network={network}
            handleNodeOrEdgeSelection={handleNodeOrEdgeSelection}
            resetNodeOrEdgeSelection={resetNodeOrEdgeSelection}
          />
          <LaneTable laneTableData={laneTableData} />
        </LoadingSpinner>
      </Card>
    </StyledContainer>
  );
};
