import { Text, Textarea, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";

const TextAreaGroup = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing.S2};
  margin-bottom: ${theme.spacing.S2};
`
);

const StyledTextArea = styled(Textarea)<ThemeProps>(
  ({ theme }) => `
  height: 25vh;
  margin-top: ${theme.spacing.S2};
  margin-bottom: ${theme.spacing.S2};
`
);

interface ZipCodeTextAreaProps {
  zipCodes: string[];
  errorMessage: string;
  handleSetZipCodes: (val: string) => void;
}

export const ZipCodeTextArea = ({ zipCodes, errorMessage, handleSetZipCodes }: ZipCodeTextAreaProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => handleSetZipCodes(e.target.value);

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    // Replace new lines with commas
    const formattedText = text.replace(/\n/g, ",");
    handleSetZipCodes(formattedText);
  };

  return (
    <TextAreaGroup>
      <Text appearance="INFO">Zip Codes</Text>
      <StyledTextArea value={zipCodes.join(",")} onPaste={handlePaste} onChange={handleChange} />
      <Text appearance="DANGER">{errorMessage}</Text>
    </TextAreaGroup>
  );
};
