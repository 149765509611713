import { ModalGenericProps } from "./ModalTypes";
import { visibleSupportPoralModalsState } from "./SupportPortalModalState";
import { useResetRecoilState, useRecoilState } from "recoil";

export const useSupportPortalModal = <T extends string, P extends ModalGenericProps>() => {
  const [visibleModals, setVisibleModals] = useRecoilState(visibleSupportPoralModalsState);
  const resetVisibleModals = useResetRecoilState(visibleSupportPoralModalsState);

  function showModal(modalType: T, props: P): void {
    setVisibleModals({ ...visibleModals, [modalType]: props });
  }

  function hideModal(modalType: T): void {
    setVisibleModals((prevState) => {
      const { [modalType]: _, ...rest } = prevState;
      return rest;
    });
  }

  return {
    showModal,
    hideModal,
    hideAllModals: resetVisibleModals,
  };
};
