import { useEffect, useState } from "react";
import { UseFormWatch } from "react-hook-form";

// Used to execute a callback when the values of a react-hook-form change
export const useOnFormValueChange = <FormValues>(watch: UseFormWatch<FormValues>, onChange: () => void) => {
  const allFormValues = watch();
  const [lastFormValues, setLastFormValues] = useState(JSON.stringify(allFormValues));

  useEffect(() => {
    const currentFormValuesStr = JSON.stringify(allFormValues);

    // Compare current stringified form values with the last known values
    if (currentFormValuesStr !== lastFormValues) {
      onChange();
      setLastFormValues(currentFormValuesStr); // update the last known values
    }
  }, [allFormValues, lastFormValues, onChange]);
};
