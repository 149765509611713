import { Route, Routes, useNavigate } from "react-router-dom";
import { useUserState } from "hooks/auth/useUserState";
import { AppLayout } from "components/AppLayout";
import { Path } from "paths";
import { SidebarContent } from "components/Sidebar/SidebarContent";
import { SidebarContextProvider } from "components/Sidebar";
import { NavBar } from "components/NavBar";
import { inboundHeaderRoutes, inboundRoutes } from "components/inbounds/InboundsRoutes";
import { Container } from "components/Container";
import { sortationHeaderRoutes, sortationRoutes } from "components/sortation/SortationRoutes";
import { nonComplianceHeaderRoutes, nonComplianceRoutes } from "components/nonCompliance/NonComplianceRoutes";
import { parcelHeaderRoutes, parcelRoutes } from "components/parcel/ParcelRoutes";
import { carrierNetworkHeaderRoutes, carrierNetworkRoutes } from "./components/carrier-network/CarrierNetworkRoutes";
import { creditBlockRoutes, financeHeaderRoutes } from "components/finance/FinanceRoutes";

const headerRoutes = [
  <Route key="rootHeaderRoute" path={Path.root} element={<NavBar breadcrumbs={[{ pageName: "Home" }]} />} />,
  ...inboundHeaderRoutes,
  ...sortationHeaderRoutes,
  ...nonComplianceHeaderRoutes,
  ...parcelHeaderRoutes,
  ...carrierNetworkHeaderRoutes,
  ...financeHeaderRoutes,
];
const pageRoutes = [
  <Route
    key="rootPageRoute"
    path={Path.root}
    element={<Container> Select a tool from the left hand side</Container>}
  />,
  ...inboundRoutes,
  ...sortationRoutes,
  ...nonComplianceRoutes,
  ...parcelRoutes,
  ...carrierNetworkRoutes,
  ...creditBlockRoutes,
];

export const SupportApp = () => {
  const { isAuthenticated } = useUserState();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    navigate(process.env.REACT_APP_CUSTOM_AUTH0_LOGIN_DOMAIN!);
    return undefined;
  }

  return (
    <SidebarContextProvider>
      <Routes>
        <Route
          path="*"
          element={
            <AppLayout headerRoutes={headerRoutes} pageRoutes={pageRoutes} sidebarContent={<SidebarContent />} />
          }
        />
      </Routes>
    </SidebarContextProvider>
  );
};
