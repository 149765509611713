import { useMemo, useState } from "react";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { SubmitHandler, useController, useForm } from "react-hook-form";
import { handleInboundVisibilityError } from "utils/handleInboundVisibilityError";
import { BoxStatus } from "@deliverr/inbound-visibility-client";

type FormValues = {
  cdSkus: string;
  notes?: string;
  boxStatus: { label: string; value: BoxStatus };
};

const BOX_STATUS_DISPLAY_TEXT = {
  [BoxStatus.MISSING]: "MISSING",
  [BoxStatus.OTHER]: "OTHER",
};

export const useBoxReconciliationTool = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({});
  const { inboundVisibilityClient } = useClientsWithAuth(); // Assuming this client exists

  const cdSkusController = useController({
    name: "cdSkus",
    control,
    rules: {
      required: "CDSKUs are required",
    },
    defaultValue: "",
  });

  const notesController = useController({
    name: "notes",
    control,
    defaultValue: "",
  });

  const boxStatusController = useController({
    name: "boxStatus",
    control,
    rules: {
      required: true,
    },
  });

  const statusFilterOptions = useMemo(
    () =>
      Object.entries(BOX_STATUS_DISPLAY_TEXT).map(([key, value]) => ({
        label: value,
        value: key,
      })),
    [BOX_STATUS_DISPLAY_TEXT]
  );

  const onSubmitBoxReconciliationsTool: SubmitHandler<FormValues> = async (data) => {
    try {
      setIsSubmitting(true);
      const cdSkusInput = data.cdSkus;
      // Split and clean the input into an array of boxLabels
      const boxLabels = cdSkusInput
        .split(",")
        .map((sku) => sku.trim())
        .filter((sku) => sku !== "");

      if (boxLabels.length === 0) {
        toast.error("Please enter at least one valid CDSKU", { position: "top-right" });
        setIsSubmitting(false);
        return;
      }

      const notesInput = data.notes?.trim();
      const notes = notesInput ? notesInput : undefined;

      const boxStatus = data.boxStatus.value;

      // Prepare payload
      const payload = { boxLabels, notes, status: boxStatus };

      // Call the API
      await inboundVisibilityClient.createBoxReconciliations(payload);
      toast.success("Submission successful", { position: "top-right" });
      resetAll();
    } catch (error) {
      handleInboundVisibilityError({ fn: "useBoxReconciliationTool.onSubmitBoxReconciliationsTool" }, error as Error);
    } finally {
      setIsSubmitting(false);
    }
  };

  function resetAll() {
    reset();
    setIsSubmitting(false);
  }

  return {
    onSubmitBoxReconciliationTool: handleSubmit(onSubmitBoxReconciliationsTool),
    cdSkusController,
    notesController,
    boxStatusController,
    resetAll,
    isSubmitting,
    statusFilterOptions,
  };
};
