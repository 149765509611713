export enum OrganizationStatusReason {
  // ACTIVE reason
  NegotiatedPaymentSchedule = "Negotiated Payment Schedule",
  TestAccount = "Test / Sandbox Account",
  PaymentApplicationPending = "Payment Application Pending",
  ChurnRisk = "Churn Risk",
  OpenDispute = "Open Dispute",

  // SUSPEND reason
  Suspended = "Suspended",
  ExceedingDueTolerance = "Exceeding Due Tolerance",

  // INACTIVE reason
  Offboarded = "Offboarded",
}
