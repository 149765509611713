import { GetNetworkResponse, NetworkLane, NetworkNode } from "@deliverr/carrier-network-types";
import { EdgeDefinition, ElementDefinition, NodeDefinition } from "cytoscape";
import { useEffect, useState } from "react";

// Process the network lanes into edges that are consumable by Cytoscape
const processEdges = (lanes: NetworkLane[]): EdgeDefinition[] => {
  let edges: EdgeDefinition[] = [];

  lanes.forEach((lane) => {
    let edge = edges.find(
      (edge) => edge.data.source === lane.originNodeId && edge.data.target === lane.destinationNodeId
    );

    if (edge) {
      edge.data.label += `, ${lane.description}`;
    } else {
      edges.push({ data: { source: lane.originNodeId, target: lane.destinationNodeId, label: lane.description } });
    }
  });

  return edges;
};

// Process the network nodes into nodes that are consumable by Cytoscape
const processNodes = (nodes: NetworkNode[]): NodeDefinition[] => {
  return nodes.reduce((acc: NodeDefinition[], node) => {
    if (!acc.some((n) => n.data.id === node.id)) {
      acc.push({ data: { id: node.id, label: node.id, type: node.type } });
    }
    return acc;
  }, []);
};

export const useLaneGraph = (network: GetNetworkResponse) => {
  // State for Cytoscope element definition for nodes and edges
  const [elements, setElements] = useState<ElementDefinition[]>([]);

  // Only reprocess the elements when the network changes
  useEffect(() => {
    const nodes = processNodes(network.nodes);
    const edges = processEdges(network.lanes);

    setElements([...nodes, ...edges]);
  }, [network]);

  return { elements };
};
