import { Text, Stack } from "@deliverr/ui";
import { Container } from "components/Container";
import { LoadingSpinner } from "components/LoadingSpinner";
import React from "react";
import { useNavigate } from "react-router";
import { Field, NetworkButtonGroup, NetworkButton, SplitInfoSection, NetworkTitle, NetworkCard } from "../components";
import { formatISODate } from "../utils";
import { useNetworkNodeDetails } from "./useNetworkNodeDetails";

export const NetworkNodeDetails: React.FC = () => {
  const navigate = useNavigate();
  const { loading, node } = useNetworkNodeDetails();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Container fullWidth={false}>
      <NetworkCard>
        <LoadingSpinner loading={loading} text="Loading Node Details">
          {node ? (
            <>
              <NetworkTitle size="h2">Network Node Information</NetworkTitle>
              <Stack gap="S1">
                <Field label="Node ID" value={node.id} />
                <Field label="Node Type" value={node.type} />
                <Field label="Description" value={node.description} />
                <Field label="Timezone" value={node.timeZone} />
                <Field label="Active Node" value={node.isActive ? "Yes" : "No"} />
                <Field label="Created At" value={formatISODate(node.createdAt)} />
                <Field label="Updated At" value={formatISODate(node.updatedAt)} />
              </Stack>
            </>
          ) : (
            <Stack>
              <Text size="h2">Error</Text>
              <Text>No node found for the provided node ID</Text>
            </Stack>
          )}
        </LoadingSpinner>
      </NetworkCard>
      {!loading && node?.sortationCenterData && Object.keys(node.sortationCenterData).length > 0 && (
        <NetworkCard>
          <NetworkTitle size="h2">Sortation Center</NetworkTitle>
          <SplitInfoSection>
            <Stack gap="S1">
              <NetworkTitle bold>Details</NetworkTitle>
              <Field label="ID" value={node.sortationCenterData?.id} />
              <Field label="Hub ID" value={node.sortationCenterData?.hubId} />
              <Field label="Created At" value={formatISODate(node.sortationCenterData.createdAt)} />
              <Field label="Updated At" value={formatISODate(node.sortationCenterData.updatedAt)} />
            </Stack>
            <Stack gap="S1">
              <NetworkTitle bold>Deliverr Address</NetworkTitle>
              <Field label="name" value={node.sortationCenterData.address.name} />
              <Field label="company" value={node.sortationCenterData.address.company} />
              <Field label="street1" value={node.sortationCenterData.address.street1} />
              <Field label="street2" value={node.sortationCenterData.address.street2} />
              <Field label="city" value={node.sortationCenterData.address.city} />
              <Field label="zip" value={node.sortationCenterData.address.zip} />
              <Field label="state" value={node.sortationCenterData.address.state} />
              <Field label="country" value={node.sortationCenterData.address.country} />
              <Field label="phone" value={node.sortationCenterData.address.phone} />
              <Field label="email" value={node.sortationCenterData.address.email} />
            </Stack>
          </SplitInfoSection>
        </NetworkCard>
      )}
      {!loading && (
        <NetworkCard>
          <NetworkButtonGroup>
            <NetworkButton secondary onClick={handleBackClick}>
              Back
            </NetworkButton>
            {/* TODO: Edit Node Functionality*/}
            {node && <NetworkButton disabled>Edit Node</NetworkButton>}
          </NetworkButtonGroup>
        </NetworkCard>
      )}
    </Container>
  );
};
