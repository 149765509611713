import { defaultTheme } from "@deliverr/ui";
import { NodeSingular, Stylesheet } from "cytoscape";

/**
 * This file contains the styles for the nodes and edges in the Cytoscape graph.
 * Documentation for Cytoscape styles can be found here: https://js.cytoscape.org/#style
 */

// Node Background
const NODE_BACKGROUND_COLOR_SC = defaultTheme.colors.GREEN[300];
const NODE_BACKGROUND_COLOR_FC = defaultTheme.colors.BLUE[300];
const NODE_BACKGROUND_COLOR_SELECTED = defaultTheme.colors.ORANGE[300];

// Node Border
const NODE_BORDER_COLOR_SC = defaultTheme.colors.GREEN[500];
const NODE_BORDER_COLOR_FC = defaultTheme.colors.BLUE[500];
const NODE_BORDER_COLOR_SELECTED = defaultTheme.colors.ORANGE[500];

// Node Text
const NODE_TEXT_COLOR = defaultTheme.colors.NEUTRAL["00"];
const NODE_TEXT_OUTLINE_COLOR = defaultTheme.colors.NEUTRAL[500];

// Edge Color
const EDGE_COLOR = defaultTheme.colors.BLUE[100];
const EDGE_COLOR_SELECTED = defaultTheme.colors.ORANGE[300];

// Edge Text
const EDGE_TEXT_COLOR = defaultTheme.colors.NEUTRAL[400];
const EDGE_TEXT_BACKGROUND_COLOR = defaultTheme.colors.NEUTRAL[80];

export const NODE_STYLES: Stylesheet = {
  selector: "node",
  style: {
    // Adds a label to the node
    label: "data(id)",
    "font-size": "12px",

    // Node Styles
    shape: "ellipse",
    width: "80px",
    height: "80px",
    "background-color": (node: NodeSingular) =>
      node.data("type") === "SC" ? NODE_BACKGROUND_COLOR_SC : NODE_BACKGROUND_COLOR_FC,
    "border-color": (node: NodeSingular) => (node.data("type") === "SC" ? NODE_BORDER_COLOR_SC : NODE_BORDER_COLOR_FC),
    "border-width": 2,

    // Colors the node text and adds an outline
    color: NODE_TEXT_COLOR,
    "text-outline-color": NODE_TEXT_OUTLINE_COLOR,
    "text-outline-width": 0.75,
    "text-valign": "center",
    "text-halign": "center",
    "text-wrap": "ellipsis",
    "text-max-width": "75px",
  },
};

export const NODE_SELECTED_STYLES: Stylesheet = {
  selector: "node:selected",
  style: {
    // Node's have a different background color when selected
    "background-color": NODE_BACKGROUND_COLOR_SELECTED,
    "border-color": NODE_BORDER_COLOR_SELECTED,

    // Remove text wrap when selected
    "text-wrap": "none",
  },
};

export const EDGE_STYLES: Stylesheet = {
  selector: "edge",
  style: {
    // Edge Styles
    width: 3,
    "line-color": EDGE_COLOR,
    "target-arrow-color": EDGE_COLOR,
    "target-arrow-shape": "triangle",
    "curve-style": "bezier",
  },
};

export const EDGE_SELECTED_STYLES: Stylesheet = {
  selector: "edge:selected",
  style: {
    // Adds a label to the edge
    label: "data(label)",
    "font-size": "10px",

    // Colors the edge and arrow
    "line-color": EDGE_COLOR_SELECTED,
    "target-arrow-color": EDGE_COLOR_SELECTED,
    color: EDGE_TEXT_COLOR,

    // Adds a background color to the text
    "text-background-opacity": 1,
    "text-background-color": EDGE_TEXT_BACKGROUND_COLOR,
    "text-background-shape": "roundrectangle",

    // Adds a border around the text
    "text-border-color": EDGE_TEXT_COLOR,
    "text-border-width": 1,
    "text-border-opacity": 1,

    // Adds spacing between the text and border
    "text-background-padding": "3px",

    // Moves text z-index above all edges and nodes
    "z-compound-depth": "top",
  },
};
