import { SellerStatusFilter, SortFields } from "components/finance/CreditBlockList/CreditBlockItem";
import { SearchResults } from "base/OpenSearch/SearchService";
import { CreditBlockSearchService } from "../CreditBlockSearchService";
import { QueryDslQueryContainer } from "@deliverr/logistics-search-client/lib/src/entities/QueryDslQueryContainer";
import { ITEMS_PER_PAGE } from "components/finance/constants";

export interface SearchParcelsProps {
  page?: number;
}

const buildStatusFilter = (status: SellerStatusFilter): QueryDslQueryContainer => ({
  match: {
    status: `${status}`,
  },
});

const getCustomizedOpenSearchFilters = (filter: SellerStatusFilter): QueryDslQueryContainer[] => {
  if (!filter || filter === SellerStatusFilter.ALL) {
    return [];
  }
  const openSearchFilter = buildStatusFilter(filter);
  return [
    {
      bool: {
        filter: [openSearchFilter],
      },
    },
  ];
};

export const searchCreditBlock = async (
  token: Promise<string>,
  currentPage: number,
  searchTerm: string,
  sort: SortFields,
  filter: SellerStatusFilter
): Promise<SearchResults> => {
  const customizedOpenSearchFilters = getCustomizedOpenSearchFilters(filter);
  const creditBlockSearchService: CreditBlockSearchService = new CreditBlockSearchService({
    indexName: "billing_credit_block",
    searchConfig: {
      hitsPerPage: 1,
      highlightMatches: false,
    },
    token: await token,
  });
  const results: SearchResults = await creditBlockSearchService.execute({
    customizedOpenSearchFilters,
    searchTerm: searchTerm,
    sort,
    page: currentPage,
    pageSize: ITEMS_PER_PAGE,
  });
  return results;
};
