import { atom } from "recoil";
import { UserStateKey } from "./UserStateKey";
import { ROLES } from "types/Auth";

export interface UserState {
  userName: string;
  email: string;
  isAdmin: boolean;
  roles: ROLES[];
}

export const DEFAULT_USER_AUTH_STATE: UserState = {
  userName: "",
  email: "",
  isAdmin: false,
  roles: [],
};

export const userState = atom<UserState>({
  key: UserStateKey.USER,
  default: DEFAULT_USER_AUTH_STATE,
});
