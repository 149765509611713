import { useEffect, useState, useMemo } from "react";
import { CreditBlockItem, SellerStatusFilter, SortFields } from "./CreditBlockItem";
import { searchCreditBlock } from "./CreditBlockTable/actions/searchCreditBlock";
import { SortDir } from "types/SortDir";
import { useBearerToken } from "base/Auth/useBearerToken";
import { toast } from "@deliverr/ui";

export const useCreditBlock = () => {
  const [currentPageItems, setCurrentPageItems] = useState<CreditBlockItem[]>([]);
  const [sort, setSort] = useState<SortFields>({ fieldName: "totalBalance", direction: SortDir.DESC });
  const [filter, setFilter] = useState<SellerStatusFilter>(SellerStatusFilter.ALL);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalHits, setTotalHits] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const token = useBearerToken().accessToken;

  useEffect(() => {
    const fetchCreditBlocks = async () => {
      try {
        setLoading(true);
        const results = await searchCreditBlock(token, currentPage, searchTerm, sort, filter);
        setCurrentPageItems(results.hits);
        setTotalHits(results.response.nbHits);
        setTotalPages(results.response.nbPages);
      } catch (error) {
        toast.error(`Error fetching credit block data: ${error}`, { position: "top-right" });
      } finally {
        setLoading(false);
      }
    };

    fetchCreditBlocks();
  }, [token, currentPage, searchTerm, sort, filter]);

  const SELLER_STATUS_DISPLAY_TEXT = useMemo(
    () => ({
      [SellerStatusFilter.ALL]: "All Statuses",
      [SellerStatusFilter.ACTIVE]: "Active",
      [SellerStatusFilter.INACTIVE]: "Inactive",
      [SellerStatusFilter.SUSPENDED]: "Suspended",
    }),
    []
  );

  const statusFilterOptions = useMemo(
    () =>
      Object.entries(SELLER_STATUS_DISPLAY_TEXT).map(([key, value]) => ({
        label: value,
        value: key,
      })),
    [SELLER_STATUS_DISPLAY_TEXT]
  );

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (currentSearchTerm: string) => {
    setSearchTerm(currentSearchTerm.trim());
  };

  const handleStatusFilter = (e: any) => {
    setFilter(e.value);
  };

  const handleSort = () => {
    const newDirection = sort.direction === SortDir.ASC ? SortDir.DESC : SortDir.ASC;
    setSort({ fieldName: sort.fieldName, direction: newDirection });
  };

  return {
    handleStatusFilter,
    statusFilterOptions,
    statusFilterValue: { value: filter, label: SELLER_STATUS_DISPLAY_TEXT[filter] },
    onPageChange,
    currentPage,
    totalPages,
    totalHits,
    currentPageItems,
    searchTerm,
    setSearchTerm,
    sort,
    setSort,
    handleSort,
    handleSearch,
    loading,
  };
};
