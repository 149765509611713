import { Button, Card, ThemeProps, Input, Text, BoxReset } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Container } from "components/Container";
import { useAsnPackageLink } from "./useAsnPackageLink";
import { AttributionResults } from "../AttributionResults";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const AsnPackageLink = () => {
  const {
    targetAsnIdController,
    boxLabelController,
    onSubmitUpdateAsnPackageLink,
    resetAll,
    isSubmitting,
    previewResults,
    persistAttribution,
  } = useAsnPackageLink();

  return (
    <Container>
      <Card>
        <StyledForm onSubmit={onSubmitUpdateAsnPackageLink}>
          <Text size="bodyLarge" bold>
            Update ASN Package Link
          </Text>
          <Text size="body">
            This tool is used to assign a box to an ASN, which is usually necessary when a box is not scanned to a
            pallet at the crossdock
          </Text>

          <Input
            {...targetAsnIdController.field}
            helpText="The id of the ASN you'd like to link the box to"
            placeholder="88988989"
            label="ASN Id"
            required
            type="number"
            hasError={!!targetAsnIdController.fieldState.error}
            errorMessage={targetAsnIdController.fieldState.error?.message}
          />
          <Input
            {...boxLabelController.field}
            label="Box Label"
            helpText="The label of the box (CDSKU) you'd like to link to the ASN"
            required
            placeholder="CDSKU1234"
            hasError={!!boxLabelController.fieldState.error}
            errorMessage={boxLabelController.fieldState.error?.message}
          />
          {!previewResults ? (
            <BoxReset marginTop="S4" marginBottom="S4">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button style={{ marginRight: "16px" }} secondary onClick={resetAll} loading={isSubmitting}>
                  Cancel
                </Button>
                <Button type="submit" loading={isSubmitting}>
                  Preview
                </Button>
              </div>
            </BoxReset>
          ) : null}
        </StyledForm>
      </Card>
      {previewResults ? (
        <BoxReset marginTop="S4">
          <AttributionResults
            attributionResults={previewResults}
            onCancel={resetAll}
            onApprove={persistAttribution}
            isSubmitting={isSubmitting}
          />
        </BoxReset>
      ) : null}
    </Container>
  );
};
