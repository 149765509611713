import { NetworkLane } from "@deliverr/carrier-network-types";
import styled from "@emotion/styled";
import { RowModel, flexRender } from "@tanstack/react-table";
import { useCallback } from "react";
import { useNavigate } from "react-router";

const StyledBodyRow = styled.tr`
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
`;

const StyledBodyCell = styled.td`
  padding: 0.375rem 0.25rem;
  border-bottom: 1px solid #e0e0e0;
`;

interface LaneTableBodyProps {
  rowModel: RowModel<NetworkLane>;
}

export const LaneTableBody: React.FC<LaneTableBodyProps> = ({ rowModel }) => {
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (laneId: string) => {
      navigate(`/carrier-network/lanes/${laneId}`);
    },
    [navigate]
  );

  return (
    <tbody>
      {rowModel.rows.map((row) => (
        <StyledBodyRow key={row.id} onClick={() => handleRowClick(row.id)}>
          {row.getVisibleCells().map((cell) => (
            <StyledBodyCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</StyledBodyCell>
          ))}
        </StyledBodyRow>
      ))}
    </tbody>
  );
};
