import { SwitchMenu, Card } from "@deliverr/ui";
import { Container } from "components/Container";
import { useManageReceivingZones } from "./useManageReceivingZones";
import { ZONE_MANAGEMENT_VIEWS } from "./useManageReceivingZones";
import { AddFacilityZones } from "./AddFacilityZones";
import { ViewFacilityZones } from "./ViewFacilityZones";
import { AddMerchantZoneAssignments } from "./AddMerchantZoneAssignments";
import { ViewMerchantZoneAssignments } from "./ViewMerchantZoneAssignments";

export const ManageReceivingZones = () => {
  const { managementView, updateLocationView } = useManageReceivingZones();
  const componentToRender = () => {
    switch (managementView) {
      case ZONE_MANAGEMENT_VIEWS.ADD_FACILITY_ZONE:
        return <AddFacilityZones />;
      case ZONE_MANAGEMENT_VIEWS.LIST_DELETE_FACILITY_ZONES:
        return <ViewFacilityZones />;
      case ZONE_MANAGEMENT_VIEWS.ADD_MERCHANT_ZONE_ASSIGNMENTS:
        return <AddMerchantZoneAssignments />;
      case ZONE_MANAGEMENT_VIEWS.LIST_DELETE_MERCHANT_ZONE_ASSIGNMENTS:
        return <ViewMerchantZoneAssignments />;
      default:
        return null;
    }
  };
  return (
    <Container>
      <Card>
        <SwitchMenu
          menuItems={[
            { key: ZONE_MANAGEMENT_VIEWS.ADD_FACILITY_ZONE, label: ZONE_MANAGEMENT_VIEWS.ADD_FACILITY_ZONE },
            {
              key: ZONE_MANAGEMENT_VIEWS.LIST_DELETE_FACILITY_ZONES,
              label: ZONE_MANAGEMENT_VIEWS.LIST_DELETE_FACILITY_ZONES,
            },
            {
              key: ZONE_MANAGEMENT_VIEWS.ADD_MERCHANT_ZONE_ASSIGNMENTS,
              label: ZONE_MANAGEMENT_VIEWS.ADD_MERCHANT_ZONE_ASSIGNMENTS,
            },
            {
              key: ZONE_MANAGEMENT_VIEWS.LIST_DELETE_MERCHANT_ZONE_ASSIGNMENTS,
              label: ZONE_MANAGEMENT_VIEWS.LIST_DELETE_MERCHANT_ZONE_ASSIGNMENTS,
            },
          ]}
          activeMenuItem={managementView}
          handleOnClick={updateLocationView}
        />
        {componentToRender()}
      </Card>
    </Container>
  );
};
