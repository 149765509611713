import { toast } from "@deliverr/ui";
import { IssueGetResponse, IssueStatus, NonComplianceClient } from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { ActionsProps } from "./components/Actions/Actions";
import { handleNonComplianceError } from "utils/handleNonComplianceError";

export async function getIssueOtherDetails({
  issue,
  client,
  setIsLoading,
  refreshIssue,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
  setIsLoading: (isLoading: boolean) => void;
  refreshIssue: () => void;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
  actionProps: Required<Pick<ActionsProps, "markAsComplete">> | null;
}> {
  const issueOther = await client.getIssueOtherById({ issueId: issue.id });

  const issueDetails = {
    issue: issueOther.issue,
    images: issueOther.images,
    issueEntities: issueOther.issueEntities,
    zendeskTickets: issueOther.zendeskTickets,
    fields: [
      { type: FIELD_TYPE.UNIT_DISPOSITION, value: issueOther.unitDisposition },
      { type: FIELD_TYPE.DSKU, value: issueOther.dsku },
      { type: FIELD_TYPE.SUPPORT_NOTES, value: issueOther.supportNotes },
    ],
    reimbursementData: null,
    disputeData: null,
  };

  const completeIssueOther = async () => {
    setIsLoading(true);
    try {
      await client.completeIssueOther({
        issueId: issue.id,
      });
      await refreshIssue();
      setIsLoading(false);
      toast.success("Issue other completion success", { position: "top-right" });
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "getIssueOtherDetails.completeIssueOther" }, error as Error);
    }
  };

  const showIssueCompletion = IssueStatus.AWAITING_FACILITY_OR_SUPPORT === issue.status;

  return {
    issueDetails,
    actionProps: {
      markAsComplete: showIssueCompletion ? completeIssueOther : null,
    },
  };
}
