import { Button, ThemeProps, Input, Text } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useProductIdentificationAction } from "./useProductIdentificationAction";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const ProductIdentificationAction: React.FC<{
  onIdentifyProductWithBarcode: ((params: { dsku: string; barcode: string }) => Promise<void>) | null;
  onIdentifyProductWithoutBarcode: ((params: { dsku: string }) => Promise<void>) | null;
  onFailedToIdentify: () => Promise<void>;
}> = ({ onIdentifyProductWithBarcode, onIdentifyProductWithoutBarcode, onFailedToIdentify }) => {
  const requireBarcode = !!onIdentifyProductWithBarcode;
  const { dsku, barcode, onSubmit, isSubmitting, onFail } = useProductIdentificationAction({
    onFailedToIdentify,
    onIdentifyProduct: onIdentifyProductWithBarcode || onIdentifyProductWithoutBarcode,
    requireBarcode,
  });

  return (
    <StyledForm onSubmit={onSubmit}>
      <Text size="bodyLarge" bold>
        Product Identification
      </Text>
      <Text size="body">
        This tool is used to identify the product for either MISSING_BARCODE or UNKNOWN_BARCODE issue types.
      </Text>

      <Input
        {...dsku.field}
        helpText="The dsku of the product identified"
        placeholder=""
        label="DSKU"
        required
        type="string"
        hasError={!!dsku.fieldState.error}
        errorMessage={dsku.fieldState.error?.message}
      />

      {requireBarcode && (
        <Input
          {...barcode.field}
          helpText="The barcode of the product identified"
          placeholder=""
          label="Barcode"
          required
          type="string"
          hasError={!!barcode.fieldState.error}
          errorMessage={barcode.fieldState.error?.message}
        />
      )}

      <Button type="submit" loading={isSubmitting}>
        Submit Product Identification
      </Button>
      <Text>
        If you can't identify the product and flag it as such, the units will be disposed of. Please only do this if
        you're sure.
      </Text>
      <Button appearance={"DANGER"} loading={isSubmitting} onClick={onFail}>
        Can't identify the product
      </Button>
    </StyledForm>
  );
};
