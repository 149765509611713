import { Route } from "react-router-dom";
import { Path } from "paths";
import { NavBar } from "components/NavBar";
import { Container } from "components/Container";
import { CreditBlock } from "./CreditBlockList/CreditBlock";
import { CreditBlockDetail } from "./CreditBlockDetail/CreditBlockDetail";

export const financeHeaderRoutes = [
  <Route key={"financeHeader"} path={Path.financeList} element={<NavBar breadcrumbs={[{ pageName: "Finance" }]} />} />,
  <Route
    key={"creditBlockListHeader"}
    path={Path.CreditBlockList}
    element={<NavBar breadcrumbs={[{ pageName: "Finance" }, { pageName: "Credit Blocks" }]} />}
  />,
  <Route
    key={"creditBlockDetailsHeader"}
    path={Path.CreditBlockDetail}
    element={
      <NavBar
        breadcrumbs={[{ pageName: "Finance" }, { pageName: "Credit Blocks" }, { pageName: "Credit Block Detail" }]}
      />
    }
  />,
];

export const creditBlockRoutes = [
  <Route
    key={"finance"}
    path={Path.financeList}
    element={<Container>Select a tool from the dropdown on the left hand side</Container>}
  />,
  <Route key={"creditBlockList"} path={Path.CreditBlockList} element={<CreditBlock />} />,
  <Route key={"creditBlockList"} path={Path.CreditBlockList} element={<CreditBlock />} />,
  <Route key={"creditBlockDetails"} path={Path.CreditBlockDetail} element={<CreditBlockDetail />} />,
];
