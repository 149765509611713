import { useState } from "react";
import { useForm, useController, SubmitHandler } from "react-hook-form";
import { handleNonComplianceError } from "utils/handleNonComplianceError";

type FormValues = {
  reason: string;
};

export const useIssueCancellationAction = (cancelIssue: (reason: string) => Promise<void>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({});

  const reason = useController({
    name: "reason",
    defaultValue: "",
    control,
    rules: {
      required: true,
      maxLength: 50,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsSubmitting(true);
    try {
      await cancelIssue(data.reason);
      setIsSubmitting(false);
      reset();
      return;
    } catch (error) {
      // some better error handling of 4** errors.
      setIsSubmitting(false);
      handleNonComplianceError({ fn: "useIssueCancellationTool.onSubmit" }, error as Error);
    }
  };

  return {
    reason,
    onSubmit: handleSubmit(onSubmit),
    isSubmitting,
  };
};
