import { DefaultTheme, Text, TextProps } from "@deliverr/ui";
import styled from "@emotion/styled";

const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLabel = styled(Text)<TextProps, DefaultTheme>`
  ${({ theme }) => `
    margin-right: ${theme.spacing.S1};
  `}
`;

interface FieldProps {
  label: string;
  value?: string | number | null;
}

export const Field: React.FC<FieldProps> = ({ label, value }) => {
  return (
    <FieldContainer>
      <StyledLabel bold>{label}:</StyledLabel>
      <Text appearance="INFO">{value === undefined || value === null ? "N/A" : value}</Text>
    </FieldContainer>
  );
};
