import {
  ExpiredProductUnitDisposition,
  IssueGetResponse,
  IssueStatus,
  NonComplianceClient,
} from "@deliverr/non-compliance-client";
import { DateFormat, DisplayDate } from "../components/DisplayDate";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";

import { toast } from "@deliverr/ui";
import { handleNonComplianceError } from "utils/handleNonComplianceError";
import { ActionsProps } from "./components/Actions/Actions";

export async function getExpiredProductDetails({
  issue,
  client,
  setIsLoading,
  refreshIssue,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
  setIsLoading: (isLoading: boolean) => void;
  refreshIssue: () => void;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
  actionProps: Pick<ActionsProps, "dispose" | "manualActionRequired" | "markAsComplete"> | null;
}> {
  const expiredProductIssue = await client.getIssueExpiredProductById({ issueId: issue.id });
  const localExpirationDate = new Date(expiredProductIssue.expirationDate);
  const utcExpirationDate = new Date(
    localExpirationDate.getUTCFullYear(),
    localExpirationDate.getUTCMonth(),
    localExpirationDate.getUTCDate()
  );

  const issueDetails = {
    issue: expiredProductIssue.issue,
    images: expiredProductIssue.images,
    issueEntities: expiredProductIssue.issueEntities,
    zendeskTickets: expiredProductIssue.zendeskTickets,
    fields: [
      { type: FIELD_TYPE.UNIT_DISPOSITION, value: expiredProductIssue.unitDisposition },
      { type: FIELD_TYPE.DSKU, value: expiredProductIssue.dsku },
      { type: FIELD_TYPE.BARCODE, value: expiredProductIssue.barcode },
      {
        type: FIELD_TYPE.EXPIRATION_DATE,
        value: <DisplayDate date={utcExpirationDate} format={DateFormat.YearDayMonth} />,
      },
      { type: FIELD_TYPE.SUPPORT_NOTES, value: expiredProductIssue.supportNotes },
    ],
    reimbursementData: null,
    disputeData: null,
  };

  const setDisposition = async (disposition: ExpiredProductUnitDisposition) => {
    setIsLoading(true);
    try {
      if (expiredProductIssue) {
        await client.approveProductDispositionForExpiredProduct({
          issueId: expiredProductIssue.issue.id,
          disposition,
        });
        await refreshIssue();
        setIsLoading(false);
        toast.success("Unit disposition updated", { position: "top-right" });
      } else {
        setIsLoading(false);
        toast.error("No issue present", { position: "top-right" });
      }
    } catch (error) {
      handleNonComplianceError({ fn: "useExpiredProductTool.setDisposition" }, error as Error);
      setIsLoading(false);
    }
  };

  const markAsComplete = async () => {
    setIsLoading(true);
    try {
      if (expiredProductIssue) {
        await client.completeIssueExpiredProduct({ issueId: expiredProductIssue.issue.id });
        await refreshIssue();
        setIsLoading(false);
        toast.success("Issue marked as complete", { position: "top-right" });
      } else {
        setIsLoading(false);
        toast.error("No issue present", { position: "top-right" });
      }
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "useExpiredProductTool.markAsComplete" }, error as Error);
    }
  };

  const showDispositionAction =
    expiredProductIssue.issue.status === IssueStatus.AWAITING_SUPPORT && expiredProductIssue.unitDisposition === null;
  const showMarkAsCompleteAction =
    expiredProductIssue.unitDisposition === ExpiredProductUnitDisposition.OTHER &&
    expiredProductIssue.issue.status !== IssueStatus.RESOLVED;

  return {
    issueDetails,
    actionProps: {
      dispose: showDispositionAction ? () => setDisposition(ExpiredProductUnitDisposition.DISPOSE) : null,
      manualActionRequired: showDispositionAction ? () => setDisposition(ExpiredProductUnitDisposition.OTHER) : null,
      markAsComplete: showMarkAsCompleteAction ? markAsComplete : null,
    },
  };
}
