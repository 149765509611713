import { Button, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { ActionsProps } from "../Actions";

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const ReviewProductDimensions: React.FC<
  Pick<ActionsProps, "approveProductDimensions" | "rejectProductDimensions" | "isLoading">
> = ({ approveProductDimensions, rejectProductDimensions, isLoading }) => {
  if (!approveProductDimensions || !rejectProductDimensions) {
    return null;
  }

  return (
    <StyledForm>
      <Text size="bodyLarge" bold>
        Review Product Dimensions
      </Text>
      <Text size="body">
        Please review the newly captured product dimensions with the merchant. After review please select the
        appropriate action below.
      </Text>

      <Button onClick={approveProductDimensions} loading={isLoading}>
        Merchant Approves Dimensions
      </Button>

      <Button onClick={rejectProductDimensions} loading={isLoading} appearance={"DANGER"}>
        Merchant Rejects Dimensions
      </Button>
    </StyledForm>
  );
};
