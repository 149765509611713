import { useNavigate } from "react-router-dom";
import { toast } from "@deliverr/ui";
import { sortCodeMappingState } from "components/sortation/sortationState";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { isEmpty } from "lodash";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { useAsync } from "react-use";
import { useRecoilState } from "recoil";

export const useSortCodeSelector = () => {
  const [sortCodes, setSortCodes] = useState<string[]>([]);

  const [sortCodeMapping, setSortCodeMapping] = useRecoilState(sortCodeMappingState);
  const { sortationCenterId, shippingMethod } = useParams() as { sortationCenterId: string; shippingMethod: string };
  const { sortationApiClient } = useClientsWithAuth();
  const navigate = useNavigate();

  const state = useAsync(async () => {
    // On refresh, we lose sort code mappings. Fetch them again
    if (isEmpty(sortCodeMapping)) {
      try {
        const fetchedSortCodeMapping = await sortationApiClient.getSortCodeMapping();
        setSortCodeMapping(fetchedSortCodeMapping);

        if (fetchedSortCodeMapping[sortationCenterId] && fetchedSortCodeMapping[sortationCenterId][shippingMethod]) {
          setSortCodes(fetchedSortCodeMapping[sortationCenterId][shippingMethod]);
        }

        return;
      } catch (err) {
        toast.error("Unable to retrieve sort code mapping", { position: "top-right" });
        return;
      }
    }

    if (sortCodeMapping[sortationCenterId] && sortCodeMapping[sortationCenterId][shippingMethod]) {
      setSortCodes(sortCodeMapping[sortationCenterId][shippingMethod]);
    }
  });

  const handleCancel = useCallback(() => {
    navigate("/sortation/sort-code-manager");
  }, [navigate]);

  return {
    state,
    sortationCenterId,
    shippingMethod,
    sortCodes,
    handleCancel,
  };
};
