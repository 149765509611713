import { FC } from "react";
import { GenericWarningModal } from "./GenericWarningModal";

export enum SupportPortalModal {
  GENERIC_WARNING_CONFIRMATION_MODAL = "GENERIC_WARNING_CONFIRMATION_MODAL",
}

export const SUPPORT_PORTAL_MODAL_MAP: Record<SupportPortalModal, FC<any>> = {
  [SupportPortalModal.GENERIC_WARNING_CONFIRMATION_MODAL]: GenericWarningModal,
};
