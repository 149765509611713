import { useState } from "react";
import { useController, useForm, SubmitHandler } from "react-hook-form";
import { useClientsWithAuth } from "../../../../../hooks/auth/useClientsWithAuth";
import { IssueEntityGetResponse, IssueType } from "@deliverr/non-compliance-client";
import { toast } from "@deliverr/ui";
import { handleNonComplianceError } from "utils/handleNonComplianceError";
import { useSupportPortalModal } from "components/Modal/useSupportPortalModal";
import { SupportPortalModal } from "components/Modal/SupportPortalModalMap";

type FormValues = {
  reimbursementUnitCost: number;
};

interface UseReimbursementFormParams {
  issueId: string;
  issueType: IssueType;
  issueEntities: Array<IssueEntityGetResponse>;
}

// Support needs to double confirm and submit a Jira ticket for every reimbursement over this amount
const REIMBURSEMENT_WARNING_AMOUNT = 250;

export const useReimbursementForm = ({ issueId, issueType, issueEntities }: UseReimbursementFormParams) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({});
  const { nonComplianceClient } = useClientsWithAuth();
  const { showModal, hideAllModals } = useSupportPortalModal();

  const reimbursementUnitCost = useController({
    name: "reimbursementUnitCost",
    defaultValue: 0,
    control,
    rules: {
      required: true,
      validate: (value) => {
        if (value <= 0) {
          return "Must be a positive value";
        }
        return true;
      },
    },
  });

  const submitReimbursement = async (reimbursementUnitCost: number) => {
    setIsSubmitting(true);
    try {
      await nonComplianceClient.createIssueDamagedProductReimbursement({
        issueId,
        reimbursementUnitCost,
      });
      toast.success("Reimbursement submitted", { position: "top-right" });
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);
      reset();
      handleNonComplianceError({ fn: "useReimbursementForm.submitReimbursement" }, error as Error);
    }
  };

  const submitReimbursementForm: SubmitHandler<FormValues> = async ({ reimbursementUnitCost }) => {
    // I think we can use this component for other issue types in the future this is just a gate for now.
    if (issueType !== IssueType.DAMAGED_PRODUCT) {
      toast.error(`Can't submit reimbursement for ${issueType}`, { position: "top-right" });
    }

    const numberOfUnitsAffected = issueEntities.reduce((acc, ie) => acc + (ie.numberOfUnits || 0), 0);
    const totalReimbursementAmount = numberOfUnitsAffected * reimbursementUnitCost;

    if (totalReimbursementAmount > REIMBURSEMENT_WARNING_AMOUNT) {
      showModal(SupportPortalModal.GENERIC_WARNING_CONFIRMATION_MODAL, {
        title: `Reimbursing $${totalReimbursementAmount}!`,
        text: `Exceeded reimbursement limit of $${REIMBURSEMENT_WARNING_AMOUNT}. Are you sure you want to proceed?`,
        onConfirm: async () => {
          hideAllModals();
          await submitReimbursement(reimbursementUnitCost);
        },
        onCancel: () => {
          hideAllModals();
        },
      });
      return;
    }

    await submitReimbursement(reimbursementUnitCost);
  };

  return {
    reimbursementUnitCost,
    isSubmitting,
    submitReimbursement: handleSubmit(submitReimbursementForm),
  };
};
