import { Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const SelectSortCodeContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing.S4} 0;
`
);

const StyledList = styled.ul<ThemeProps>(
  ({ theme }) => `
  list-style: disc;
  padding-left: ${theme.spacing.S4};
 `
);

const StyledLink = styled(Link)<ThemeProps>(
  ({ theme }) => `
    color: ${theme.colors.BLUE[300]};
    &:hover {
      color: ${theme.colors.BLUE[500]};
      text-decoration: underline;
    }
  `
);

interface SelectSortCodeProps {
  sortCodes: string[];
  sortationCenterId: string;
  shippingMethod: string;
}

export const SelectSortCode = ({ sortCodes, sortationCenterId, shippingMethod }: SelectSortCodeProps) => {
  return (
    <SelectSortCodeContainer>
      {sortCodes.length > 0 ? (
        <>
          <Text appearance="INFO">Select a sort code</Text>
          <StyledList>
            {sortCodes.map((sortCode) => {
              return (
                <li key={sortCode}>
                  <StyledLink to={`/sortation/sort-code-manager/${sortationCenterId}/${shippingMethod}/${sortCode}`}>
                    {sortCode}
                  </StyledLink>
                </li>
              );
            })}
          </StyledList>
        </>
      ) : (
        <Text appearance="INFO" size="bodyLarge">
          ❌ No sort codes for the sortation center and shipping method combination. Please create a new sort code.
        </Text>
      )}
    </SelectSortCodeContainer>
  );
};
