import { toast } from "@deliverr/ui";
import {
  IllegibleExpirationUnitDisposition,
  IssueGetResponse,
  IssueStatus,
  NonComplianceClient,
} from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { ActionsProps } from "./components/Actions/Actions";
import { handleNonComplianceError } from "utils/handleNonComplianceError";

export async function getIllegibleExpirationDetails({
  issue,
  client,
  setIsLoading,
  refreshIssue,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
  setIsLoading: (isLoading: boolean) => void;
  refreshIssue: () => void;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
  actionProps: Pick<ActionsProps, "dispose" | "manualActionRequired" | "markAsComplete"> | null;
}> {
  const illegibleExpirationIssue = await client.getIssueIllegibleExpirationById({ issueId: issue.id });

  const issueDetails = {
    issue: illegibleExpirationIssue.issue,
    images: illegibleExpirationIssue.images,
    issueEntities: illegibleExpirationIssue.issueEntities,
    zendeskTickets: illegibleExpirationIssue.zendeskTickets,
    fields: [
      { type: FIELD_TYPE.UNIT_DISPOSITION, value: illegibleExpirationIssue.unitDisposition },
      { type: FIELD_TYPE.BARCODE, value: illegibleExpirationIssue.barcode },
      { type: FIELD_TYPE.DSKU, value: illegibleExpirationIssue.dsku },
    ],
    reimbursementData: null,
    disputeData: null,
  };

  const markAsComplete = async () => {
    setIsLoading(true);
    try {
      if (illegibleExpirationIssue) {
        await client.completeIssueIllegibleExpiration({ issueId: illegibleExpirationIssue.issue.id });
        await refreshIssue();
        setIsLoading(false);
        toast.success("Issue marked as complete", { position: "top-right" });
      } else {
        setIsLoading(false);
        toast.error("No issue present", { position: "top-right" });
      }
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "useIllegibleExpirationTool.markAsComplete" }, error as Error);
    }
  };

  const setDisposition = async (disposition: IllegibleExpirationUnitDisposition) => {
    setIsLoading(true);
    try {
      if (illegibleExpirationIssue) {
        await client.approveProductDispositionForIllegibleExpiration({
          issueId: illegibleExpirationIssue.issue.id,
          disposition,
        });
        await refreshIssue();
        setIsLoading(false);
        toast.success("Unit disposition updated", { position: "top-right" });
      } else {
        setIsLoading(false);
        toast.error("No issue present", { position: "top-right" });
      }
    } catch (error) {
      handleNonComplianceError({ fn: "useIllegibleExpirationTool.setDisposition" }, error as Error);
      setIsLoading(false);
    }
  };

  const showDispositionAction =
    illegibleExpirationIssue.issue.status === IssueStatus.AWAITING_SUPPORT &&
    illegibleExpirationIssue.unitDisposition === null;
  const showMarkAsCompleteAction =
    illegibleExpirationIssue.unitDisposition === IllegibleExpirationUnitDisposition.OTHER;

  return {
    issueDetails,
    actionProps: {
      dispose: showDispositionAction ? () => setDisposition(IllegibleExpirationUnitDisposition.DISPOSE) : null,
      manualActionRequired: showDispositionAction
        ? () => setDisposition(IllegibleExpirationUnitDisposition.OTHER)
        : null,
      markAsComplete: showMarkAsCompleteAction ? markAsComplete : null,
    },
  };
}
