import { IconV2Types } from "@deliverr/ui/lib-esm/IconV2";
import { Appearance } from "@deliverr/ui/lib-esm/shared";
import React, { FC } from "react";
import { Link, useMatch } from "react-router-dom";
import { useSidebarContext } from "./SidebarContext";
import { SidebarItemContainer } from "./SidebarItemContainer";
import { SidebarItemContent } from "./SidebarItemContent";

export interface SidebarPageItemProps {
  path: string;
  navigationPath?: string;
  title: string;
  icon?: IconV2Types;
  logo?: React.ReactNode;
  secondary?: boolean;
  attentionIcon?: Appearance;
  testId?: string;
  active?: boolean;
  itemCount?: number;
}

/**
 * Link to a page. Styled as active if the given route (or sub-route) is a match for the current route.
 */
export const SidebarPageItem: FC<SidebarPageItemProps> = ({
  path,
  navigationPath,
  title,
  icon,
  logo,
  secondary,
  attentionIcon,
  testId,
  active: activeProp,
  itemCount,
}) => {
  const match = useMatch({ path, end: true });
  const { mode, onItemClick } = useSidebarContext();
  const isActive = activeProp ?? !!match;

  return (
    <SidebarItemContainer active={isActive} mode={mode}>
      <Link
        aria-current={isActive ? "page" : undefined}
        to={navigationPath ?? (path as string)}
        data-testid={testId}
        onClick={onItemClick}
      >
        <SidebarItemContent
          icon={icon}
          logo={logo}
          description={title}
          secondary={secondary}
          active={isActive}
          attentionIcon={attentionIcon}
          itemCount={itemCount}
        />
      </Link>
    </SidebarItemContainer>
  );
};
