import { NetworkLane } from "@deliverr/commons-objects/lib/NetworkLane";
import styled from "@emotion/styled";
import { HeaderGroup, flexRender } from "@tanstack/react-table";

const StyledHeaderRow = styled.tr`
  border-bottom: 1px solid #e0e0e0;
  &:hover {
    cursor: pointer;
  }
`;

const StyledHeaderCell = styled.th`
  padding: 0.375rem 0.25rem;
  text-align: start;
  border-bottom: 1px solid #e0e0e0;
`;

interface LaneTableHeaderProps {
  headerGroups: HeaderGroup<NetworkLane>[];
}

export const LaneTableHeader: React.FC<LaneTableHeaderProps> = ({ headerGroups }) => {
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <StyledHeaderRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <StyledHeaderCell key={header.id}>
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            </StyledHeaderCell>
          ))}
        </StyledHeaderRow>
      ))}
    </thead>
  );
};
