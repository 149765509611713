import styled from "@emotion/styled";

export const SplitInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > * {
    flex: 1;
  }
  button {
    margin-top: 1rem;
  }
`;
