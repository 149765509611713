import { GetNetworkLaneResponse } from "@deliverr/carrier-network-types";
import { toast } from "@deliverr/ui";
import { useClientsWithAuth } from "hooks/auth/useClientsWithAuth";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAsync } from "react-use";

export const useNetworkLaneDetails = () => {
  const [laneResponse, setLaneResponse] = useState<GetNetworkLaneResponse>();
  const [isEditingLane, setIsEditingLane] = useState(false);

  const { laneId } = useParams() as {
    laneId: string;
  };
  const navigate = useNavigate();

  const { carrierNetworkClient } = useClientsWithAuth();

  const fetchLane = useCallback(async () => {
    try {
      // Fetch lane details
      const getNetworkLaneResp = await carrierNetworkClient.getLane(laneId);

      setLaneResponse(getNetworkLaneResp);
    } catch (err) {
      toast.error(`Unable to retrieve the network lane for id:${laneId}.`, {
        position: "top-right",
      });
    }
  }, [laneId, carrierNetworkClient]);

  // Fetch lane on mount
  const { loading } = useAsync(async () => {
    await fetchLane();
  }, [laneId]);

  const handleNodeDetailsClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    navigate(`/carrier-network/nodes/${event.currentTarget.value}`);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const toggleLaneEditor = () => {
    setIsEditingLane(!isEditingLane);
  };

  const handleLaneEditorOnFinish = async () => {
    await fetchLane();

    setIsEditingLane(!isEditingLane);
  };

  return {
    // State Data
    loading,
    isEditingLane,
    lane: laneResponse?.lane,
    originNode: laneResponse?.originNode,
    destinationNode: laneResponse?.destinationNode,

    // Click Handlers
    handleNodeDetailsClick,
    handleBackClick,
    toggleLaneEditor,
    handleLaneEditorOnFinish,
  };
};
