import React from "react";
import { Button, Text, DefaultTheme, Stack } from "@deliverr/ui";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";

const MainContainer = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    background-color: ${theme.colors.NEUTRAL[40]};
  `
);

export const Logout: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <MainContainer>
      <Stack>
        <Text>Your account does not have the necessary permissions to access Support Portal.</Text>
        <Text>Contact your account creator or the Receiving and Non-Compliance team for help.</Text>
        <Button onClick={() => logout({ returnTo: window.location.origin })}>Logout</Button>
      </Stack>
    </MainContainer>
  );
};
