import { toast } from "@deliverr/ui";
import { IssueGetResponse, NonComplianceClient, ShortShipReasonCode } from "@deliverr/non-compliance-client";
import { FIELD_TYPE, IssueSpecificDetailsProps } from "./components/IssueSpecificDetails/IssueSpecificDetails";
import { ActionsProps } from "./components/Actions/Actions";
import { handleNonComplianceError } from "utils/handleNonComplianceError";

export async function getShortShipDetails({
  issue,
  client,
  setIsLoading,
  refreshIssue,
}: {
  issue: IssueGetResponse;
  client: NonComplianceClient;
  setIsLoading: (isLoading: boolean) => void;
  refreshIssue: () => void;
}): Promise<{
  issueDetails: IssueSpecificDetailsProps;
  actionProps: Pick<ActionsProps, "setReasonCode"> | null;
}> {
  const shortShipIssue = await client.getIssueShortShipById({ issueId: issue.id });

  const issueDetails = {
    issue: shortShipIssue.issue,
    images: shortShipIssue.images,
    issueEntities: shortShipIssue.issueEntities,
    zendeskTickets: shortShipIssue.zendeskTickets,
    fields: [
      { type: FIELD_TYPE.REASON_CODE, value: shortShipIssue.reasonCode },
      { type: FIELD_TYPE.DSKU, value: shortShipIssue.dsku },
      { type: FIELD_TYPE.SUPPORT_NOTES, value: shortShipIssue.supportNotes },
    ],
    reimbursementData: null,
    disputeData: null,
  };

  const setReasonCode = async (params: { reasonCode: ShortShipReasonCode; supportNotes: string | null }) => {
    setIsLoading(true);
    try {
      await client.identifyReasonForShortShip({
        issueId: issue.id,
        reasonCode: params.reasonCode,
        supportNotes: params.supportNotes,
      });
      await refreshIssue();
      setIsLoading(false);
      toast.success("Issue short ship reason code submitted", { position: "top-right" });
      return;
    } catch (error) {
      setIsLoading(false);
      handleNonComplianceError({ fn: "getMissingBarcodeDetails.identifyProduct" }, error as Error);
    }
  };

  return {
    issueDetails,
    actionProps: {
      setReasonCode: !shortShipIssue.reasonCode ? setReasonCode : null,
    },
  };
}
