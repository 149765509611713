import React from "react";
import { SUPPORT_PORTAL_MODAL_MAP, SupportPortalModal } from "./SupportPortalModalMap";
import { useRecoilValue } from "recoil";
import { visibleSupportPortalModalValue } from "./SupportPortalModalState";

export const SupportPortalModalRenderer: React.FC = () => {
  const visibleModal = useRecoilValue(visibleSupportPortalModalValue);
  if (!visibleModal) {
    return null;
  }
  const Modal = SUPPORT_PORTAL_MODAL_MAP[visibleModal.type as SupportPortalModal];
  return <Modal {...visibleModal.props} />;
};
