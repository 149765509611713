import { Button, Card, Input, Text, ThemeProps } from "@deliverr/ui";
import { useReimbursementForm } from "./useReimbursementForm";
import { IssueEntityGetResponse, IssueType } from "@deliverr/non-compliance-client";
import styled from "@emotion/styled";
import { CardHeading } from "components/nonCompliance/components/CardHeading";

interface ReimbursementFormProps {
  issueId: string;
  issueType: IssueType;
  issueEntities: Array<IssueEntityGetResponse>;
}

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

export const ReimbursementForm = ({ issueId, issueType, issueEntities }: ReimbursementFormProps) => {
  const { reimbursementUnitCost, submitReimbursement, isSubmitting } = useReimbursementForm({
    issueId,
    issueType,
    issueEntities,
  });
  return (
    <Card>
      <CardHeading>Reimbursement</CardHeading>
      <Text size="body">Once investigation is complete use this form to issue a reimbursement to the merchant.</Text>
      <br />
      <StyledForm onSubmit={submitReimbursement}>
        <Input
          {...reimbursementUnitCost.field}
          helpText="Must be a positive value"
          placeholder={"0"}
          label="Reimbursement Unit Cost"
          required
          type="string"
          hasError={!!reimbursementUnitCost.fieldState.error}
          errorMessage={reimbursementUnitCost.fieldState.error?.message}
        />
        <Button type="submit" loading={isSubmitting}>
          Submit Reimbursement
        </Button>
      </StyledForm>
    </Card>
  );
};
