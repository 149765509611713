import { Button, Card, FileInput, IconV2, Input, Text, ThemeProps } from "@deliverr/ui";
import { Container } from "components/Container";
import { useSortCodeEditor } from "./useSortCodeEditor";
import { LoadingSpinner } from "components/LoadingSpinner";
import styled from "@emotion/styled";
import { ZipCodeTextArea } from "./ZipCodeTextArea";

const CardTitleContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
  `
);

const TextGroup = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing.S4};
`
);

const ButtonGroup = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: ${theme.spacing.S4};
`
);

const StyledButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
  margin-right: ${theme.spacing.S2};
`
);

export const SortCodeEditor = () => {
  const {
    state,
    isNewSortCode,
    zipCodes,
    errorMessage,
    reason,
    sortationCenterId,
    shippingMethod,
    sortCode,
    setReason,
    handleSetZipCodes,
    handleDownload,
    handleUpload,
    handleSave,
    handleDelete,
    handleBack,
  } = useSortCodeEditor();

  return (
    <Container>
      <Card>
        <LoadingSpinner loading={state.loading}>
          <CardTitleContainer>
            <TextGroup>
              <Text size="bodyLarge" bold>
                {isNewSortCode ? "Create New" : "Edit"} Sort Code
              </Text>
              <Text size="body">Please edit zip codes for the sort code and click save.</Text>
            </TextGroup>
            <Button appearance={"DANGER"} disabled={isNewSortCode || !reason.length} onClick={handleDelete}>
              Delete
            </Button>
          </CardTitleContainer>
          <TextGroup>
            <Text appearance="INFO">Sortation Center</Text>
            <Text>{sortationCenterId}</Text>
          </TextGroup>
          <TextGroup>
            <Text appearance="INFO">Shipping Method</Text>
            <Text>{shippingMethod}</Text>
          </TextGroup>
          <TextGroup>
            <Text appearance="INFO">Sort Code</Text>
            <Text>{sortCode}</Text>
          </TextGroup>
          <hr />
          <ZipCodeTextArea zipCodes={zipCodes} errorMessage={errorMessage} handleSetZipCodes={handleSetZipCodes} />
          <StyledButton onClick={handleDownload}>
            <IconV2 type="download" /> Download CSV
          </StyledButton>
          <FileInput
            accept=".csv"
            label={
              <span>
                <IconV2 type="file" /> Upload CSV
              </span>
            }
            onChange={handleUpload}
          />
          <TextGroup>
            <Input
              label="Please enter a reason for sort code change"
              placeholder="We needed to change the zip codes for this sort code because..."
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </TextGroup>
          <hr />
          <ButtonGroup>
            <Button secondary onClick={handleBack}>
              Back
            </Button>
            <Button disabled={!reason.length || !zipCodes.length || errorMessage.length > 0} onClick={handleSave}>
              Save
            </Button>
          </ButtonGroup>
        </LoadingSpinner>
      </Card>
    </Container>
  );
};
