import { ShippingMethod } from "@deliverr/carrier-network-types";
import { toast } from "@deliverr/ui";
import { useState } from "react";
import { useAsync } from "react-use";
import { useClientsWithAuth } from "../../../hooks/auth/useClientsWithAuth";

export const useFetchShippingMethods = () => {
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>([]);

  const { carrierNetworkClient } = useClientsWithAuth();

  const { loading } = useAsync(async () => {
    try {
      const methods = await carrierNetworkClient.getShippingMethods();
      setShippingMethods(methods.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (err) {
      toast.error("Unable to retrieve shipping methods", { position: "top-right" });
    }
  }, []);

  return { shippingMethods, loading };
};
