import { DefaultTheme, Pill } from "@deliverr/ui";
import styled from "@emotion/styled";

export const ActiveLanePill = styled(Pill)<{ isActive: boolean }, DefaultTheme>`
  ${({ theme, isActive = false }) => `
        padding: ${theme.spacing.S1} ${theme.spacing.S2};
        font-size: ${theme.font.size.F1};
        background-color: ${theme.colors[isActive ? "GREEN" : "RED"][300]};
        color: white;
    `}
`;
