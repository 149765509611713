import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { SidebarMode, useSidebarContext } from "./SidebarContext";
import { sidebarTheme } from "./SidebarTheme";

const List = styled.ul<{ mode: SidebarMode }, DefaultTheme>(
  ({ mode }) => `
    margin: ${sidebarTheme.sectionMargin} 0;
    padding: 0;

    > li {
      margin: ${sidebarTheme.item.margin[mode]};

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `
);

/**
 * List of sidebar items, styled with standard spacing.
 */
export const SidebarList = ({ children }: { children: React.ReactNode }) => {
  const { mode } = useSidebarContext();

  return <List mode={mode}>{children}</List>;
};
