import { NetworkLane } from "@deliverr/carrier-network-types";
import { FormGroup, Input, Select, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import React from "react";
import { LoadingSpinner } from "../../../LoadingSpinner";
import { NetworkButton, NetworkButtonGroup, NetworkCard, NetworkTitle } from "../../components";
import { BusinessDayFormOptions } from "./BusinessDay";
import { useNetworkLaneEditor } from "./useNetworkLaneEditor";

const StyledTextContent = styled.div<ThemeProps>`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing.S2};
  `}
`;

const StyledForm = styled.form<ThemeProps>`
  & > *:not(:last-child) {
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.S4};
  }
`;

interface EditNetworkLaneProps {
  lane: NetworkLane;
  handleBackClick: () => void;
  onFinish: () => void;
}

export const NetworkLaneEditor: React.FC<EditNetworkLaneProps> = ({ lane, handleBackClick, onFinish }) => {
  const { loading, onSubmit, controllers, isSubmitting, shippingMethods } = useNetworkLaneEditor(lane, onFinish);

  return (
    <NetworkCard>
      <LoadingSpinner loading={loading} text="Loading Lane Editor">
        <StyledTextContent>
          <NetworkTitle size="h2">Network Lane Editor</NetworkTitle>
          <Text size="body">Lane ID: {lane.id}</Text>
          <Text size="body">Origin Node: {lane.originNodeId}</Text>
          <Text size="body">Destination Node: {lane.destinationNodeId}</Text>
        </StyledTextContent>
        <StyledForm onSubmit={onSubmit}>
          {/* Lane Description */}
          <FormGroup>
            <Input
              {...controllers.description.field}
              label="Lane Description"
              hasError={!!controllers.description.fieldState.error}
            />
            {controllers.description.fieldState.error && (
              <Text size="caption" appearance="DANGER">
                Please enter a valid value for lane description
              </Text>
            )}
          </FormGroup>

          {/* Is Active Lane */}
          <FormGroup>
            <Select
              {...controllers.isActive.field}
              label="Active Lane"
              options={[
                {
                  value: true,
                  label: "Yes",
                },
                {
                  value: false,
                  label: "No",
                },
              ]}
              hasError={!!controllers.isActive.fieldState.error}
            />
            {controllers.isActive.fieldState.error && (
              <Text size="caption" appearance="DANGER">
                Please select a valid value for active lane
              </Text>
            )}
          </FormGroup>

          {/* Shipping Methods */}
          <FormGroup>
            <Select
              multiple
              pluralEntityName={"shipping methods"}
              {...controllers.shippingMethods.field}
              label="Shipping Methods"
              options={shippingMethods.map((method) => ({
                value: method.name,
                label: method.name,
              }))}
              hasError={!!controllers.isActive.fieldState.error}
            />
            {controllers.shippingMethods.fieldState.error && (
              <Text size="caption" appearance="DANGER">
                Please select at least one shipping method
              </Text>
            )}
          </FormGroup>

          {/* Local Origin Cutoff Time*/}
          <FormGroup>
            <Input
              {...controllers.localOriginCutoffTime.field}
              label="Local Origin Cutoff Time (HH:MM)"
              // type="number"
              hasError={!!controllers.localOriginCutoffTime.fieldState.error}
            />
            {controllers.localOriginCutoffTime.fieldState.error && (
              <Text size="caption" appearance="DANGER">
                Please enter a valid value for local origin cutoff time
              </Text>
            )}
          </FormGroup>

          {/* Local Origin Node Departure Time */}
          <FormGroup>
            <Input
              {...controllers.localOriginNodeDepartureTime.field}
              label="Local Origin Node Departure Time (HH:MM)"
              // type="number"
              hasError={!!controllers.localOriginNodeDepartureTime.fieldState.error}
            />
            {controllers.localOriginNodeDepartureTime.fieldState.error && (
              <Text size="caption" appearance="DANGER">
                Please enter a valid value for local origin node departure time
              </Text>
            )}
          </FormGroup>

          {/* Transit Hours */}
          <FormGroup>
            <Input
              {...controllers.transitHours.field}
              label="Transit Hours"
              type="number"
              hasError={!!controllers.transitHours.fieldState.error}
            />
            {controllers.transitHours.fieldState.error && (
              <Text size="caption" appearance="DANGER">
                Please enter a valid value for transit hours
              </Text>
            )}
            {controllers.transitHours.field.disabled && (
              <Text size="caption" appearance="INFO">
                Transit hours are only applicable for zone skip lanes
              </Text>
            )}
          </FormGroup>

          {/* Active Business Days */}
          <FormGroup>
            <Select
              multiple
              pluralEntityName={"business days"}
              {...controllers.activeBusinessDays.field}
              label="Active Business Days"
              options={BusinessDayFormOptions}
              hasError={!!controllers.activeBusinessDays.fieldState.error}
            />
          </FormGroup>

          <NetworkButtonGroup>
            <NetworkButton secondary onClick={handleBackClick}>
              Back
            </NetworkButton>
            <NetworkButton type="submit" loading={isSubmitting}>
              Update Lane
            </NetworkButton>
          </NetworkButtonGroup>
        </StyledForm>
      </LoadingSpinner>
    </NetworkCard>
  );
};
